import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { hideActiveModals } from '../ducks/modals';

import TrailingSlashHost from './TrailingSlashHost';
import HideHubSpotHost from './HideHubSpotHost';
import ModalHost from './ModalHost';

const hosts = [
  TrailingSlashHost,
  HideHubSpotHost,
  ModalHost,
]

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  hideActiveModals,
}, dispatch);

const Host = ({state, ...props}) => (
  <Fragment>
    {hosts.map((H, index) => <H key={index} {...props} {...state}/>)}
  </Fragment>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Host);

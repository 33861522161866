/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { allowedImageFiles } from "../../constants/mime-types";

import Headline from "../../elements/Headline/Headline";
import HR from "../../elements/HR/HR";
import Input from "../../elements/Input/Input";
import Spacer from "../../elements/Spacer/Spacer";
import Button from "../../elements/Button/Button";
import HelpText from "../../elements/HelpText/HelpText";
import DropZone from "../../components/DropZone/DropZone";

const Authorization = ({
  authorization: auth,
  isLogin,
  authorizationMethod,
  uploadSingleFile,
  removeSingleFile,
  images,
  reset,
}) => {
  const fields = {
    email: "",
    name: "",
    phoneNumber: "",
    companyName: "",
  };

  const location = useLocation();

  const [state, setState] = useState(fields);
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");

  const emailValidation = (email) => {
    if (email === "") {
      setEmailError("Please enter a valid email address.");
      return;
    }

    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const nameValidation = (name) => {
    if (name === "") {
      setNameError("Please enter your name.");
    } else {
      setNameError("");
    }
  };

  const handleInputChange = (e) => {
    const { target } = e;
    const { name } = target;

    setState({
      ...state,
      [name]: target.value,
    });

    if (name === "email") {
      emailValidation(target.value);
      reset();
    }

    if (name === "name") {
      nameValidation(target.value);
    }
  };

  const handleSubmit = (e) => {
    if (e.charCode === 13 && emailError === "") {
      authorizationMethod(state);
    }
  };

  useEffect(() => {
    setState({ email: "", name: "", phoneNumber: "", companyName: "" });
    setEmailError("");
    setNameError("");
  }, [location.pathname]);

  return (
    <div>
      {isLogin ? (
        <>
          <Headline align="left">Log Into Choosy</Headline>
          <HR />
          <Spacer height="17.6" />
          {auth?.error && <div className="error-message">{auth.error}</div>}
          <Spacer height="45" />
          <Input.Input
            label="Your Email"
            type="email"
            name="email"
            value={state.email}
            onChange={handleInputChange}
            error={emailError}
            onKeyPress={handleSubmit}
          />
          <Spacer height="20" />
          <Button
            disabled={state.error || emailError}
            onClick={authorizationMethod(state)}
          >
            Log In
          </Button>
        </>
      ) : (
        <>
          <Headline align="left">Create Account</Headline>
          <HR />
          <Spacer height="17.6" />
          {auth?.error && <div className="error-message">{auth.error}</div>}
          <Spacer height="45" />
          <Input.Input
            label="Your Email"
            type="email"
            name="email"
            value={state.email}
            onChange={handleInputChange}
            error={emailError}
          />
          {true && (
            <>
              <Spacer height="20" />
              <Input.Input
                label="Your Name"
                type="text"
                name="name"
                value={state.name}
                onChange={handleInputChange}
                error={nameError}
              />
              <Spacer height="20" />
              <Input.Input
                label="Phone Number"
                type="text"
                name="phoneNumber"
                value={state.phoneNumber}
                onChange={handleInputChange}
              />
              <Spacer height="20" />
              <Input.Input
                label="Company Name"
                type="text"
                name="companyName"
                value={state.companyName}
                onChange={handleInputChange}
              />
              <Spacer height="20" />
              <label htmlFor="companyLogo">Upload your logo</label>
              <DropZone
                items={[images.companyLogo]}
                accept={allowedImageFiles}
                maxSize={15}
                label="company logo"
                uploadHandler={(image, file) => {
                  uploadSingleFile("companyLogo", {
                    upload: image.target.result,
                    file,
                  });
                }}
                deleteHandler={(item) =>
                  removeSingleFile("companyLogo", item.id)
                }
              />
            </>
          )}
          <Spacer height="20" />
          <Button
            disabled={emailError || state.email === "" || state.name === ""}
            onClick={authorizationMethod(state)}
          >
            Sign Up
          </Button>
        </>
      )}

      <Spacer height="10" />
      <HelpText
        fontFamily="Inter"
        fontSize="13px"
        lineHeight="17px"
        color="#828282"
        align="center"
      >
        {isLogin ? (
          <Fragment>
            Don’t have an account? <Link to="/signup">Sign up here.</Link>
          </Fragment>
        ) : (
          <Fragment>
            Already have an account? <Link to="/signin">Log in here.</Link>
          </Fragment>
        )}
      </HelpText>
    </div>
  );
};

export default Authorization;

import React from 'react';

import analytics, { generateAnalyticsPrefix } from '../../helpers/analytics';
import {
  ContractGenerateProposal,
} from '../../constants/analyticEvents';

import Button from '../../elements/Button/Button';

import {
  Wrapper,
  Holder,
  Trimmer,
} from './ProposalCreateEditFooter.styled';

const ProposalCreateEditFooter = ({
  isEdit,
  createOrUpdate,
  cancel
}) => {
  return (
    <Wrapper>
      <Holder>
        <Trimmer>
          <Button
            width="79%"
            size="small"
            onClick={() => {
              analytics.track({
                event: generateAnalyticsPrefix(ContractGenerateProposal),
              })
              createOrUpdate();
            }}
          >
            {isEdit ? 'Update Proposal' : 'Create Proposal' }
          </Button>
          <Button
            outline
            width="20%"
            size="small"
            onClick={cancel}
          >
            Cancel
          </Button>
        </Trimmer>
      </Holder>
    </Wrapper>
  );
};

export default ProposalCreateEditFooter;

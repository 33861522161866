import React, { useState, useEffect } from "react";

import Icon from "../../elements/Icon/Icon";
import Wrapper from "../../elements/Wrapper/Wrapper";
import Holder from "../../elements/Holder/Holder";
import Spacer from "../../elements/Spacer/Spacer";
import HR from "../../elements/HR/HR";

import FAQ from "../../components/FAQ/FAQ";

import analytics from "../../helpers/analytics";
import {
  LoggedOutHamburgerMenu,
  LoggedinHamburgerMenu,
  ViewerLogo,
} from "../../constants/analyticEvents";

import {
  Header,
  HeaderButton,
  LogoWrapper,
  Logo,
  Overlap,
  NavigationItem,
  LinkWrapper,
  AnchorLink,
  NavigationLink,
  RegularLink,
  BurgerWrapper,
} from "./Navigation.styled";

import { Text } from "../../pages/Home/Home.styled";
import { Icon as NavigationIcon } from "../../components/LandingFooter/LandingFooter.styled";

import ChoosyLogo from "../../assets/images/choosy_logo.png";

const Navigation = ({
  authorization,
  isViewer,
  isDashboard,
  proposal,
  noborder = false,
  isLanding = false,
  inverse = true,
  isHome = false,
}) => {
  const [uiState, setUiState] = useState({
    showHelp: false,
    showNavigation: false,
  });
  const [scrolled, setScrolled] = useState(window.pageYOffset > 1);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 1) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const overlayOpened = uiState.showHelp || uiState.showNavigation;
  let iconColor = scrolled || overlayOpened ? "#000" : "#fff";

  if (inverse) {
    iconColor = "#000";
  }

  const { authorized } = authorization;

  let hidebigPicture = false;
  let hidePlan = false;
  let hideShoppingList = false;
  let navigationPreffix;

  if (isViewer) {
    navigationPreffix = "Proposal Viewer: ";
  } else if (isDashboard) {
    navigationPreffix = "Dashboard: ";
  } else if (!authorized) {
    navigationPreffix = "Logged Out: ";
  } else {
    navigationPreffix = "Navigation: ";
  }

  if (proposal) {
    hideShoppingList =
      proposal.costGroups.length === 0
        ? true
        : proposal.costGroups
            .map((costGroup) =>
              costGroup.values.filter(({ selection }) => selection)
            )
            .every((costGroup) => costGroup.length === 0);
    hidePlan = proposal.costGroups.length === 0;
    hidebigPicture =
      !proposal.projectScope &&
      !proposal.projectScope &&
      proposal.currentCondition.length === 0;
  }

  const scrollWithOffset = (el) => {
    const elementPosition = el.offsetTop - 20;

    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  const toggleNavigation = () => {
    if (authorization.authorized) {
      analytics.track({
        event: LoggedinHamburgerMenu,
      });
    } else {
      analytics.track({
        event: LoggedOutHamburgerMenu,
      });
    }

    setUiState({
      showNavigation: !uiState.showNavigation,
      showHelp: false,
    });
  };

  const onLinkClick = () =>
    setUiState({
      showHelp: false,
      showNavigation: false,
    });

  return (
    <>
      <Header scrolled={scrolled || overlayOpened} noborder={noborder}>
        <LogoWrapper
          onClick={() => {
            analytics.track({
              event: ViewerLogo,
            });
          }}
          src={ChoosyLogo}
        >
          <NavigationLink to="/">
            <Logo src={ChoosyLogo} />
          </NavigationLink>
        </LogoWrapper>
        <BurgerWrapper>
          <HeaderButton onClick={() => toggleNavigation()}>
            {!uiState.showNavigation && (
              <Icon color={iconColor} name="hamburger-open" size="20" />
            )}
            {uiState.showNavigation && (
              <Icon color={iconColor} name="hamburger-close" size="25" />
            )}
          </HeaderButton>
        </BurgerWrapper>
      </Header>
      <Overlap show={uiState.showNavigation}>
        <Wrapper fullheight>
          <Holder fullwidth fullheight>
            {!isLanding && <Spacer height="15" />}
            {!authorized && (
              <>
                {isLanding && (
                  <>
                    <LinkWrapper>
                      <Spacer height="48" />
                      <NavigationLink
                        to="/"
                        onClick={() => {
                          onLinkClick();
                        }}
                      >
                        <Text linkHeading fullwidth>
                          For Homeowners
                        </Text>
                        <Text linkSubheading fullwidth>
                          Plan, hire, and build your dream home
                        </Text>
                      </NavigationLink>
                      <Spacer height="20" />
                      <NavigationLink
                        to="/pros"
                        onClick={() => {
                          onLinkClick();
                        }}
                      >
                        <Text linkHeading fullwidth>
                          For Contractors
                        </Text>
                        <Text linkSubheading fullwidth>
                          Get real projects, send great proposals, and win jobs
                        </Text>
                      </NavigationLink>
                    </LinkWrapper>
                    <HR />
                    <Spacer height="43" />
                  </>
                )}
                <LinkWrapper>
                  {isLanding && isHome && (
                    <>
                      <AnchorLink
                        to="#lets-make-it-happen"
                        scroll={(el) => scrollWithOffset(el)}
                        onClick={() => {
                          onLinkClick();
                        }}
                      >
                        Let’s Make It Happen
                      </AnchorLink>
                      <AnchorLink
                        to="#portfolio"
                        scroll={(el) => scrollWithOffset(el)}
                        onClick={() => {
                          onLinkClick();
                        }}
                      >
                        Portfolio
                      </AnchorLink>
                    </>
                  )}
                  <NavigationLink
                    to="/signin/"
                    onClick={() => {
                      onLinkClick();
                      analytics.track({
                        event: `${navigationPreffix}Log In`,
                      });
                    }}
                  >
                    Log In
                  </NavigationLink>
                  <NavigationLink
                    to="/allProducts/"
                    onClick={() => {
                      onLinkClick();
                      analytics.track({
                        event: `${navigationPreffix}AllProducts`,
                      });
                    }}
                  >
                    All Products
                  </NavigationLink>
                  {/* <RegularLink
                    href="#"
                  >
                    Services
                  </RegularLink>
                  <RegularLink
                    href="https://www.choosy.tools/"
                    onClick={() => {
                      onLinkClick();
                    }}
                  >
                    Plan
                  </RegularLink>
                  <RegularLink
                    href="#"
                  >
                    Shop
                  </RegularLink>
                  <RegularLink
                    href="https://www.choosy.tools/"
                    target="_blank"
                    onClick={() => {
                      onLinkClick();
                    }}
                  >
                    FAQ
                  </RegularLink> */}
                </LinkWrapper>
              </>
            )}
            {authorized && !isViewer && (
              <LinkWrapper>
                <NavigationLink
                  to="/dashboard/"
                  onClick={() => {
                    onLinkClick();
                    analytics.track({
                      event: `${navigationPreffix}Dashboard`,
                    });
                  }}
                >
                  Dashboard
                </NavigationLink>
                <NavigationLink
                  to="/allProducts/"
                  onClick={() => {
                    onLinkClick();
                    analytics.track({
                      event: `${navigationPreffix}AllProducts`,
                    });
                  }}
                >
                  All Products
                </NavigationLink>
                {authorization.user.role === "contractor" && (
                  <NavigationLink
                    to="/create/"
                    onClick={() => {
                      onLinkClick();
                      analytics.track({
                        event: `${navigationPreffix}Create New Proposal`,
                      });
                    }}
                  >
                    Create New Proposal
                  </NavigationLink>
                )}
                <NavigationLink
                  to="/logout/"
                  onClick={() => {
                    onLinkClick();
                    analytics.track({
                      event: `${navigationPreffix}Log Out`,
                    });
                  }}
                >
                  Log Out
                </NavigationLink>
              </LinkWrapper>
            )}
            {isLanding && (
              <>
                <LinkWrapper>
                  <RegularLink
                    underline
                    href="https://app.acuityscheduling.com/schedule.php?owner=18876024"
                    onClick={() => {
                      onLinkClick();
                    }}
                  >
                    <NavigationIcon phone margin />
                    Schedule expert consultation
                  </RegularLink>
                  <RegularLink
                    underline
                    href="mailto:info@choosy.tools"
                    onClick={() => {
                      onLinkClick();
                    }}
                  >
                    <NavigationIcon margin />
                    info@choosy.tools
                  </RegularLink>
                </LinkWrapper>
              </>
            )}
            {authorized && isViewer && (
              <LinkWrapper>
                {proposal && proposal.projectName && (
                  <NavigationItem>{proposal.projectName}</NavigationItem>
                )}
                <NavigationLink
                  to="/dashboard/"
                  onClick={() => {
                    onLinkClick();
                    analytics.track({
                      event: `${navigationPreffix}Back to Dashboard`,
                    });
                  }}
                >
                  &larr; Back to Dashboard
                </NavigationLink>
                <AnchorLink
                  to="#welcome"
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    onLinkClick();
                    analytics.track({
                      event: `${navigationPreffix}Welcome`,
                    });
                  }}
                >
                  Welcome
                </AnchorLink>
                {!hidebigPicture && (
                  <AnchorLink
                    to="#big-picture"
                    scroll={(el) => scrollWithOffset(el)}
                    onClick={() => {
                      onLinkClick();
                      analytics.track({
                        event: `${navigationPreffix}Big Picture`,
                      });
                    }}
                  >
                    Big Picture
                  </AnchorLink>
                )}
                {!hidePlan && (
                  <AnchorLink
                    to="#plan"
                    scroll={(el) => scrollWithOffset(el)}
                    onClick={() => {
                      onLinkClick();
                      analytics.track({
                        event: `${navigationPreffix}Plan`,
                      });
                    }}
                  >
                    Plan
                  </AnchorLink>
                )}
                {!hideShoppingList && (
                  <AnchorLink
                    to="#shopping-list"
                    scroll={(el) => scrollWithOffset(el)}
                    onClick={() => {
                      onLinkClick();
                      analytics.track({
                        event: `${navigationPreffix}Shopping List`,
                      });
                    }}
                  >
                    Shopping List
                  </AnchorLink>
                )}
                <AnchorLink
                  to="#contract"
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    onLinkClick();
                    analytics.track({
                      event: `${navigationPreffix}Contract`,
                    });
                  }}
                >
                  Contract
                </AnchorLink>
              </LinkWrapper>
            )}
          </Holder>
        </Wrapper>
      </Overlap>
      <Overlap show={uiState.showHelp}>
        <Spacer height="20" />
        <Wrapper fullheight>
          <Holder fullheight align="end" noShadow>
            <FAQ />
          </Holder>
        </Wrapper>
      </Overlap>
    </>
  );
};

export default Navigation;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import { BrowserRouter as Router } from "react-router-dom";

import "antd/dist/antd.less";
import "./assets/styles/normalize.css";
import "./assets/styles/style.css";

import store from "./store";

import App from "./pages/App";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

const getEnvironment = () => {
  if (window.location.host.indexOf("choosy") !== -1) {
    return "production";
  }

  if (window.location.host.indexOf("herokuapp") !== -1) {
    return "stage";
  }

  return "local";
};

if (getEnvironment() !== "local") {
  Sentry.init({
    dsn: "https://ec6c44123ea7494992d2dee12577aa1b@o577361.ingest.sentry.io/5732060",
    environment: getEnvironment(),
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>,
  document.querySelector("#root")
);

import React from 'react';
import moment from 'moment';

import { S3_URL } from '../../constants';

const ProposalLog = ({
  proposalLog,
}) => {
  if (!proposalLog) {
    return null;
  }

  const { homeowner, contractor } = proposalLog;

  return (
    <div>
      <p>Contractor Signed</p>
      <ul>
        <li>{contractor.ip}</li>
        <li>{contractor.name}</li>
        <li>{contractor.email}</li>
        <li>{S3_URL}/{contractor.signature}</li>
        <li>{moment(contractor.signedAt).format('MMMM D, YYYY [at] hh:mm:ss')}</li>
        <li>{moment(contractor.lastLoginAt).format('MMMM D, YYYY [at] hh:mm:ss')}</li>
      </ul>

      <br /><br />

      <p>Homeowner Signed</p>
      <ul>
        <li>{homeowner.ip}</li>
        <li>{homeowner.name}</li>
        <li>{homeowner.email}</li>
        <li>{S3_URL}/{homeowner.signature}</li>
        <li>{moment(homeowner.signedAt).format('MMMM D, YYYY [at] hh:mm:ss')}</li>
        <li>{moment(homeowner.lastLoginAt).format('MMMM D, YYYY [at] hh:mm:ss')}</li>
      </ul>
    </div>
  );
};

export default ProposalLog;

import React from "react";
import styled from "styled-components";

import "./icon.css";

import checkmarkChecked from "../../assets/images/checkmark-checked.svg";
import checkmarkUnchecked from "../../assets/images/checkmark-unchecked.svg";
import heartFilled from "../../assets/images/heart-filled.svg";
import heartOutlined from "../../assets/images/heart-outlined.svg";
import smallClose from "../../assets/images/small-close.svg";
import calculatorIcon from "../../assets/images/calculator_icon.svg";
import draggableIcon from "../../assets/images/draggable.svg";

const svgIconNameMap = {
  "checkmark-checked": checkmarkChecked,
  "checkmark-unchecked": checkmarkUnchecked,
  "heart-filled": heartFilled,
  "heart-outlined": heartOutlined,
  "small-close": smallClose,
  "calculator-icon": calculatorIcon,
  "draggable-icon": draggableIcon,
};

const strukFontIconNameMap = {
  "2x4": "\\E800",
  "arrow-left": "\\E801",
  "arrow-right": "\\E802",
  "bath-shower": "\\E803",
  calculator: "\\E804",
  calendar: "\\E805",
  celebration: "\\E806",
  door: "\\E807",
  "electrical-plug": "\\E808",
  "faucet-2": "\\E809",
  faucet: "\\E80A",
  "flooring-staggered": "\\E80B",
  "hamburger-close": "\\E80C",
  "hamburger-open": "\\E80D",
  hammer: "\\E80E",
  handshake: "\\E80F",
  help: "\\E810",
  lightning: "\\E811",
  "mount-climb-goal": "\\E812",
  "paper-stack": "\\E813",
  ruler: "\\E814",
  "sink-bathroom": "\\E815",
  team: "\\E816",
  tile: "\\E817",
  "start-to-finish": "\\E818",
  timeline: "\\E819",
  toilet: "\\E81A",
  "towel-holder": "\\E81B",
  vanity: "\\E81C",
  ventilation: "\\E81D",
  windows: "\\E81E",
  slab: "\\E834",
  bulldozer: "\\E835",
  "door-handle": "\\E836",
  fireplace: "\\E837",
  "flooring-open": "\\E838",
  foundation: "\\E839",
  "house-front": "\\E83A",
  insulation: "\\E83B",
  paint: "\\E83C",
  pillar: "\\E83D",
  "putty-knife": "\\E83E",
  roof: "\\E83F",
  stairs: "\\E840",
  steel: "\\E841",
  wall: "\\E842",
  waterproof: "\\E843",
  "masonry-trowel": "\\E844",
  kitchen: "\\E845",
  cones: "\\E846",
  cabinet: "\\E847",
};

const getIconContent = (props) => {
  const escapedName = props.name ? props.name : "";
  const lowercased = escapedName.toLocaleLowerCase();
  const iconCode = strukFontIconNameMap[lowercased];

  if (iconCode) {
    return `content: "${iconCode}"`;
  }

  return `content: "${strukFontIconNameMap["2x4"]}"`;
};

const StrukIcon = styled.div`
  ${(props) => props.customStyle}
  &:before {
    font-family: struk;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${(props) => (props.size ? `${props.size}px` : "inherit")};
    color: ${(props) => props.color};
    fill: ${(props) => props.color};
    ${(props) => getIconContent(props)};
  }
`;

const SvgIcon = styled.img`
  width: ${(props) => (props.size ? `${props.size}px` : "inherit")} !important;
  height: ${(props) => (props.size ? `${props.size}px` : "inherit")} !important;
  fill: ${(props) => props.color};
  ${(props) => props.customStyle}
`;

const IconHandler = ({ name, ...rest }) => {
  if (svgIconNameMap[name]) {
    return <SvgIcon src={svgIconNameMap[name]} {...rest} />;
  }
  return <StrukIcon name={name} {...rest} />;
};

export default IconHandler;


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { getProposals as getProposalsAction, fetchContractors as fetchContractorsAction } from '../../ducks/dashboard';

import Dashboard from './Dashboard';

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  manageProposal: state.manageProposal,
  forContractor: state.authorization.user.role === 'contractor',
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getProposals: getProposalsAction,
  fetchContractors: fetchContractorsAction,
}, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const { getProposals, fetchContractors } = this.props;

      getProposals();
      fetchContractors();
    },
    componentWillReceiveProps(nextProps) {
      const { props } = this;

      if (
        (nextProps.manageProposal.createNew !== props.manageProposal.createNew
        || nextProps.manageProposal.reassign !== props.manageProposal.reassign
        || nextProps.manageProposal.fetching !== props.manageProposal.fetching
        || nextProps.manageProposal.toggleVisibility !== props.manageProposal.toggleVisibility)
      ) {
        if (nextProps.manageProposal.createNew && nextProps.manageProposal.reassign && !nextProps.manageProposal.fetching) {
          alert(`Share this link with a contractor. When they click the link it will authorize them and open their proposal: ${nextProps.manageProposal.res}`);
        }
        if (nextProps.manageProposal.toggleVisibility && !nextProps.manageProposal.fetching) {
          alert('Visibility of your proposal was updated!');
        }
      }
    },
  }),
)(Dashboard);

import styled from "styled-components";
import posed from "react-pose";

const sidebarProps = {
  closed: { height: 0 },
  open: { height: "auto" },
};

export const Wrapper = styled.div`
  padding: ${(props) => (props.padding ? props.padding : 0)}px;
  width: 100%;
`;

export const ListItem = styled.div`
  padding: 1rem 0;
  font-family: var(--font-family-body);
  font-size: 1.2rem;
  text-decoration: none;
  color: var(--text-color);

  @media (min-width: 420px) {
    margin-left: 3rem;
  }

  border-bottom: ${(props) =>
    props.noborder ? "0" : "1px solid var(--line-color)"};

  &:last-child {
    border-bottom: none;
  }

  ${(props) => (props.itemStyles ? props.itemStyles : "")}
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const ComponentWrapper = styled(posed.div(sidebarProps))`
  overflow: hidden;

  @media print {
    height: auto !important;
  }
`;

export const IconHolder = styled.div`
  align-self: flex-start;
  margin: 1px 0.8rem 0 0.8rem;
`;

export const ItemData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Name = styled.div``;

const arrowStyle = (props) => {
  if (props.accordeon) {
    if (props.opened) {
      return "transform: rotate(-90deg)";
    }
    return "transform: rotate(90deg)";
  }

  return "";
};

export const Arrow = styled.div`
  transition: all 0.5s ease-out;
  color: var(--line-color);
  ${(props) => props.arrowCustomStyle}
  ${(props) => arrowStyle(props)}
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;

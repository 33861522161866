import React, { useState, useEffect } from "react";

import {
  NavigationWrapper,
  Navigation,
  Logo,
  Links,
} from "./LandingNavigation.styled";

const LandingNavigation = ({ inverse = false, children }) => {
  const [scrolled, setScrolled] = useState(window.pageYOffset > 1);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 1) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <NavigationWrapper>
      <Navigation scrolled={scrolled} inverse={inverse}>
        <Logo scrolled={scrolled} inverse={inverse} to="/" />
        <Links scrolled={scrolled} inverse={inverse}>
          {children}
        </Links>
      </Navigation>
    </NavigationWrapper>
  );
};

export default LandingNavigation;

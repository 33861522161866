import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import imageCompression from 'browser-image-compression';

import ImageReel from '../ImageReel/ImageReel';

import {
  allowedImageFiles,
} from '../../constants/mime-types';

import {
  DropZoneWrapper,
  DropZoneLabelWrapper,
  DropZoneLabel
} from './DropZone.styled';

const DropZone = ({
  label = '',
  items: incomingItems,
  accept,
  multiple = false,
  maxSize,
  uploadHandler,
  deleteHandler,
}) => {
  const onDrop = useCallback(acceptedFiles => {
    for (let i = 0; i < acceptedFiles.length; i += 1) {
      // eslint-disable-next-line no-loop-func
      (async function addFile(file) {
        const reader = new FileReader();

        reader.onload = function handleFileRead(image) {
          uploadHandler(image,file)
        };

        if (allowedImageFiles.indexOf(file.type) !== -1) {
          const compressed = await imageCompression(file, {
            maxSizeMB: 1,
          });

          reader.readAsArrayBuffer(compressed);
        } else {
          reader.readAsArrayBuffer(file);
        }
      }(acceptedFiles[i]));
    }
  }, [uploadHandler]);

  const onDropRejected = useCallback(() => {
    alert('The file you are trying to upload is bigger than 15MB')
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept,
    onDropRejected,
    multiple,
    maxSize: maxSize*1000000
  })

  const placeholder = label ? ` ${label} ` : ' ';

  if(multiple) {
    const items = incomingItems;

    return (
      <DropZoneWrapper {...getRootProps({className: 'dropzone'})} isDragActive={isDragActive} isDragReject={isDragReject}>
        <input {...getInputProps()} />
        {
          items.length === 0 && !isDragActive &&
            <DropZoneLabelWrapper>
              <DropZoneLabel>Drag and Drop your{placeholder}{multiple ? 'photos and documents' : ''} here.</DropZoneLabel>
              <DropZoneLabel>Or click to open upload dialog</DropZoneLabel>
            </DropZoneLabelWrapper>
        }
        {
          items.length > 0 && (
            <ImageReel
              items={items}
              onDeleteClick={deleteHandler}
              withFiles
            />
          )
        }
        {
          isDragActive &&
             <DropZoneLabelWrapper>
              <DropZoneLabel>Drop the files here ...</DropZoneLabel>
            </DropZoneLabelWrapper>
        }
      </DropZoneWrapper>
    )
  }

  const items = incomingItems.filter(Boolean).length > 0 ? incomingItems.filter(({file, uploading}) => !!file || uploading !== undefined) : [];

  return (
    <DropZoneWrapper {...getRootProps({className: 'dropzone'})} isDragActive={isDragActive} isDragReject={isDragReject}>
      <input {...getInputProps()} />
      {
        items.length === 0 && !isDragActive &&
          <DropZoneLabelWrapper>
            <DropZoneLabel>Drag and Drop your{placeholder}{multiple ? 'photos and documents' : ''} here.</DropZoneLabel>
            <DropZoneLabel>Or click to upload.</DropZoneLabel>
          </DropZoneLabelWrapper>
      }
      {
        items.length > 0 && (
          <ImageReel
            items={items.map(image => ({
              ...image,
              id: JSON.stringify(image)
            }))}
            onDeleteClick={deleteHandler}
            withFiles
          />
        )
      }
      {
        isDragActive &&
           <DropZoneLabelWrapper>
            <DropZoneLabel>Drop the files here ...</DropZoneLabel>
          </DropZoneLabelWrapper>
      }
    </DropZoneWrapper>
  )

}

export default DropZone;
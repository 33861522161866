import {
  SET_FIELD,
  CREATE_COST_GROUP,
  UPDATE_COST_GROUP_NAME,
  CREATE_COST_ITEM,
  UPDATE_COST_ITEM_VALUE,
  REMOVE_COST_GROUP,
  REMOVE_COST_GROUP_ITEM,
  ORDER_COST_GROUP_ITEM,
  ORDER_COST_GROUP,
  DUPLICATE_INTO_CREATE_STORE,
  CREATE_RESET,
} from "../ducks/create";

import {
  GET_PROPOSAL_EDIT_SUCCESS,
  EDIT_SET_FIELD,
  EDIT_CREATE_COST_GROUP,
  EDIT_UPDATE_COST_GROUP_NAME,
  EDIT_CREATE_COST_ITEM,
  EDIT_UPDATE_COST_ITEM_VALUE,
  EDIT_REMOVE_COST_GROUP,
  EDIT_REMOVE_COST_GROUP_ITEM,
  UPDATE_COST_ITEM_OPTIONS,
  SUCCESS_EDIT_PROPOSAL,
  EDIT_RESET,
} from "../ducks/edit";

import { SUCCESS_SEND_CREATED_PROPOSAL } from "../ducks/emails";

import {
  UPLOAD_SINGLE_FILE,
  REMOVE_SINGLE_FILE,
  UPLOAD_MULTIPLE_FILES,
  REMOVE_MULTIPLE_FILES,
} from "../ducks/images";

import { ERROR_AUTHORIZE, SUCCESS_LOGOUT } from "../ducks/authorization";

export default ({ getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case SET_FIELD:
      case CREATE_COST_GROUP:
      case UPDATE_COST_GROUP_NAME:
      case CREATE_COST_ITEM:
      case UPDATE_COST_ITEM_VALUE:
      case REMOVE_COST_GROUP:
      case REMOVE_COST_GROUP_ITEM:
      case ORDER_COST_GROUP_ITEM:
      case ORDER_COST_GROUP:
      case CREATE_RESET:
      case DUPLICATE_INTO_CREATE_STORE: {
        if (
          window.location.href.indexOf("create") === -1 &&
          action.type !== DUPLICATE_INTO_CREATE_STORE
        ) {
          return result;
        }

        const { fetching, proposalId, error, ...rest } = getState().create;

        const { costGroups, ...restData } = rest;

        // Do not delete
        // const filteredCostGroups = costGroups
        //   .filter((group) => !group.pristine)
        //   .map(({ values, ...restGroup }) => ({
        //     values: values.filter((value) => !value.pristine && value.name),
        //     ...restGroup,
        //   }));

        localStorage.setItem(
          "create",
          JSON.stringify({
            costGroups,
            ...restData,
          })
        );

        return result;
      }

      case EDIT_SET_FIELD:
      case EDIT_CREATE_COST_GROUP:
      case EDIT_UPDATE_COST_GROUP_NAME:
      case EDIT_CREATE_COST_ITEM:
      case EDIT_UPDATE_COST_ITEM_VALUE:
      case EDIT_REMOVE_COST_GROUP:
      case UPDATE_COST_ITEM_OPTIONS:
      case EDIT_REMOVE_COST_GROUP_ITEM:
      case GET_PROPOSAL_EDIT_SUCCESS: {
        /* if (window.location.href.indexOf('edit') === -1) {
        return result;
      }

      const {
        fetching,
        error,
        edited,
        date,
        ...rest
      } = getState().edit;

      localStorage.setItem('edit', JSON.stringify({
        ...rest,
        editedAt: Date.now()
      })); */

        return result;
      }

      case UPLOAD_SINGLE_FILE:
      case REMOVE_SINGLE_FILE:
      case UPLOAD_MULTIPLE_FILES:
      case REMOVE_MULTIPLE_FILES:
        if (window.location.href.indexOf("create") === -1) {
          return result;
        }

        try {
          localStorage.setItem("images", JSON.stringify(getState().images));
        } catch (e) {
          if (e.name === "QuotaExceededError") {
            return result;
          }
        }

        return result;
      case SUCCESS_SEND_CREATED_PROPOSAL:
        localStorage.removeItem("images");
        localStorage.removeItem("create");
        return result;
      case SUCCESS_EDIT_PROPOSAL:
      case EDIT_RESET:
        localStorage.removeItem("edit");

        return result;
      case ERROR_AUTHORIZE:
      case SUCCESS_LOGOUT:
        localStorage.removeItem("authorization");

        return result;
      default:
        return result;
    }
  };

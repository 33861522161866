import React from 'react';

import {
  Wrapper,
  Progress
} from './ProgressBar.styled'

const ProgressBar = ({progress}) => (
  <Wrapper>
    <Progress progress={progress}/>
  </Wrapper>
)

export default ProgressBar;
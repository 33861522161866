import CONTRACT from "../constants/contract";
import INTRODUCTORY_NOTE from "../constants/introductoryNote";

import hydrateProposal from "../helpers/hydrateProposal";

import { setUiState } from "./uiState";

import { setUi } from "./ui";

import {
  KITCHEN_MODULE,
  BATHROOM_MODULE,
  POWDER_ROOM_MODULE,
  kitchenModuleItems,
  bathroomModuleItems,
  powderRoomModuleItems,
  costGroupsData,
} from "../constants/costGroupData";

export const generateCostItem = (
  name = "",
  cost = "",
  costType = "",
  selection = false,
  note = "",
  pristine = true
) => ({
  id: Date.now().toString(36) + Math.random().toString(36).substring(2),
  name,
  cost,
  costType,
  selection,
  note,
  pristine,
});

const generateCostGroup = () => ({
  id: Math.ceil(Math.random() * 100) + Date.now(),
  name: "",
  note: "",
  pristine: true,
  values: [generateCostItem()],
  images: [],
});

export const generateDefaultCreateState = () => ({
  costGroups: [generateCostGroup()],
  contract: CONTRACT,
  introductoryNote: INTRODUCTORY_NOTE,
  proposalId: null,
  fetching: false,
  error: null,
  edited: false,
  date: Date.now(),
  signed: false,
  showLogo: true,
  sectionVisibility: {
    cover: true,
    welcome: true,
    bigpicture: true,
    plan: true,
    shoppinglist: true,
    contract: true,
  },
});

export const defaultCreateState = generateDefaultCreateState();

export const SET_FIELD = "SET_FIELD";
export const CREATE_COST_GROUP = "CREATE_COST_GROUP";
export const UPDATE_COST_GROUP_NAME = "UPDATE_COST_GROUP_NAME";
export const CREATE_COST_ITEM = "CREATE_COST_ITEM";
export const UPDATE_COST_ITEM_VALUE = "UPDATE_COST_ITEM_VALUE";
export const REMOVE_COST_GROUP = "REMOVE_COST_GROUP";
export const REMOVE_COST_GROUP_ITEM = "REMOVE_COST_GROUP_ITEM";
export const ORDER_COST_GROUP_ITEM = "ORDER_COST_GROUP_ITEM";
export const ORDER_COST_GROUP = "ORDER_COST_GROUP";

export const START_CREATE_PROPOSAL = "START_CREATE_PROPOSAL";
export const SUCCESS_CREATE_PROPOSAL = "SUCCESS_CREATE_PROPOSAL";
export const ERROR_CREATE_PROPOSAL = "ERROR_CREATE_PROPOSAL";

export const SUCCESS_SIGN_PROPOSAL = "SUCCESS_SIGN_PROPOSAL";
export const CREATE_RESET = "CREATE_RESET";
export const CREATE_RESET_PROPOSAL_ID = "CREATE_RESET_PROPOSAL_ID";
export const DUPLICATE_INTO_CREATE_STORE = "DUPLICATE_INTO_CREATE_STORE";

export const setField = (field, value) => ({
  type: SET_FIELD,
  payload: {
    field,
    value,
  },
});

export const createCostGroup = () => (dispatch) => {
  const newCostGroup = generateCostGroup();

  dispatch(setUiState("lastCostGroupId", newCostGroup.id));

  dispatch({
    type: CREATE_COST_GROUP,
    payload: {
      ...newCostGroup,
    },
  });
};

export const updateCostGroupValue = (costGroupId, name, value) => ({
  type: UPDATE_COST_GROUP_NAME,
  payload: {
    costGroupId,
    name,
    value,
  },
});

export const createCostItem = (costGroupId) => ({
  type: CREATE_COST_ITEM,
  payload: {
    costGroupId,
  },
});

export const updateCostItemValue = (costGroupId, costItemId, field, value) => ({
  type: UPDATE_COST_ITEM_VALUE,
  payload: {
    costGroupId,
    costItemId,
    field,
    value,
  },
});

export const removeCostGroup = (costGroupId) => ({
  type: REMOVE_COST_GROUP,
  payload: {
    costGroupId,
  },
});

export const removeCostGroupItem = (costGroupId, costItemId) => ({
  type: REMOVE_COST_GROUP_ITEM,
  payload: {
    costGroupId,
    costItemId,
  },
});

export const orderCostGroupItems = (
  costGroupId,
  sourceIndex,
  destinationIndex
) => ({
  type: ORDER_COST_GROUP_ITEM,
  payload: {
    costGroupId,
    sourceIndex,
    destinationIndex,
  },
});

export const orderCostGroup = (sourceIndex, destinationIndex) => ({
  type: ORDER_COST_GROUP,
  payload: {
    sourceIndex,
    destinationIndex,
  },
});

export const createProposal = () => (dispatch, getState) => {
  dispatch({
    type: START_CREATE_PROPOSAL,
  });

  const filteredData = hydrateProposal(getState().create, getState().images);

  fetch("/api/proposal", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(filteredData),
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.text();
      }

      throw new Error(res);
    })
    .then((id) => {
      dispatch({
        type: SUCCESS_CREATE_PROPOSAL,
        payload: {
          id: id.replace(/"/g, ""),
        },
      });
    })
    .catch((error) =>
      dispatch({
        type: ERROR_CREATE_PROPOSAL,
        payload: {
          error,
        },
      })
    );
};

export const signProposal = (fileId) => (dispatch, getState) => {
  const { proposal } = getState().proposalView;

  fetch(`/api/proposal/sign/${proposal._id}`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ fileId }),
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return;
      }

      throw new Error(res);
    })
    .then(() => {
      dispatch(
        setUi({
          signOverlay: false,
        })
      );

      dispatch({
        type: SUCCESS_SIGN_PROPOSAL,
      });
    });
};

export const reset = () => ({
  type: CREATE_RESET,
});

export const resetProposalId = () => ({
  type: CREATE_RESET_PROPOSAL_ID,
});

export const duplicateIntoCreateStore = () => (dispatch, getState) => {
  const { personalPhoto, companyLogo, currentCondition, ...rest } =
    getState().proposalView.proposal;

  const {
    contract,
    introductoryNote,
    companyName,
    projectName,
    projectAddress,
    projectScope,
    teamDescription,
  } = rest;

  rest.costGroups =
    rest.costGroups.length === 0
      ? [generateCostGroup()]
      : rest.costGroups.map((costGroup) => {
          return {
            ...costGroup,
            values: [...costGroup.values, generateCostItem()],
            images: [],
          };
        });

  dispatch({
    type: DUPLICATE_INTO_CREATE_STORE,
    payload: {
      proposal: {
        contract,
        introductoryNote,
        companyName,
        projectName,
        projectAddress,
        projectScope,
        teamDescription,
        costGroups: rest.costGroups,
      },
      isDuplicate: true,
    },
  });
};

export default function CreateReducer(state = defaultCreateState, action) {
  switch (action.type) {
    case SET_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case CREATE_COST_GROUP:
      return {
        ...state,
        costGroups: [...state.costGroups, action.payload],
      };
    case UPDATE_COST_GROUP_NAME: {
      const costGroup = state.costGroups.find(
        ({ id }) => id === action.payload.costGroupId
      );

      if (
        [KITCHEN_MODULE, BATHROOM_MODULE, POWDER_ROOM_MODULE].includes(
          action.payload.value
        )
      ) {
        costGroup.values = [];
        const costItems =
          action.payload.value === KITCHEN_MODULE
            ? kitchenModuleItems
            : action.payload.value === BATHROOM_MODULE
            ? bathroomModuleItems
            : powderRoomModuleItems;

        costItems.forEach((costItem) => {
          costGroup.values.push(
            generateCostItem(
              costItem.name,
              undefined,
              costItem.costType,
              costItem.selection,
              undefined,
              costItem.pristine
            )
          );
        });
        costGroup.values.push(generateCostItem());
      }

      costGroup[action.payload.name] = action.payload.value;

      if (costGroup.pristine) {
        costGroup.pristine = false;
      }

      return {
        ...state,
        costGroups: state.costGroups,
      };
    }
    case CREATE_COST_ITEM: {
      const costGroup = state.costGroups.find(
        ({ id }) => id === action.payload.costGroupId
      );
      costGroup.values.push(generateCostItem());

      return {
        ...state,
        costGroups: state.costGroups,
      };
    }
    case UPDATE_COST_ITEM_VALUE: {
      const costGroup = state.costGroups.find(
        ({ id }) => id === action.payload.costGroupId
      );

      const costItem = costGroup.values.find(
        ({ id }) => id === action.payload.costItemId
      );

      costItem[action.payload.field] = action.payload.value;

      if (costItem.pristine) {
        costItem.pristine = false;
      }

      if (costGroup.pristine) {
        costGroup.pristine = false;
      }

      const costGroupItems = costGroupsData[costGroup.name];
      if (costGroupItems) {
        // auto populate cost item row with necessary data
        costGroupItems.map((item) => {
          if (item.name === costItem.name) {
            costItem.costType =
              costItem.costType === "" ? item.costType : costItem.costType;
          }

          return false;
        });
      }

      return {
        ...state,
        costGroups: state.costGroups,
      };
    }
    case REMOVE_COST_GROUP:
      return {
        ...state,
        costGroups: state.costGroups.filter(
          ({ id }) => id !== action.payload.costGroupId
        ),
      };
    case REMOVE_COST_GROUP_ITEM: {
      return {
        ...state,
        costGroups: state.costGroups.map((costGroup) => {
          if (costGroup.id === action.payload.costGroupId) {
            const { values, ...rest } = costGroup;

            return {
              values: values.filter(
                ({ id }) => id !== action.payload.costItemId
              ),
              ...rest,
            };
          }
          return costGroup;
        }),
      };
    }
    case ORDER_COST_GROUP_ITEM: {
      const { costGroupId, destinationIndex, sourceIndex } = action.payload;
      const { costGroups, ...rest } = state;

      return {
        ...rest,
        costGroups: costGroups.map((costGroup) => {
          if (costGroup.id === costGroupId) {
            const { values, ...restCostItem } = costGroup;
            const sortedOptions = Array.from(values);
            const [removed] = sortedOptions.splice(sourceIndex, 1);
            sortedOptions.splice(destinationIndex, 0, removed);
            return {
              ...restCostItem,
              values: sortedOptions,
            };
          }
          return costGroup;
        }),
      };
    }
    case ORDER_COST_GROUP: {
      const { destinationIndex, sourceIndex } = action.payload;
      const { costGroups, ...rest } = state;
      const sortedOptions = Array.from(costGroups);
      const [removed] = sortedOptions.splice(sourceIndex, 1);
      sortedOptions.splice(destinationIndex, 0, removed);
      return {
        ...rest,
        costGroups: sortedOptions,
      };
    }
    case START_CREATE_PROPOSAL:
      return {
        ...state,
        error: null,
        proposalId: null,
        fetching: true,
      };
    case SUCCESS_CREATE_PROPOSAL:
      return {
        ...generateDefaultCreateState(),
        fetching: false,
        proposalId: action.payload.id,
      };
    case ERROR_CREATE_PROPOSAL:
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    case SUCCESS_SIGN_PROPOSAL: {
      return {
        ...state,
        signed: true,
      };
    }
    case CREATE_RESET: {
      if (state.isDuplicate) {
        return state;
      }

      return {
        ...generateDefaultCreateState(),
      };
    }
    case CREATE_RESET_PROPOSAL_ID: {
      return {
        ...state,
        proposalId: null,
      };
    }
    case DUPLICATE_INTO_CREATE_STORE: {
      return {
        ...state,
        ...action.payload.proposal,
        isDuplicate: action.payload.isDuplicate,
      };
    }
    default:
      return state;
  }
}

/* eslint-disable no-unused-vars */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose, withProps } from "recompose";
import { uploadSingleFile, removeSingleFile } from "../../ducks/images";

import getImages from "../../selectors/getImages";

import {
  triggerModal as triggerModalAction,
  hideActiveModals as hideActiveModalsAction,
} from "../../ducks/modals";
import {
  editProduct as editProductAction,
  createManualProduct as createManualProductAction,
} from "../../ducks/edit";

import AddManualProductModal from "./AddManualProductModal";

const mapStateToProps = (state) => ({
  proposalId: state.create.proposalId,
  edited: state.create.edited,
  itemName: state.uiState.itemName,
  fields:
    state.modals &&
    state.modals.find((x) => x.name === "addManualProduct").fields,
  images: getImages(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      triggerModal: triggerModalAction,
      hideActiveModals: hideActiveModalsAction,
      editProduct: editProductAction,
      createManualProduct: createManualProductAction,
      uploadSingleFile,
      removeSingleFile,
    },
    dispatch
  );

const props = ({
  triggerModal,
  hideActiveModals,
  edited,
  proposalId,
  createManualProduct,
  editProduct,
  itemName,
  fields,
}) => {
  return {
    itemName,
    proposalId,
    hideActiveModals,
    edited,
    closeModal: () => {
      triggerModal({
        name: "addManualProduct",
        state: false,
      });
    },
    editProduct: ({ id, productName, photoURL, price, currency }) => {
      if (!productName) {
        alert("Please enter product name");
        return;
      }

      editProduct({
        _id: id,
        title: productName,
        image: photoURL,
        price,
        currency,
        manual: true,
      });
    },
    addManualProduct: ({
      id,
      productName,
      productURL,
      photoURL,
      price,
      currency,
    }) => {
      if (!productName) {
        alert("Please enter product name");
        return;
      }

      createManualProduct({
        id,
        productName,
        productURL,
        photoURL,
        price,
        currency,
      });
    },
    fields,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props)
)(AddManualProductModal);

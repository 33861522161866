import React from 'react';

import Headline from '../../elements/Headline/Headline';
import HR from '../../elements/HR/HR';
import Spacer from '../../elements/Spacer/Spacer';
import Button from '../../elements/Button/Button';
import Content from '../../elements/Content/Content';
import HelpText from '../../elements/HelpText/HelpText';

import Icon from '../../components/NotFound/NotFound.styled';

const HandleAuthorization = ({
  authorization,
}) => {
  if (!authorization.fetching && authorization.error === 403) {
    return (
      <Content horizontal="20">
        <Icon />
        <Headline align="left">Trying to login?</Headline>
        <HR />
        <Spacer height="50" />
        <HelpText fontSize="20px" fontWeight="500" align="left" fullsize>It looks like you may be trying to login with an old link.</HelpText>
        <Spacer height="20" />
        <HelpText fontSize="16px" align="left" fullsize>Request a new link to login to Choosy.</HelpText>
        <Spacer height="50" />
        <Button to="/signin">Log In</Button>
      </Content>
    );
  }

  return null;
};

export default HandleAuthorization;

import React from "react";

import HelpText from "../../elements/HelpText/HelpText";

import { Wrapper, Holder } from "./Footer.styled";

const Footer = ({ fullwidth }) => {
  const date = new Date();

  return (
    <Wrapper>
      <Holder fullwidth={fullwidth}>
        <HelpText>© {date.getFullYear()} Choosy</HelpText>
        <HelpText align="right">
          <span>Contact us: </span>
          <a href="mailto:info@choosy.tools">info@choosy.tools</a>
        </HelpText>
      </Holder>
    </Wrapper>
  );
};

export default Footer;

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose, withProps } from "recompose";
import isUrl from "is-url";

import {
  triggerModal as triggerModalAction,
  hideActiveModals as hideActiveModalsAction,
} from "../../ducks/modals";
import {
  updateCostItemOptions as updateCostItemOptionsAction,
  editProduct as editProductAction,
} from "../../ducks/edit";

import AddProductModal from "./AddProductModal";

const mapStateToProps = (state) => ({
  proposalId: state.create.proposalId,
  edited: state.create.edited,
  itemName: state.uiState.itemName,
  fields:
    state.modals && state.modals.find((x) => x.name === "addProduct").fields,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      triggerModal: triggerModalAction,
      hideActiveModals: hideActiveModalsAction,
      updateCostItemOptions: updateCostItemOptionsAction,
      editProduct: editProductAction,
    },
    dispatch
  );

const props = ({
  triggerModal,
  hideActiveModals,
  edited,
  proposalId,
  updateCostItemOptions,
  editProduct,
  itemName,
  fields,
}) => {
  return {
    itemName,
    proposalId,
    hideActiveModals,
    edited,
    closeModal: () => {
      triggerModal({
        name: "addProduct",
        state: false,
      });
    },
    addProduct: ({ productName, productURL, photoURL }) => {
      if (!isUrl(productURL)) {
        alert("Please enter valid Product URL. E.g. http://example.com/");
        return;
      }

      if (!isUrl(photoURL)) {
        alert("Please enter valid Photo URL. E.g. http://example.com/");
        return;
      }

      updateCostItemOptions(false, false, true, {
        productName,
        productURL,
        photoURL,
      });
    },
    editProduct: ({ id, productName, productURL, photoURL, price }) => {
      if (!isUrl(productURL)) {
        alert("Please enter valid Product URL. E.g. http://example.com/");
        return;
      }

      if (!isUrl(photoURL)) {
        alert("Please enter valid Photo URL. E.g. http://example.com/");
        return;
      }

      editProduct({
        _id: id,
        title: productName,
        url: productURL,
        image: photoURL,
        price,
      });
    },
    fields,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props)
)(AddProductModal);

import React, { useState } from "react";

import Input from "../../elements/Input/Input";
import Button from "../../elements/Button/Button";
import Spacer from "../../elements/Spacer/Spacer";
import Link from "../../elements/Link/Link";
import HelpText from "../../elements/HelpText/HelpText";

import { ModalWrapper, Modal } from "./AddProductModal.styled";

const AddProductModal = ({
  closeModal,
  addProduct,
  editProduct,
  itemName,
  fields,
}) => {
  const prefiled = fields
    ? {
        id: fields.id,
        productName: fields.productName,
        productURL: fields.productURL,
        photoURL: fields.photoURL,
        price: fields.price,
      }
    : { productName: "", productURL: "", photoURL: "", price: "" };

  const [state, setState] = useState(prefiled);

  const handleChange = (e) => {
    const { target } = e;
    const { name } = target;

    setState({
      ...state,
      [name]: target.value,
    });
  };

  const submit = () => {
    if (!fields) {
      addProduct(state);
      return;
    }
    editProduct(state);
  };

  const getDescription = () => {
    if (!fields) {
      return (
        <React.Fragment>
          <HelpText align="left" fullsize fontSize="24px" fontWeight="bold">
            Add {itemName}
          </HelpText>
          <Spacer height="10" />
          <HelpText align="left" fullsize color="#828282">
            We, unfortunately, can’t add that product right now. Add any product
            by copy and pasting its name, URL, and photo URL here. Have
            questions?{" "}
            <Link.Html
              href="mailto:help@choosy.tools"
              underline
              nohover
              customStyle="display: inline"
            >
              Get in touch
            </Link.Html>
          </HelpText>
          <Spacer height="30" />
        </React.Fragment>
      );
    }

    return null;
  };

  const getPriceField = () => {
    if (fields) {
      return (
        <React.Fragment>
          <Input.Input
            name="price"
            type="text"
            value={state.price}
            placeholder="Price"
            onChange={handleChange}
          />
          <Spacer height="15" />
        </React.Fragment>
      );
    }

    return null;
  };

  return (
    <ModalWrapper>
      <Modal>
        {getDescription()}
        <Input.Input
          name="productName"
          type="text"
          value={state.productName}
          placeholder="Product Name"
          onChange={handleChange}
        />
        <Spacer height="15" />
        <Input.Input
          name="productURL"
          type="text"
          value={state.productURL}
          placeholder="Product URL"
          onChange={handleChange}
        />
        <Spacer height="15" />
        <Input.Input
          name="photoURL"
          type="text"
          value={state.photoURL}
          placeholder="Photo URL"
          onChange={handleChange}
        />
        <Spacer height="15" />
        {getPriceField()}
        <Button onClick={submit}>{fields ? "Update" : "Add"}</Button>
        <Spacer height="15" />
        <HelpText color="#828282">
          Or,{" "}
          <HelpText
            decoration="underline"
            display="inline"
            cursor="pointer"
            onClick={closeModal}
          >
            cancel and go back
          </HelpText>
        </HelpText>
      </Modal>
    </ModalWrapper>
  );
};

export default AddProductModal;

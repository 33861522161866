import { withRouter } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';

const Hide = ({ location, routes, children }) => {
  const regExpRoutes = routes.map(route => pathToRegexp(route));
  if (regExpRoutes.some(route => route.test(location.pathname))) {
    return null;
  }

  return children;
};

export default withRouter(Hide);

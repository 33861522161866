import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;

  @media screen {
    display: block;
    margin-bottom: 75px;
  }

  justify-content: ${props => (props.verticalAlignTop ? 'flex-start' : 'flex-end')};
  align-items: ${props => (props.horizontalAlignLeft ? 'left' : 'center')};
`;

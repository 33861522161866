import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from "react-router-dom"

import Link from '../../elements/Link/Link';

import Wrapper from './ProposalTopNavigation.styled'

const ProposalTopNavigation = () => {
  const proposal = useSelector(state => state.proposalView?.proposal || state.edit );
  const user = useSelector(state => state.authorization.user);
  const location = useLocation();

  const [ scrolled, setScrolled ] = useState(window.pageYOffset > 1)
  const showShopLink = !proposal.costGroups.map(costGroup => (costGroup.values.filter(({ selection }) => selection))).every(costGroup => costGroup.length === 0)

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 1) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  if(!proposal || !user) {
    return null
  }

  return (
    <Wrapper scrolled={scrolled}>
      <Link.Router active={(location.pathname.indexOf('selections') === -1).toString()} to={`/proposal/${proposal._id}`}>Plan</Link.Router>
      {showShopLink && <Link.Router active={(location.pathname.indexOf('selections') !== -1).toString()} to={`/proposal/${proposal._id}/selections`}>Shop</Link.Router>}
      {!proposal?.events?.signedByHomeowner && user?.role !== "homeowner" && <Link.Router to={`/proposal/edit/${proposal?._id}`}>Edit</Link.Router>}
    </Wrapper>
  )
}

export default ProposalTopNavigation

import React from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';

import ProposalView from '../ProposalView';
import Navigation from '../../composables/Navigation';

const Wrapper = styled.div`
  @media print {
      &, & * {
        page-break-inside: avoid !important;
        -webkit-region-break-inside: avoid !important;
      }
    }
`;

const PrintProposal = () => {
  const cookie = document.cookie.split(';').find(el => el.indexOf('STRUK_BUILT_ACCESS') !== -1).split('=')[1];

  if (cookie !== 'secret_salt') {
    return null;
  }

  return (
    <Wrapper>
      <Navigation />
      <Route path="/print/:id" component={ProposalView} />
    </Wrapper>
  );
};


export default PrintProposal;

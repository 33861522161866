import styled, { css } from "styled-components";
import media, { MEDIUM, SMALL } from "../../style/media-query";

const reelSize = "250px";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 10px;

  ${media.lessThan(SMALL)`
    scroll-snap-type: x mandatory;
  `}

  ${media.greaterThan(MEDIUM)`
    margin-bottom: 10px;
  `}
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  height: ${reelSize};
  align-items: center;
  justify-content: center;
`;

export const SelectionItem = styled.div`
  position: relative;
  width: calc(${reelSize} - 20px - 2px);
  min-width: calc(${reelSize} - 20px - 2px);
  padding: 15px;
  margin: 10px 10px 10px 0;
  scroll-snap-align: start;
  background-color: #fff;
  box-sizing: content-box;

  border: 1px solid var(--line-color);
  border-radius: 0.4rem;

  ${media.greaterThan(MEDIUM)`
    margin-top: 10px;
  `}

  ${(props) =>
    props.isDragging &&
    css`
      opacity: 0.8;
    `}

    &:hover {
    box-shadow: 2px 2px 3px 0px #c5c5c580;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

export const SelectionChooseButton = styled.a`
  width: 160px;
  height: 28px;

  font-family: Inter, serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #f2f2f2;

  background: #486aff;
  border-radius: 7px;

  cursor: pointer;

  border: 0;
  text-decoration: none;
`;

export const SelectionLabelWrapper = styled.span`
  width: 100%;
`;

export const SelectionLabel = styled.label`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

export const ChoisePositionLabel = styled.label`
  display: inline-block;
  margin-top: 5px;
  font-family: Inter, serif;
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  background: rgba(169, 185, 189);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 10px;
  border-radius: 3px;
`;

export const SelectionLink = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  display: flex;
  align-items: center;

  color: #bcbbc1;
  color: ${(props) => (props.color ? props.color : "#000")};
`;

export const SelectionImage = styled.img`
  width: calc(${reelSize} - 2 * 10px);
`;

export const BottomPanel = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DraggingZone = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
`;

export const RemoveSelectionButton = styled.div`
  position: absolute;
  right: -8px;
  top: -8px;
  border: 1px solid var(--line-color);
  background-color: white;
  padding: 2px;
  border-radius: 0.4rem;
`;

export const NoCurrency = styled.span`
  .default_currency {
    color: var(--color-warning);
    text-decoration: line-through;
    margin-left: 3px;
  }
`;

import { resetImages } from "./images";

export const TRIGGER_MODAL = "TRIGGER_MODAL";

export const triggerModal = ({ name, state, fields }) => {
  if (state) {
    // disableBodyScroll(document.getElementById('modal-container'))
  } else {
    // enableBodyScroll(document.getElementById('modal-container'))
  }

  return {
    type: TRIGGER_MODAL,
    payload: {
      name,
      state,
      fields,
    },
  };
};

export const hideActiveModals = () => (dispatch, getState) => {
  const openedModals = getState().modals.filter(({ state }) => state);

  openedModals.forEach(({ name }) => {
    dispatch(
      triggerModal({
        name,
        state: false,
      })
    );

    dispatch(resetImages());
  });

  // enableBodyScroll(document.getElementById('modal-container'))
};

export default function ModalsReducer(
  state = [
    {
      name: "createProposal",
      state: false,
    },
    {
      name: "addProduct",
      state: false,
    },
    {
      name: "addManualProduct",
      state: false,
    },
  ],
  action
) {
  switch (action.type) {
    case TRIGGER_MODAL: {
      const restModals = state.filter(
        ({ name }) => name !== action.payload.name
      );

      return [
        ...restModals,
        {
          name: action.payload.name,
          state: action.payload.state,
          fields: action.payload.fields,
        },
      ];
    }

    default:
      return state;
  }
}

import styled from "styled-components";
import { mdScreen } from "../../style/media-query";

export const CommentsWrapper = styled.div`
  margin: 2rem 0;

  @media (min-width: ${mdScreen}) {
    margin: 2rem 3rem;
  }

  h4 {
    margin-bottom: 1rem;
  }

  .no-comments {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding: 2rem 1rem;
  }
`;

export const Comment = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;

  .comment-content {
    background-color: var(--line-color-softer);
    padding: 1rem;
    margin-bottom: 0.25rem;
    border-radius: 0.4rem;
  }

  .comment-email {
    font-weight: 500;
  }

  .comment-text {
    padding-top: 0.5rem;
  }

  .comment-date {
    text-align: right;
    font-size: 0.8rem;
  }
`;

export const PostCommentWrapper = styled.div`
  display: flex;
`;

import React from "react";

import { allowedFiles } from "../../constants/mime-types";

import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import { BigPictureNext } from "../../constants/analyticEvents";

import ImageReel from "../../components/ImageReel/ImageReel";
import DropZone from "../../components/DropZone/DropZone";
import SectionToggle from "../../components/SectionToggle/SectionToggle";

import Spacer from "../../elements/Spacer/Spacer";
import Link from "../../elements/Link/Link";

import { PreviewWrapper, Heading, Text } from "./BigPicture.styled";

const BigPicture = ({
  images,
  create,
  setField,
  uploadMultipleFiles,
  removeMultipleFiles,
}) => {
  return (
    <section id="step_3_big_picture" className="proposal_step">
      <div className="container">
        <div className="row">
          <div className="proposal_preview_edit">
            <div id="preview">
              <PreviewWrapper>
                {create.sectionVisibility.bigpicture && (
                  <>
                    <Heading>Scope</Heading>
                    <Text>
                      {create.projectScope || "Our goal for this project is..."}
                    </Text>
                    <Heading>Team</Heading>
                    <Text>
                      {create.teamDescription || "Our team has been around..."}
                    </Text>
                    <Heading>Photos and documents</Heading>
                    <ImageReel items={images.currentCondition} withFiles />
                  </>
                )}
              </PreviewWrapper>
            </div>
            <div className="phone_bg" />
          </div>
          <div className="proposal_content">
            <h1>3. Big Picture</h1>
            <SectionToggle
              create={create}
              setField={setField}
              section="bigpicture"
            />
            <h4>
              A clear and concise summary lets your customer know you understand
              their goals, and that you listened to them.
            </h4>

            <form className="form">
              <p>
                <label>
                  Add the scope of the project{" "}
                  <a className="info_tooltip">
                    i{" "}
                    <span className="tooltip_content">
                      Here's where you summarize the project. This section
                      generally starts with a broad description of the project,
                      and then lists some or all of the components.
                    </span>
                  </a>
                </label>
                <textarea
                  defaultValue={
                    create.projectScope || "Our goal for this project is..."
                  }
                  name="projectScope"
                  onChange={(e) => setField("projectScope", e.target.value)}
                />
              </p>
              <p>
                <label>
                  Add information about your team{" "}
                  <a className="info_tooltip ">
                    i{" "}
                    <span className="tooltip_content">
                      Write up a description of your company that tells your
                      customer about who you are and how you operate. Think of
                      it like your profile description.
                    </span>
                  </a>
                </label>
                <textarea
                  defaultValue={
                    create.teamDescription || "Our team has been around..."
                  }
                  name="teamDescription"
                  onChange={(e) => setField("teamDescription", e.target.value)}
                />
              </p>
              <div>
                <label>Upload photos and documents</label>
                <Spacer height="10" />
                <DropZone
                  items={images.currentCondition}
                  accept={allowedFiles}
                  multiple
                  maxSize={15}
                  uploadHandler={(image, file) => {
                    uploadMultipleFiles("currentCondition", {
                      binary: image.target.result,
                      id: Date.now(),
                      file,
                    });
                  }}
                  deleteHandler={(item) =>
                    removeMultipleFiles("currentCondition", item.id)
                  }
                />
                {images.currentCondition.length > 0 && (
                  <small>
                    Upload another file by draging them into the area above or
                    by clicking on that area. Remove files by clicking "X" icon
                    at the top left corner of the images above.
                  </small>
                )}
              </div>
            </form>
            <div className="center_button">
              <Link.Scroll
                className="button-big Button-module--button--2mvQU"
                to="#step_4_plan"
                onClick={() => {
                  analytics.track({
                    event: generateAnalyticsPrefix(BigPictureNext),
                  });
                }}
              >
                Next
              </Link.Scroll>
              <small>3 of 5</small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BigPicture;

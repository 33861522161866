
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, withProps, lifecycle } from 'recompose';

import analytics, { generateAnalyticsPrefix } from '../../helpers/analytics';
import { CreatorViewProposal } from '../../constants/analyticEvents';

import { sendCreatedProposal as sendCreatedProposalAction } from '../../ducks/emails';

import ProposalCreated from './ProposalCreated';

const mapStateToProps = state => ({
  emails: state.emails,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  sendCreatedProposal: sendCreatedProposalAction,
}, dispatch);

const props = ({
  proposal,
  sendCreatedProposal,
  match,
}) => ({
  proposal,
  signUp: match.path.indexOf('signup') !== -1,
  sendCreatedProposal: email => () => {
    analytics.track({
      event: generateAnalyticsPrefix(CreatorViewProposal),

    });

    sendCreatedProposal(email);
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(props),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const { history, match } = this.props;

      if (!nextProps.emails.fetching && nextProps.emails.sent && match.url.indexOf('sent') === -1) {
        history.replace(`/proposal/sent/${match.params.id}/`);
      }
    },
  }),
)(ProposalCreated);

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 0;
  height: 60px;
  display: flex;
  justify-content: center;

  @media print {
    display: none;
  }
`;

export const Holder = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
  border-top: 2px solid #b5b8bc;

  * {
    color: #828282;
    font-family: Inter, serif;
    font-size: 16px;
    line-height: 17px;
  }
`;

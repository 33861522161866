import React, { Fragment, useState } from 'react';
import {Link} from 'react-router-dom';

import Icon from '../../elements/Icon/Icon';
import Headline from '../../elements/Headline/Headline';
import HR from '../../elements/HR/HR';
import Input from '../../elements/Input/Input';
import Spacer from '../../elements/Spacer/Spacer';
import Button from '../../elements/Button/Button';
import HelpText from '../../elements/HelpText/HelpText';
import Content from '../../elements/Content/Content';

const ProposalCreated = ({
  proposal,
  sendCreatedProposal,
  match,
  userEmail,
  signUp,
}) => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState(userEmail);

  if (proposal === null) {
    return null;
  }

  const handleInputChange = (e) => {
    setEmail(e.target.value);

    if (e.target.value === '') {
      setError('');
      return;
    }

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setError('Please enter a valid email address.');
    } else {
      setError('');
    }
  };

  return proposal !== null && (
    <Content>
      <Icon name="paper-stack" size="120" customStyle="transform: rotate(-4deg);" />
      <Headline align="left">Proposal Created: {signUp ? 'Sign Up' : 'Log In'}</Headline>
      <HR />
      <Spacer height="17.6" />
      <HelpText
        fontSize="17px"
        lineHeight="25px"
        color="#333333"
      >
          Confirm your email and we’ll send a link so you can review, edit, and send your proposal to your prospective client.
      </HelpText>
      <Spacer height="45" />
      <Input.Input label="Your Email" type="email" name="email" onChange={handleInputChange} error={error} defaultValue={email} />
      <Spacer height="20" />
      <Button disabled={error || email === ''} onClick={sendCreatedProposal(email)}>View Proposal</Button>
      <Spacer height="10" />
      <HelpText
        fontFamily="Inter"
        fontSize="13px"
        lineHeight="17px"
        color="#828282"
      >
          We'll never spam you or share your information.
      </HelpText>
      <Spacer height="60" />
      <HelpText
        fontFamily="Inter"
        fontSize="13px"
        lineHeight="17px"
        color="#828282"
      >
        {signUp ? (<Fragment>Already have an account? <Link to={`/proposal/login/${match.params.id}`}>Log in here.</Link></Fragment>) : (<Fragment>Don't have an account? <Link to={`/proposal/signup/${match.params.id}`}>Sign up here.</Link></Fragment>)}
      </HelpText>
    </Content>
  );
};

export default ProposalCreated;

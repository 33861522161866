import React from "react";
import styled from "styled-components";
import { v4 } from "uuid";

const Wrapper = styled.div`
  position: relative;
  width: ${(props) => (props.wrapperWidth ? props.wrapperWidth : "100%")};

  @media (max-width: 770px) {
    width: 100%;
  }
`;

const CheckboxWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: ${(props) => props.left}px;
`;

const StyledLabel = styled.label`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #bcbbc1;

  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  padding: 0 10px;
`;

const ErrorLabel = styled.label`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #b96565;

  margin: 2px 0 0 10px;
  display: block;
`;

const StyledInput = styled.input`
  height: ${(props) => (props.height ? props.height : "52px")};
  width: 100%;
  border: 1px solid var(--line-color);
  border-radius: 0.4em;
  box-sizing: border-box;

  outline: 0;
  padding: ${(props) => (props.withLabel ? "20px" : "8px")} 15px 8px 15px;

  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;

  color: #828282;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    border-color: #486aff;

    & + ${StyledLabel} {
      color: #486aff;
    }
  }

  ${(props) => {
    if (props.error) {
      return `
        border-color: #B96565;

        &+${StyledLabel} {
          color: #B96565;
        }

        &:focus{
          border-color: #B96565;

          &+${StyledLabel} {
            color: #B96565;
          }
        }
     `;
    }

    return "";
  }}

  &::placeholder {
    color: #bcbbc1;
  }

  ${(props) => (props.customStyle ? props.customStyle : "")}
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  border: 1px solid #bcbbc1;
  box-sizing: border-box;
  border-radius: 7px;

  outline: 0;
  padding: 20px 15px 8px 20px;

  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;

  color: #828282;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    border-color: #486aff;

    & + ${StyledLabel} {
      color: #486aff;
    }
  }
`;

const StyledCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    top: -2px;
    padding: 0;
  }

  // Box.
  & + label:before {
    --checkbox-size: 15px;
    content: "";
    display: block;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    background-color: var(--background-color);
    border: 1px solid var(--highlight-color);
    border-radius: 100%;
  }

  &:checked + label:before {
    background: #f35429;
  }
`;

/*

// Box hover
  &:hover + label:before {
    background: #f35429;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked


  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }

 */

const Input = ({ label, error, ...rest }) => (
  <Wrapper {...rest}>
    <StyledInput {...rest} error={error} withLabel={!!label} />
    {label && <StyledLabel error={error}>{label}</StyledLabel>}
    {error && <ErrorLabel>{error}</ErrorLabel>}
  </Wrapper>
);

const Textarea = ({ label, ...rest }) => (
  <Wrapper>
    <StyledTextarea {...rest} />
    {label && <StyledLabel>{label}</StyledLabel>}
  </Wrapper>
);

const CheckBox = ({ left, label, id = v4(), ...rest }) => (
  <CheckboxWrapper left={left}>
    <StyledCheckbox id={id} {...rest} />
    <label htmlFor={id} />
    {label && <StyledLabel>{label}</StyledLabel>}
  </CheckboxWrapper>
);

export default {
  Input,
  Textarea,
  CheckBox,
};

import React, { Fragment } from 'react';

import { S3_URL } from '../../constants';

import DefaultAvatar from '../../assets/images/default-avatar.png';

import {
  Wrapper,
  Header,
  PhotoWrapper,
  Photo,
  Date,
  TitleWrapper,
  Name,
  To,
  Message,
} from './WelcomePreview.styled';

const WelcomePreview = ({
  mode = 'default',
  personalPhoto,
  date,
  name,
  introductoryNote,
  companyName,
  phoneNumber,
  to = 'me',
}) => (
  <Wrapper>
    <Header>
      <PhotoWrapper>
        {mode === 'view' && (
          <Photo src={
            personalPhoto ? `${S3_URL}/${personalPhoto}` : DefaultAvatar
          } />
        )}
        {mode !== 'view' && (
          <Photo src={
            personalPhoto ? personalPhoto.file ? `${S3_URL}/${personalPhoto.file}` : DefaultAvatar : DefaultAvatar
          } />
        )}
      </PhotoWrapper>
      <Date>
        {date}
      </Date>
      {mode === 'view' && name && (
      <TitleWrapper>
        <Name>{name || 'Contractor Name'}</Name>
        <To>to {to}</To>
      </TitleWrapper>
      )}
      {mode !== 'view' && (
        <TitleWrapper>
          <Name>{name || 'Contractor Name'}</Name>
          <To>to me</To>
        </TitleWrapper>
      )}

    </Header>
    <Message>
      {introductoryNote}
      <br /><br />
      {mode === 'view' && name && (
        <Fragment>
          Best, <br />
        </Fragment>
      )}
      {mode !== 'view' && (
        <Fragment>
          Best, <br />
        </Fragment>
      )}
      {mode === 'view' && name && (
        <Fragment>
          {name || 'Contractor Name'}<br />
        </Fragment>
      )}
      {mode !== 'view' && (
        <Fragment>
          {name || 'Contractor Name'}<br />
        </Fragment>
      )}
      {mode === 'view' && companyName && (
        <Fragment>
          {companyName || 'Company'}<br />
        </Fragment>
      )}
      {mode !== 'view' && (
        <Fragment>
          {companyName || 'Company'}<br />
        </Fragment>
      )}
      {mode === 'view' && phoneNumber && (
        <Fragment>
          {phoneNumber || 'Phone Number'}<br />
        </Fragment>
      )}
      {mode !== 'view' && (
        <Fragment>
          {phoneNumber || 'Phone Number'}<br />
        </Fragment>
      )}
    </Message>
  </Wrapper>
);

export default WelcomePreview;

import styled from "styled-components";
import { xlScreen, lgScreen, mdScreen } from "../../style/media-query";
import {
  wrapperWidth,
  lgSpacing,
  mdSpacing,
  smSpacing,
} from "../../style/landing-page";

export const Wrapper = styled.div`
  max-width: ${wrapperWidth};
  margin: 0 auto;

  h1 {
    font-weight: 900;
    font-size: 60px;
    line-height: 56px;
    margin: 30px 0;

    span {
      color: var(--choosy-blue);
    }
  }

  h2 {
    font-size: 46px;
    font-weight: 700;
    line-height: 56px;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin: 8px 0 24px;
  }

  h4 {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
  }
`;

export const Heading = styled.h1`
  font-family: "Kanit", sans-serif;
  text-align: ${(props) => props.alignment};
  color: ${(props) => (props.headingColor ? props.headingColor : "#000")};
`;

export const Paragraph = styled.p`
  margin: 0;
  text-align: ${(props) => props.alignment};
  color: ${(props) => (props.textColor ? props.textColor : "#000")};
`;

export const SectionWrapperHalf = styled.section`
  display: grid;
  padding: 0 ${smSpacing};
  background-color: ${(props) =>
    props.sectionColor ? props.sectionColor : "#fff"};
  color: ${(props) =>
    props.sectionTextColor ? props.sectionTextColor : "#000"};

  @media (min-width: ${mdScreen}) {
    padding: 0 ${mdSpacing};
  }

  @media (min-width: ${lgScreen}) {
    grid-template-columns: ${(props) =>
      props.equalCols ? "repeat(2, 1fr)" : "40% 1fr"};
    align-items: center;
  }

  @media (min-width: ${xlScreen}) {
    padding: 0 ${lgSpacing};
  }
`;

export const SectionContent = styled.div`
  @media (max-width: ${lgScreen}) {
    order: ${(props) => props.elOrder};
  }

  ${(props) =>
    props.rightSide &&
    `
      margin: 30px 0 0 0;
      @media (min-width: ${lgScreen}) {
        margin: 0 0 0 20%;
      }
    `};
`;

const imgMarginPosition = (directionRight, spacing) => {
  return directionRight ? `0 ${`-${spacing}`} 0 0` : `0 0 0 ${`-${spacing}`}`;
};

export const SectionImg = styled.div`
  margin: ${(props) =>
    props.directionRight
      ? imgMarginPosition(true, smSpacing)
      : imgMarginPosition(false, smSpacing)};

  @media (min-width: ${mdScreen}) {
    margin: ${(props) =>
      props.directionRight
        ? imgMarginPosition(true, mdSpacing)
        : imgMarginPosition(false, mdSpacing)};
  }

  @media (max-width: ${lgScreen}) {
    order: ${(props) => props.elOrder};
  }

  @media (min-width: ${xlScreen}) {
    margin: ${(props) =>
      props.directionRight
        ? imgMarginPosition(true, lgSpacing)
        : imgMarginPosition(false, lgSpacing)};
  }

  img {
    margin-top: 30px;
    max-width: 100%;
  }
`;

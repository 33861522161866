const documentNameRegex = /-NAME-(.+)-NAME-/;

export default function getDocumentName(url) {
  const documentName = documentNameRegex.exec(url);

  if(documentName === null) {
    return url;
  }

  if(documentName.length === 2) {
    return documentName[1];
  }

  return url;
}
import styled from "styled-components";

export const ListItem = styled.div`
  display: block;
  font-family: var(--font-family-label);
  font-weight: 400;
  line-height: 0.8;
`;

export const AddItemWrapper = styled.form`
  display: flex;
`;

export const ListItemNote = styled.div``;

export const NoteLabel = styled.label`
  display: block;
  font-size: 16px;
  padding-bottom: 8px;
`;

export const NoteInput = styled.textarea`
  width: calc(50% + 110px);
  height: 34px;
  padding: 8px;
  border-radius: 0.4rem;
  font-size: 14px;
  color: var(--text-color-soft);
  border: 1px solid var(--line-color);
  background-color: rgba(72, 106, 255, 0.1);
  resize: none;

  @media (max-width: 770px) {
    width: 100%;
  }
`;

export const DnDTooltip = styled.div`
  font-size: 14px;
  color: #a9b9be;
  text-align: center;
`;

export const AddProductInfo = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const AddManualLink = styled.span`
  color: var(--choosy-blue);
  cursor: pointer;
`;

import styled from 'styled-components';

export const IconHolder = styled.div`
  font-size: 10rem;
  position: relative;
  color: var(--line-color-softer);
  z-index: 0;

  /* FIXME: Icon overlap issue */
  display: none;

  @media print {
    display: none;
  }
`;

export const Content = styled.div`
  z-index: 1;
`;

import styled from 'styled-components';

export const List = styled.div``;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.2rem 0;
  font-family: var(--font-family-label);
  font-weight: 500;
  line-height: .8;
  border-bottom: 1px solid var(--line-color-soft);
`;

export const Label = styled.label`
`;

export const Cost = styled.code`
  float: right;
  font-family: var(--font-family-monospace);
`;

export const Note = styled.small`
  display: block;
  max-width: 25rem;
  margin-top: 0.5rem;
  font-weight: 500;
  font-family: var(--font-family-label);
  font-size: 0.8rem;
  line-height: 1.3;
  color: var(--text-color-soft);
`;

import styled from 'styled-components';

export default styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #333;
  word-wrap: break-word;
  padding: 20px
`;

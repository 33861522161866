
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  authorization: state.authorization,
});

export default connect(
  mapStateToProps,
)(({
  authorization,
  children,
  forAuthorized,
  forUnathorized,
}) => {
  if (authorization) {
    if (forAuthorized && authorization.authorized) {
      return children;
    }

    if (forUnathorized && !authorization.authorized) {
      return children;
    }
  }

  return null;
});

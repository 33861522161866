import styled from 'styled-components';

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #333;
  word-wrap: break-word;
  padding: 20px;
`;

export const Heading = styled.h2`
`;

export const Text = styled.p`
  margin: 0 0 1.5rem;
  font-family: var(--font-family-body);
  font-size: 1.05rem;
  line-height: 1.5;
  white-space: pre-wrap;
`;

import styled from 'styled-components';

export const Logo = styled.img`
  width:150px;
  align-self: center;

  @media print {
    margin-top: 30px;
  }
`;

export const CompanyName = styled.h3`
  margin-top: 1rem;
  font-size: 1.1rem;
  text-align: center;
`;

export const ProjectName = styled.h1`
  font-size: 3rem;
  line-height: 1.1;
`;

export const Address = styled.h3`
  font-size: 1rem;
`;

export const Wrapper = styled.div`
  ${props => (props.mode === 'view' ? `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
    width: 100%;

    ${Logo} {
      width: 172px;
    }

    ${CompanyName} {
      font-size: 1.5rem;
    }

    ${ProjectName} {
      font-size: 4rem;
      line-height: 1.1;
      align-self: flex-start;
    }

    ${Address} {
      font-size: 1.4rem;
      align-self: flex-start;
    }
  ` : `

  display: flex;
    flex-direction: column;`)}
`;

import styled from 'styled-components';

export const WrapperAvatar = styled.div `
  background-color: ${props => props.isImg ? "transparent" : (props.color)};
  color: ${props => props.isImg ? "transparent" : (props.textColor)};
  width: ${props => (props.size)};
  height: ${props => (props.size)};
  max-width: ${props => (props.size)};
  max-height: ${props => (props.size)};
  border-radius: ${props => (props.borderRadius)};
  line-height: ${props => (props.size)};
  text-align: center;
`;

export const ImageAvatar = styled.img `
  border-radius: ${props => (props.borderRadius)};
  width: ${props => (props.size)};
  height: ${props => (props.size)};
  display: block;
`;
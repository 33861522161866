import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

export const Action = styled.span`
  transition: color 0.2s linear;

  &:hover {
    color: #333;
  }
`;

import { generateMedia, defaultBreakpoints } from "styled-media-query";

export const LARGE = "large";
export const MEDIUM = "medium";
export const SMALL = "small";

export const xlScreen = "1280px";
export const lgScreen = "1024px";
export const mdScreen = "768px";

export const breakpoints = {
  large: "1430px",
  medium: "1050px",
  small: defaultBreakpoints.small,
};

export default generateMedia(breakpoints);

import React from "react";

import Cover from "../../composables/Cover";
import Intro from "../../composables/Intro";
import Welcome from "../../composables/Welcome";
import BigPicture from "../../composables/BigPicture";
import ThePlan from "../../composables/ThePlan";
import ShoppingList from "../../composables/ShoppingList";
import Contract from "../../composables/Contract";
import Navigation from "../../composables/Navigation";

import Footer from "../../components/Footer/Footer";

import Wrapper from "./ProposalEdit.styled";

const ProposalEdit = ({
  edit,
  images,
  setField,
  uploadSingleFile,
  removeSingleFile,
  uploadMultipleFiles,
  removeMultipleFiles,
  createCostGroup,
  editProposal,
  orderCostGroup,
}) =>
  edit.proposalId !== null && (
    <Wrapper>
      <Navigation />
      <Intro edit />
      <Cover
        setField={setField}
        create={edit}
        images={images}
        uploadSingleFile={uploadSingleFile}
        removeSingleFile={removeSingleFile}
      />
      <Welcome
        setField={setField}
        create={edit}
        images={images}
        uploadSingleFile={uploadSingleFile}
        removeSingleFile={removeSingleFile}
      />
      <BigPicture
        setField={setField}
        create={edit}
        images={images}
        uploadMultipleFiles={uploadMultipleFiles}
        removeMultipleFiles={removeMultipleFiles}
      />
      <ThePlan
        setField={setField}
        create={edit}
        createCostGroup={createCostGroup}
        orderCostGroup={orderCostGroup}
        mode="edit"
      />
      <ShoppingList setField={setField} create={edit} mode="edit" />
      <Contract
        setField={setField}
        create={edit}
        editProposal={editProposal}
        edit
      />
      <Footer fullwidth />
    </Wrapper>
  );

export default ProposalEdit;

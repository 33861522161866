import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Header = styled.header`
  overflow: hidden;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const PhotoWrapper = styled.div``;

export const Photo = styled.img`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  text-indent: -9999rem;
  border-radius: 50%;
`;

export const Date = styled.div`
  order: 1;
  line-height: 1.3;
  font-weight: 500;
  color: #828282;
  font-family: var(--font-family-label);
`;

export const TitleWrapper = styled.div`
  flex: 1;
  margin-left: 15px;
`;

export const Name = styled.div`
  line-height: 1.3;
  display: block;
  font-weight: 500;
  font-family: var(--font-family-label);
`;

export const To = styled.div`
  font-family: var(--font-family-label);
  line-height: 1.3;
  font-weight: 500;
  color: #828282;
`;

export const Message = styled.div`
  font: 1rem/1.5 var(--font-family-body);
  white-space: pre-wrap;
`;

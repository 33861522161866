import styled from "styled-components";

import media, { LARGE, MEDIUM, SMALL } from "../../style/media-query";

import carrotAndFinish from "./assets/carrot_and_finish.svg";

import lightning from "../../assets/images/lightning.png";

export const Wrapper = styled.div``;

export const Hero = styled.div`
  height: 444px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: ${(props) => props.offsetY || "0"};
  margin-top: -41px;
  padding: 8% 0 0 10%;
  overflow: hidden;

  ${(props) =>
    Object.entries(props.background).map((background) => {
      const [breakpoint, image] = background;
      return media.lessThan(breakpoint)`
        background-image: url(${image});
      `;
    })}

  ${(props) => media.greaterThan(LARGE)`
    background-image: url(${props.background[LARGE]});
  `}

  ${media.lessThan(MEDIUM)`
    padding-top: 82px;
    margin-top: -71px;
    padding-left: 20px;
  `}

  ${media.lessThan(SMALL)`
    padding-top: 140px;
  `}
`;

export const Text = styled.span`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  text-align: ${(props) => (props.align ? props.align : "left")};

  ${(props) =>
    props.hero1 &&
    media.lessThan(SMALL)`
    width: 100%;
    font-size: 42px;
  `};

  ${(props) =>
    props.hero1 &&
    `
    font-weight: bold;
    font-size: 56px;
    line-height: 102%;

    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

    color: #FAFAFA;
    width: 390px;
  `}
  ${(props) =>
    props.hero2 &&
    `
    font-size: 15px;
    line-height: 18px;

    color: #FAFAFA;
  `}
  ${(props) =>
    props.large &&
    `
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    color: #333333;
  `}
  ${(props) =>
    props.small &&
    `
    font-size: 13px;
    line-height: 17px;

    text-align: center;

    color: #828282;
  `}
  ${(props) =>
    props.heading &&
    `
    font-weight: 500;
    font-size: 34px;
    line-height: 112%;
    color: #333333;
  `}
  ${(props) =>
    props.cardHeading &&
    `
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    text-transform: uppercase;

    color: #333333;
  `}
  ${(props) =>
    props.cardDescription &&
    `
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 147.71%;

    color: #828282;
  `}
  ${(props) =>
    props.copyright &&
    `
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    color: #828282;
  `}
  ${(props) =>
    props.linkHeading &&
    `
    font-size: 24px;
    line-height: 29px;
    color: #333333;
  `}
  ${(props) =>
    props.linkSubheading &&
    `
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #828282;
  `}


  ${(props) =>
    props.left &&
    `
    align-self: flex-start;
  `}
  ${(props) =>
    props.dark &&
    `
    color: #333;
  `}
  ${(props) =>
    props.bold &&
    `
    font-weight: bold;
  `}
  ${(props) =>
    props.italic &&
    `
    font-style: italic;
  `}

  width: ${(props) => (props.width ? props.width : "inherit")};

  ${(props) =>
    props.fullwidth &&
    `
    width: 100%;
    display: block;
  `}
`;

export const Schedule = styled.div`
  height: 30px;
  background: #8fa8ad;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 6px 0 9px 0;

  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #fafafa;
`;

export const Section = styled.div`
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.lessThan(MEDIUM)`
      width: 90%;
  `}
`;

export const CarrotAndFinish = styled.div`
  height: 40px;
  background: url(${carrotAndFinish});
  background-repeat: no-repeat;
  background-position-x: 30px;

  & > * {
    opacity: 0;
  }
`;

export const Button = styled.div`
  background: #486aff;
  border-radius: 7px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f2f2f2;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 70px;
  cursor: pointer;

  ${media.lessThan(SMALL)`
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

export const Table = styled.table`
  border: 1px solid #dadada;
  border-radius: 7px;
  border-spacing: 0;

  & > *:first-child > *:first-child {
    border-top: 0;
    border-left: 0;
  }

  & > * > *:first-child {
    border-left: 0;
  }

  & > *:first-child > *:last-child {
    border-top: 0;
    border-right: 0;
  }

  & > *:last-child > *:first-child {
    border-bottom: 0;
    border-left: 0;
  }

  & > *:last-child > *:last-child {
    border-bottom: 0;
    border-right: 0;
  }

  & > *:last-child > * {
    border-bottom: 0;
  }
`;

export const Row = styled.tr``;

export const Column = styled.td`
  border-bottom: 1px solid #dadada;
  border-left: 1px solid #dadada;

  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 113.33%;
  font-weight: 500;

  text-align: ${(props) => props.textAlign || "left"};
  vertical-align: ${(props) => props.vAlign || "top"};

  ${(props) =>
    props.highlighted
      ? `
    color: #333333;
    background: rgba(101, 185, 155, 0.15);
  `
      : `
    color: #828282;
  `}

  ${(props) =>
    props.bold &&
    `
    font-weight: bold;
  `}

  ${(props) =>
    props.large &&
    `
    font-size: 17px;
  `}

  padding: 10px;
`;

export const TableCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LightningBolt = styled.span`
  width: 10.17px;
  height: 20px;
  display: inline-block;
  background: url(${lightning});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${(props) =>
    props.fullwidth &&
    `
    & > * {
      flex: 0 calc(100%) !important;
      margin: 10px 0!important;
    }
  `};
`;

export const Card = styled.div`
  box-sizing: border-box;
  flex: 0 calc(50% - 30px);
  margin: 10px 10px 17px 10px;

  img {
    width: 100%;
  }

  &:nth-child(odd) {
    margin-left: 0;
  }

  &:nth-child(even) {
    margin-right: 0;
    margin-left: 20px;
  }

  ${media.lessThan(LARGE)`
    flex: 0 100%;
    margin: 0 0 10px 0 !important;
  `}
`;

export const CardImage = styled.div`
  height: ${(props) => props.height};
  width: 100%;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  background-position-y: ${(props) =>
    props.backgroundOffset ? props.backgroundOffset : "0"};

  img {
    opacity: 0;
  }
`;

import React, { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import Input from "../../elements/Input/Input";
import Button from "../../elements/Button/Button";
import Spacer from "../../elements/Spacer/Spacer";

import SendInvitationWrapper from "./InviteToProposal.styled";

const InviteToProposal = ({
  proposal,
  inviteUserToProposal,
  sendingInvite,
}) => {
  const urlInputStyle = `
  border: 1px solid var(--line-color);
  border-radius: 0.4em;
  box-shadow: none;
`;
  const buttonStyle = `
  height: 47px
`;
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);

    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
    }
  };

  const sendInvitation = () => {
    inviteUserToProposal(proposal._id, email);
  };

  useEffect(() => {
    if (!sendingInvite) {
      setEmail("");
    }
  }, [sendingInvite]);

  return (
    <>
      <label>Invite users to this proposal</label>
      <SendInvitationWrapper>
        <Input.Input
          type="email"
          name="email"
          onChange={handleInputChange}
          customStyle={urlInputStyle}
          value={email}
          height="47px"
          wrapperWidth="50%"
          error={error}
        />
        <Spacer width="10" />
        <Button
          size="small"
          width="150px"
          onClick={() => sendInvitation()}
          customStyle={buttonStyle}
          disabled={error || email === "" || sendingInvite}
        >
          {sendingInvite ? (
            <PulseLoader size="5px" margin="2px" color="#fff" />
          ) : (
            "Send invitation"
          )}
        </Button>
      </SendInvitationWrapper>
    </>
  );
};

export default InviteToProposal;

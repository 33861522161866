import React from 'react';
import styled from 'styled-components';

import getDocumentName from '../../helpers/getDocumentName';

import pdf from './assets/pdf.svg';
import doc from './assets/doc.svg';

const Wrapper = styled.div`
  height: 100%;
  background: rgba(51, 51, 51, 0.05);
  border-radius: 3px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media print {
   display: none;
  }
`;

const Image = styled.img`
  width: 150px;
  padding: 20px 10px 0 10px;
`;

const Label = styled.a`
  margin-top: 10px;
  display: block;
  font-family: var(--font-family-label);
  font-size: 14px;
  text-align: center;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
`;

const DocumentLink = ({ type, url }) => {
  const documentName = decodeURIComponent(getDocumentName(url));

  return (
    <Wrapper>
      <Image alt="file" src={type === 'pdf' ? pdf : doc} />
      <Label href={url} target="_blank">{documentName}</Label>
    </Wrapper>
  )
};

export default DocumentLink;

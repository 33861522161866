import styled from 'styled-components';

const Content = styled.div`
  padding-top: ${props => (props.top ? `${props.top}px` : '118px')};
  padding-left: ${props => (props.horizontal ? `${props.horizontal}px` : '0')};
  padding-right: ${props => (props.horizontal ? `${props.horizontal}px` : '0')};
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.align ? props.align : 'center')};
`;

export default Content;

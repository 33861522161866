import React, { useState } from 'react';

import Icon from '../../elements/Icon/Icon';
import Headline from '../../elements/Headline/Headline';
import HR from '../../elements/HR/HR';
import Input from '../../elements/Input/Input';
import Spacer from '../../elements/Spacer/Spacer';
import Button from '../../elements/Button/Button';
import Content from '../../elements/Content/Content';

const SendProposal = ({
  proposal,
  homeowner,
  sendProposal,
}) => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [clientName, setClientName] = useState('');
  const [message, setMessage] = useState('');

  if (proposal === null) {
    return null;
  }

  if (homeowner.email && email === '') {
    setEmail(homeowner.email);
  }

  if (homeowner.name && clientName === '') {
    setClientName(homeowner.name);
  }

  const {
    introductoryNote,
    name,
    companyName,
    phoneNumber,
  } = proposal;

  if (message === '') {
    setMessage(`${introductoryNote}


Best,${name ? `\n${name}` : ''}${companyName ? `\n${companyName}` : ''}${phoneNumber ? `\n${phoneNumber}` : ''}`);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);

    if (e.target.value === '') {
      setError('');
      return;
    }

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setError('Please enter a valid email address.');
    } else {
      setError('');
    }
  };

  return proposal !== null && (
    <Content>
      <Icon name="paper-stack" size="120" customStyle="transform: rotate(-4deg);" />
      <Headline align="left">Send Proposal</Headline>
      <HR />
      <Spacer height="40" />
      <Input.Input label="Client’s Email" type="email" name="email" onChange={handleEmailChange} error={error} defaultValue={email} />
      <Spacer height="40" />
      <Input.Input label="Client’s Name" onChange={e => setClientName(e.target.value)} defaultValue={clientName} />
      <Spacer height="40" />
      <Input.Textarea
        label="Edit message"
        rows="12"
        onChange={e => setMessage(e.target.value)}
        defaultValue={message}
      />
      <Spacer height="20" />
      <Button disabled={error || email === '' || clientName === ''} onClick={sendProposal(email, message, clientName)}>Send to Homeowner</Button>
    </Content>
  );
};

export default SendProposal;

import React from "react";

import Cover from "../../composables/Cover";
import Intro from "../../composables/Intro";
import Welcome from "../../composables/Welcome";
import BigPicture from "../../composables/BigPicture";
import ThePlan from "../../composables/ThePlan";
import ShoppingList from "../../composables/ShoppingList";
import Contract from "../../composables/Contract";
import Navigation from "../../composables/Navigation";

import Footer from "../../components/Footer/Footer";

import Wrapper from "./ProposalCreate.styled";

const ProposalCreate = ({
  create,
  images,
  setField,
  uploadSingleFile,
  removeSingleFile,
  uploadMultipleFiles,
  removeMultipleFiles,
  createCostGroup,
  orderCostGroup,
}) => (
  <Wrapper>
    <Navigation />
    <Intro create />
    <Cover
      setField={setField}
      create={create}
      images={images}
      uploadSingleFile={uploadSingleFile}
      removeSingleFile={removeSingleFile}
    />
    <Welcome
      setField={setField}
      create={create}
      images={images}
      uploadSingleFile={uploadSingleFile}
      removeSingleFile={removeSingleFile}
    />
    <BigPicture
      setField={setField}
      create={create}
      images={images}
      uploadMultipleFiles={uploadMultipleFiles}
      removeMultipleFiles={removeMultipleFiles}
    />
    <ThePlan
      setField={setField}
      create={create}
      createCostGroup={createCostGroup}
      orderCostGroup={orderCostGroup}
      mode="create"
    />
    <ShoppingList setField={setField} create={create} mode="create" />
    <Contract setField={setField} create={create} />
    <Footer fullwidth />
  </Wrapper>
);

export default ProposalCreate;

import React from "react";

import { allowedImageFiles } from "../../constants/mime-types";
import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import { WelcomeNext } from "../../constants/analyticEvents";

import WelcomePeview from "../../components/WelcomePreview/WelcomePreview";
import DropZone from "../../components/DropZone/DropZone";
import SectionToggle from "../../components/SectionToggle/SectionToggle";

import Spacer from "../../elements/Spacer/Spacer";
import Link from "../../elements/Link/Link";

import { formatDate } from "../../helpers/formatDate";

import PreviewWrapper from "./Welcome.styled";

const Welcome = ({
  create,
  images,
  uploadSingleFile,
  removeSingleFile,
  setField,
}) => {
  return (
    <section id="step_2_welcome" className="proposal_step">
      <div className="container">
        <div className="row">
          <div className="proposal_preview_edit">
            <div id="preview">
              <PreviewWrapper>
                {create.sectionVisibility.welcome && (
                  <WelcomePeview
                    personalPhoto={images.personalPhoto}
                    name={create.name}
                    date={formatDate(create.date)}
                    introductoryNote={create.introductoryNote}
                    companyName={create.companyName}
                    phoneNumber={create.phoneNumber}
                  />
                )}
              </PreviewWrapper>
            </div>
            <div className="phone_bg" />
          </div>
          <div className="proposal_content">
            <h1>2. Welcome</h1>
            <SectionToggle
              create={create}
              setField={setField}
              section="welcome"
            />
            <h4>Customers love it when they feel a personal connection.</h4>
            <form className="form">
              <p>
                <label>
                  Add an introductory note
                  <a className="info_tooltip">
                    i
                    <span className="tooltip_content">
                      Edit the stock message below with a personalized
                      description of your meeting with them.
                    </span>
                  </a>
                </label>
                <textarea
                  onChange={(e) => setField("introductoryNote", e.target.value)}
                  defaultValue={
                    create.introductoryNote ||
                    `Here's your remodel proposal.

Your business is important to us so we’ve made your proposal easy to read and trust.

Thank you for the opportunity to remodel your home.`
                  }
                />
              </p>
              <p>
                <label>Add your name</label>
                <input
                  type="text"
                  defaultValue={create.name || ""}
                  name="name"
                  onChange={(e) => setField("name", e.target.value)}
                />
              </p>
              <p>
                <label>Add your phone number</label>
                <input
                  type="text"
                  defaultValue={create.phoneNumber || ""}
                  name="phoneNumber"
                  onChange={(e) => setField("phoneNumber", e.target.value)}
                />
                <small>We'll never spam you or share your information.</small>
              </p>
              <div>
                <label>Add your photo</label>
                <Spacer height="10" />
                <DropZone
                  items={[images.personalPhoto]}
                  accept={allowedImageFiles}
                  maxSize={15}
                  label="personal photo"
                  uploadHandler={(image, file) => {
                    uploadSingleFile("personalPhoto", {
                      upload: image.target.result,
                      file,
                    });
                  }}
                  deleteHandler={(item) =>
                    removeSingleFile("personalPhoto", item.id)
                  }
                />
                {!create.personalPhoto && (
                  <small>
                    No photo? No problem. We'll use the placeholder you see now.
                  </small>
                )}
              </div>
              <p
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <input
                  type="checkbox"
                  name="showLogo"
                  id="showLogo"
                  defaultChecked={create.showLogo}
                  onChange={(e) => setField("showLogo", e.target.checked)}
                />
                <label
                  htmlFor="showLogo"
                  style={{
                    display: "initial",
                  }}
                >
                  &nbsp;Show Choosy Logo
                </label>
                {/* <small>We'll never spam you or share your information.</small> */}
              </p>
            </form>
            <div className="center_button">
              <Link.Scroll
                className="button-big Button-module--button--2mvQU"
                to="#step_3_big_picture"
                onClick={() => {
                  analytics.track({
                    event: generateAnalyticsPrefix(WelcomeNext),
                  });
                }}
              >
                Next
              </Link.Scroll>
              <small>2 of 5</small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, withProps } from 'recompose';

import { triggerModal, hideActiveModals } from '../../ducks/modals';

import CreateProposalModal from './CreateProposalModal';

const mapStateToProps = state => ({
  proposalId: state.create.proposalId,
  edited: state.create.edited,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  triggerModal,
  hideActiveModals,
}, dispatch);

const props = ({
  edited,
  proposalId,
}) => ({
  proposalId,
  hideActiveModals,
  edited,
  closeModal: () => {
    triggerModal({
      name: 'insufficientCredits',
      state: false,
    });
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(props),
)(CreateProposalModal);

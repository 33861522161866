import React, { Component } from "react";

import Spacer from "../../elements/Spacer/Spacer";
import Button from "../../elements/Button/Button";

import {
  Container,
  CTAContainer,
  OptionsContainer,
  OptionsReel,
  OptionImage,
  Description,
  CostItemCTALink,
  OptionImageWrapper,
} from "./CostItemCTA.styled";

const NUM_PRODUCTS_TO_DISPLAY = 4;

class CostItemCTA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    const { item } = this.props;
    this.getOtherOptions(item);
  }

  getOtherOptions({ name }) {
    const { selections } = this.props;

    fetch(`/api/products/byName/${encodeURIComponent(name)}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ selections }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json();
        }

        throw new Error(res.status);
      })
      .then((products) => {
        this.setState({ products: products.slice(0, NUM_PRODUCTS_TO_DISPLAY) });
      });
  }

  render() {
    const { item, addProductAsOption, updateProduct, addPadding } = this.props;

    const { products } = this.state;

    return (
      <Container noOptions={products.length === 0}>
        <CTAContainer addPadding={addPadding} fullWidth={products.length === 0}>
          <div>
            <Button
              href={`https://google.com/search?q=${item.name}`}
              size="small"
              color="#fff"
              background="#486AFF"
            >
              Search for "{item.name}"
            </Button>
            <Spacer height="10" />
            <Description>
              Once you find an option, paste its URL below
            </Description>
          </div>
        </CTAContainer>
        {products.length ? (
          <OptionsContainer>
            <div style={{ width: "100%" }}>
              <Description>Or choose one of these options:</Description>
              <Spacer height="24" />
              <OptionsReel>
                {products.map((product) => (
                  <OptionImageWrapper
                    style={{
                      width: `${products.length > 2 ? "22%" : "30%"}`,
                      height: "100%",
                    }}
                    key={product._id}
                  >
                    <OptionImage
                      src={product.image}
                      onClick={addProductAsOption(product.url, item.id)}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <CostItemCTALink onClick={updateProduct(product)}>
                      Edit
                    </CostItemCTALink>
                  </OptionImageWrapper>
                ))}
              </OptionsReel>
            </div>
          </OptionsContainer>
        ) : null}
      </Container>
    );
  }
}

export default CostItemCTA;

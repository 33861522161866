
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import pathToRegexp from 'path-to-regexp';

import Navigation from './Navigation';

const mapStateToProps = state => ({
  authorization: state.authorization,
  proposal: state.proposalView.proposal,
});

const props = ({
  location,
}) => ({
  isViewer: [
    pathToRegexp('/proposal/:id'),
    pathToRegexp('/proposal/received/:id'),
    pathToRegexp('/proposal/sign/:id'),
    pathToRegexp('/print/:id'),
  ].some(path => path.test(location.pathname)),
  isDashboard: location.pathname.indexOf('dashboard') !== -1,
});

export default compose(
  connect(
    mapStateToProps,
  ),
  withRouter,
  withProps(props),
)(Navigation);

import React from "react";

import { allowedImageFiles } from "../../constants/mime-types";
import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import { CoverNext } from "../../constants/analyticEvents";

import CoverPreview from "../../components/CoverPreview/CoverPreview";
import DropZone from "../../components/DropZone/DropZone";
import SectionToggle from "../../components/SectionToggle/SectionToggle";

import Spacer from "../../elements/Spacer/Spacer";
import Link from "../../elements/Link/Link";

import PreviewWrapper from "./Cover.styled";

const Intro = ({
  create,
  images,
  setField,
  uploadSingleFile,
  removeSingleFile,
  mode,
}) => {
  return (
    <section id="step_1_cover" className="proposal_step">
      <div className="container">
        <div className="row">
          <div className="proposal_preview_edit">
            <div id="preview">
              <PreviewWrapper>
                {create.sectionVisibility.cover && (
                  <CoverPreview
                    mode={mode}
                    companyLogo={images.companyLogo}
                    companyName={create.companyName}
                    projectName={create.projectName}
                    projectAddress={create.projectAddress}
                  />
                )}
              </PreviewWrapper>
            </div>
            <div className="phone_bg" />
          </div>
          <div className="proposal_content">
            <h1>1. Cover</h1>
            <SectionToggle
              create={create}
              setField={setField}
              section="cover"
            />
            <h4>
              A branded and professional proposal starts your project out right.{" "}
              <a className="info_tooltip">
                i{" "}
                <span className="tooltip_content">
                  The cover is what your client will see when they open your
                  proposal&mdash;all you have to do is fill in your info, we'll
                  format it and make it look great.
                </span>
              </a>
            </h4>
            <form className="form">
              <p>
                <label htmlFor="companyName">Add your company's name</label>
                <input
                  type="text"
                  defaultValue={create.companyName || ""}
                  name="companyName"
                  onChange={(e) => setField("companyName", e.target.value)}
                  autoComplete="organization"
                />
              </p>
              <p>
                <label htmlFor="projectName">Name the project</label>
                <input
                  type="text"
                  defaultValue={create.projectName || ""}
                  name="projectName"
                  onChange={(e) => setField("projectName", e.target.value)}
                  autoComplete="project"
                />
              </p>
              <p>
                <label htmlFor="projectAddress">Add the project address</label>
                <input
                  type="text"
                  defaultValue={create.projectAddress || ""}
                  name="projectAddress"
                  onChange={(e) => setField("projectAddress", e.target.value)}
                  autoComplete="project-location"
                />
              </p>
              <div>
                <label htmlFor="companyLogo">Upload your logo</label>
                <Spacer height="10" />
                <DropZone
                  items={[images.companyLogo]}
                  accept={allowedImageFiles}
                  maxSize={15}
                  label="company logo"
                  uploadHandler={(image, file) => {
                    uploadSingleFile("companyLogo", {
                      upload: image.target.result,
                      file,
                    });
                  }}
                  deleteHandler={(item) =>
                    removeSingleFile("companyLogo", item.id)
                  }
                />
                {!images.companyLogo && (
                  <small>
                    No logo? No problem. Your proposal will still look great.
                  </small>
                )}
              </div>
            </form>
            <div className="center_button">
              <Link.Scroll
                className="button-big Button-module--button--2mvQU"
                to="#step_2_welcome"
                onClick={() => {
                  analytics.track({
                    event: generateAnalyticsPrefix(CoverNext),
                  });
                }}
              >
                Next
              </Link.Scroll>
              <small>1 of 5</small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, withProps, lifecycle } from 'recompose';

import { getProposal as getProposalAction, getHomeownerData as getHomeownerDataAction } from '../../ducks/proposalView';
import { reset as resetAction } from '../../ducks/create';
import { uploadSignature as uploadSignatureAction } from '../../ducks/images';
import { setUi as setUiAction } from '../../ducks/ui';

import SignProposal from './SignProposal';

const mapStateToProps = state => ({
  proposal: state.proposalView.proposal,
  homeowner: state.proposalView.homeowner,
  signed: state.create.signed,
  signOverlay: state.ui.signOverlay,
  user: state.authorization.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getProposal: getProposalAction,
  getHomeownerData: getHomeownerDataAction,
  uploadSignature: uploadSignatureAction,
  setUi: setUiAction,
  reset: resetAction,
}, dispatch);

const props = ({
  proposal,
  homeowner,
  history,
  setUi,
  user,
  uploadSignature,
}) => ({
  proposal,
  homeowner,
  role: user.role,
  uploadSignature: (file) => {
    uploadSignature(file, user.role);
  },
  close: (e) => {
    if (!e.target.closest('#sign_holder')) {
      setUi({ signOverlay: false });

      if (user.role === 'contractor') {
        history.replace(`/proposal/${proposal._id}`);
      }

      if (user.role === 'homeowner') {
        history.replace(`/proposal/received/${proposal._id}`);
      }
    }
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(props),
  lifecycle({
    componentWillMount() {
      const {
        reset, signed
      } = this.props;

      if (signed) {
        reset();
      }
    },
    componentWillReceiveProps(nextProps) {
      const {
        history, match, setUi, location, role, proposal,
      } = this.props;

      if (nextProps.proposal) {
        if (role === 'contractor') {
          if (!nextProps.proposal.events.sent) {
            if (location.pathname.indexOf('sign') !== -1) {
              setUi({ signOverlay: false });
              history.replace(`/proposal/${proposal._id}/`);
              alert('Before signing proposal - share it with the homeowner.');
              return;
            }
          }
        }

        if (role === 'homeowner') {
          if (nextProps.proposal.events.signedByHomeowner) {
            if (location.pathname.indexOf('sign') !== -1) {
              history.replace(`/proposal/received/${proposal._id}/`);
              return;
            }
          }
        }

        if (!nextProps.signOverlay && location.pathname.indexOf('sign') !== -1) {
          setUi({ signOverlay: true });
        }

        if (nextProps.signOverlay && location.pathname.indexOf('sign') === -1) {
          setUi({ signOverlay: false });
        }
      }

      if (nextProps.signed && match.url.indexOf('signed') === -1) {
        setUi({ signOverlay: false });
        history.replace(`/proposal/signed/${proposal._id}/`);
      }
    },
  }),
)(SignProposal);

import React from 'react';

import FormattedNumber from '../FormattedNumber/FormattedNumber';

import {
  List,
  ListItem,
  Label,
  Cost,
  Note,
} from './ShoppingListPreview.styled';

const ShoppingListPreview = ({
  list,
}) => (
  <List>
    {list.map((value, index) => {
      return (
        <ListItem key={index}>
          <div style={{flex: 1, alignSelf: 'center', display: 'flex', flexDirection: 'column'}}>
            <Label>{value.name}</Label>
            {value.note && <Note>{value.note}</Note>}
          </div>
          <Cost style={{alignSelf: 'center'}}><FormattedNumber number={value.cost} /></Cost>
        </ListItem>
      )
    })}
  </List>
);

export default ShoppingListPreview;

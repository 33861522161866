import styled from 'styled-components';

const getRotation = (props) => {
  switch (props.orientation) {
    case 1:
      return 'rotate(0deg)';
    case 3:
      return 'rotate(180deg)';
    case 6:
      return 'rotate(90deg)';
    case 8:
      return 'rotate(270deg)';
    default:
      return 'rotate(0deg)';
  }
};

export default styled.img`
  transform: ${props => getRotation(props)}
`;

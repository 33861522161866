
import { compose, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setUi as setUiAction} from '../../ducks/ui';
import { duplicateIntoCreateStore } from '../../ducks/create';

import ProposalViewFooter from './ProposalViewFooter';

const mapStateToProps = state => ({
  proposal: state.proposalView.proposal,
  contractor: state.proposalView.contractor,
  homeowner: state.proposalView.homeowner,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setUi: setUiAction,
  duplicateIntoCreateStore,
}, dispatch);

const props = ({
  proposal,
  history,
  setUi,
}) => ({
  goToSignProposal: () => {
    setUi({ signOverlay: true });
    history.replace(`/proposal/sign/${proposal._id}`);
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withProps(props),
)(ProposalViewFooter);

import styled from 'styled-components';

export default styled.h1`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  color: #333;
  width: 100%;
  text-align: ${props => props.align};
`;

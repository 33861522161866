import styled from 'styled-components';

export default styled.span`
  font-family: ${props => (props.fontFamily ? props.fontFamily : 'inherit')};
  font-size: ${props => (props.fontSize ? props.fontSize : 'inherit')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'inherit')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'inherit')};
  color: ${props => (props.color ? props.color : 'inherit')};
  text-align: ${props => (props.align ? props.align : 'inherit')};
  text-decoration: ${props => (props.decoration ? props.decoration : 'inherit')};
  width: ${props => (props.fullsize ? '100%' : 'inherit')};
  cursor: ${props => (props.cursor ? props.cursor : 'inherit')};
  display: ${props => (props.display ? props.display : 'initial')};

  font-style: normal;
`;

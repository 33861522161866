import React from "react";

import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import {
  ShoppingListEditPlan,
  ShoppingListNext,
} from "../../constants/analyticEvents";

import ShoppingListPreview from "../../components/ShoppingListPreview/ShoppingListPreview";
import SectionToggle from "../../components/SectionToggle/SectionToggle";

import Link from "../../elements/Link/Link";

const ShoppingList = ({ list, setField, create }) => (
  <section id="step_5_shopping-list" className="proposal_step">
    <div className="container">
      <div className="row">
        <div className="four columns">
          <div className="proposal_preview_edit">
            <div id="preview">
              {create.sectionVisibility.shoppinglist && (
                <ShoppingListPreview list={list} />
              )}
            </div>
            <div className="phone_bg" />
          </div>
        </div>
        <div className="eight columns">
          <h1>5. Shopping List</h1>
          <SectionToggle
            create={create}
            setField={setField}
            section="shoppinglist"
          />
          <h4>
            A shopping list makes it easy for your customers to track their
            purchases.{" "}
            <a className="info_tooltip">
              i{" "}
              <span className="tooltip_content">
                Anything marked “allowance” in your plan will automatically show
                up here for your client, including costs and notes.
              </span>
            </a>
          </h4>
          <div className="center_button">
            <a
              href="#step_4_plan"
              className="button-small Button-module--button--2mvQU Button-module--outline--2mCFu"
              onClick={() => {
                analytics.track({
                  event: generateAnalyticsPrefix(ShoppingListEditPlan),
                });
              }}
            >
              Edit plan
            </a>
            <Link.Scroll
              className="button-big Button-module--button--2mvQU"
              to="#step_6_contract"
              onClick={() => {
                analytics.track({
                  event: generateAnalyticsPrefix(ShoppingListNext),
                });
              }}
            >
              Next
            </Link.Scroll>
            <small>5 of 5</small>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ShoppingList;

import React, { Component } from 'react';

import {
  Wrapper,
  NoteHeading,
  ShowMore,
  Other,
} from './PlanNote.styled';

class PlanNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentHidden: true,
    };
  }

  setHidden(value) {
    this.setState({
      contentHidden: value,
    });
  }

  render() {
    const {
      children,
    } = this.props;

    const { contentHidden } = this.state;

    let first; let rest;

    if(Array.isArray(children)) {
      [first, ...rest] = children;

      return (
        <Wrapper>
          <NoteHeading>Note</NoteHeading>
          {first}
          {contentHidden && <ShowMore onClick={() => this.setHidden(false)}>Read more...</ShowMore>}
          <Other show={!contentHidden}>
            {rest}
          </Other>
        </Wrapper>
      );
    }
      const content = children.split('\n\n');

      [first, ...rest] = content;

      return (
        <Wrapper>
          <NoteHeading>Note</NoteHeading>
          <p>{first}</p>
          {contentHidden && <ShowMore onClick={() => this.setHidden(false)}>Read more...</ShowMore>}
          <Other show={!contentHidden}>
            {rest.map(row => (
              <p key={row}>{row}</p>
            ))}
          </Other>
        </Wrapper>
      );

  }
}

export default PlanNote;

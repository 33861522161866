import React from 'react';

import PlanNote from '../PlanNote/PlanNote';

import ProposalViewWrapper from '../../elements/ProposalViewWrapper/ProposalViewWrapper';
import ProposalViewHeader from '../../elements/ProposalViewHeader/ProposalViewHeader';
import Icon from '../../elements/Icon/Icon';

import {
  IconHolder,
} from '../BigPictureView/BigPictureView.styled';

const ContractView = ({ proposal }) => (
  <ProposalViewWrapper verticalAlignTop horizontalAlignLeft id="contract">
    <ProposalViewHeader>Contract</ProposalViewHeader>

    <IconHolder>
      <Icon
        name="handshake"
        customStyle={`    font-size: var(--icon-size);
        color: var(--line-color-softer);
        position: absolute;
        right: 0;
        top: -130px;`}
      />
    </IconHolder>

    {/*
    <h4>{proposal.projectAddress}</h4>

    <h4>
      Agreement between <br />
      ??? &<br />
      {proposal.companyName}
    </h4> */}


    <PlanNote hideContent>
      {`The agreement is a legal contract between homeowner and contractor that's signed before work commences.

By signing, each party agrees to the details in the proposal. To protect yourself, always sign a contract before any work begins on your home.`}
    </PlanNote>

    <h2>Agreement Details</h2>

    {/* eslint-disable-next-line react/no-array-index-key */}
    {proposal.contract.split('\n').filter(Boolean).map((sentance, index) => (<p key={index}>{sentance}</p>))}
  </ProposalViewWrapper>
);

export default ContractView;

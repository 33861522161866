import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  authorization,
  allow,
  preventRedirect = false,
  ...rest
}) => (
  <Route
    {...rest}
    component={() => {
      if (!authorization.authorized && !preventRedirect) {
        return <Redirect to="/signin" />;
      }

      if (allow && !preventRedirect) {
        if (authorization.user.role !== allow) {
          return <Redirect to="/signin" />;
        }
      }

      return <Route {...rest} component={Component} />;
    }}
  />
);

export default PrivateRoute;

import { compose, withProps } from "recompose";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  createProposal as createProposalAction,
  reset as createResetAction,
} from "../../ducks/create";
import { editProposal as editProposalAction } from "../../ducks/edit";

import ProposalCreateEditFooter from "./ProposalCreateEditFooter";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createProposal: createProposalAction,
      editProposal: editProposalAction,
      createReset: createResetAction,
    },
    dispatch
  );

const props = ({
  createProposal,
  editProposal,
  createReset,
  location,
  history,
}) => ({
  createOrUpdate: () => {
    if (location.pathname.indexOf("/create") !== -1) {
      createProposal();
    } else {
      editProposal();
    }
  },
  cancel: () => {
    if (window.confirm("Are you sure you want to discard your updates?")) {
      if (location.pathname.indexOf("/create") !== -1) {
        createReset();
      }
      history.goBack();
    }
  },
  isEdit: location.pathname.indexOf("/edit") !== -1,
});

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withProps(props)
)(ProposalCreateEditFooter);

import React from 'react';
import { Checkbox } from 'antd';

import Spacer from '../../elements/Spacer/Spacer';

const SectionToggle = ({
  create,
  setField,
  section,
}) => {
  const onChange = (e) => {
    setField('sectionVisibility', {
      ...create.sectionVisibility,
      [section]: e.target.checked
    })
  }

  return (
    <>
      <Checkbox
        onChange={onChange}
        defaultChecked={create.sectionVisibility[section]}
      >
        Show Section
      </Checkbox>
      <Spacer height="10"/>
    </>
  );
}

export default SectionToggle
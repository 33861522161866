import styled from 'styled-components';

import checkboxImage from './checkbox.svg';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    display: block;
  }
`;

export const Holder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  position: relative;
  min-height: 100vh;
  padding: 118px 20px 0 20px;

  @media (min-width: 800px) {
    width: 800px;
  }
`;

export const Icon = styled.div`
  width: 100px;
  height: 100px;
  background-image: url(${checkboxImage});
  background-size: contain;
`;

export const HelpText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  width: 100%;

  color: #333333;
`;

/* eslint-disable no-unused-vars */
import styled from "styled-components";

import { CostHeading, Cost as PlanCost } from "../PlanView/PlanView.styled";

export const BudgetTable = styled.div`
  /* display: flex; */
`;

export const BudgetLabel = styled.div`
  color: var(--text-color-soft);
`;

export const BudgetItem = styled.div`
  padding-bottom: 1rem;

  &:last-child {
    border-top: 1px solid var(--text-color-softer);
    padding-top: 1rem;
    font-size: 1rem;
    font-weight: 700;

    ${BudgetLabel} {
      font-weight: 500;
    }
  }
`;

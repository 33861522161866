import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, withProps, lifecycle } from "recompose";

import {
  reset as resetAction,
  signIn as signInAction,
  signUp as signUpAction,
} from "../../ducks/authorization";
import { uploadSingleFile, removeSingleFile } from "../../ducks/images";

import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import { Login, Signup, CreatorLogin } from "../../constants/analyticEvents";
import getImages from "../../selectors/getImages";

import Authorization from "./Authorization";

const mapStateToProps = (state) => ({
  authorization: state.authorization,
  images: getImages(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadSingleFile,
      removeSingleFile,
      reset: resetAction,
      signIn: signInAction,
      signUp: signUpAction,
    },
    dispatch
  );

const props = ({ authorization, match, signIn, signUp }) => ({
  authorization,
  isLogin:
    match.path.indexOf("signin") !== -1 || match.path.indexOf("login") !== -1,
  authorizationMethod: (user) => () => {
    if (
      match.path.indexOf("signin") !== -1 ||
      match.path.indexOf("login") !== -1
    ) {
      if (match.path.indexOf("login") !== -1) {
        analytics.track({
          event: generateAnalyticsPrefix(Login),
        });
      } else {
        analytics.track({
          event: generateAnalyticsPrefix(CreatorLogin),
        });
      }
      return signIn(user);
    }

    analytics.track({
      event: generateAnalyticsPrefix(Signup),
    });

    return signUp(user);
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props),
  lifecycle({
    componentWillMount() {
      const { reset, authorization, history } = this.props;

      if (authorization.authorized) {
        history.replace("/dashboard");
      }

      reset();
    },
    componentWillReceiveProps(nextProps) {
      const { history, match, reset } = this.props;

      if (
        !nextProps.authorization.fetching &&
        nextProps.authorization.authorizationComplete &&
        match.url.indexOf("success") === -1
      ) {
        history.push("/success/");
      }

      if (match.path !== nextProps.match.path) {
        reset();
      }
    },
  })
)(Authorization);

import React from "react";
import moment from "moment";

import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import {
  FooterSendToHomeowner,
  FooterHomeownerSign,
  PDFButton,
} from "../../constants/analyticEvents";
import Button from "../../elements/Button/Button";

import Role from "../Role";

import {
  Wrapper,
  Holder,
  Trimmer,
  Left,
  Center,
  Right,
} from "./ProposalViewFooter.styled";

const ProposalViewFooter = ({
  proposal,
  contractor,
  homeowner,
  goToSignProposal,
  duplicateIntoCreateStore,
}) => {
  if (!proposal) {
    return null;
  }
  const { events } = proposal;

  const showSend = !events.sent && !events.signedByHomeowner;
  const sendDisabled = !!events.sent;

  const signedByContractor = !!events.signedByContractor;
  const signedByHomeowner = !!events.signedByHomeowner;

  const allowResend = events.edited > events.sent;

  const signStatusContractor = !signedByHomeowner
    ? `Awaiting ${homeowner.name || "homeowner"} signature`
    : "Signed";
  const signStatusHomeowner = !signedByContractor
    ? `Awaiting ${contractor.name || "contractor"} signature`
    : "Signed";

  const showPrintOptionsWindow = () => {
    window.print();
    analytics.track({
      event: PDFButton,
    });
  };
  return (
    <Wrapper>
      <Holder>
        <Trimmer>
          <Role accept="contractor">
            <Left>
              {showSend && (
                <Button
                  size="small"
                  to={`/proposal/send/${proposal._id}`}
                  onClick={() =>
                    analytics.track({
                      event: generateAnalyticsPrefix(FooterSendToHomeowner),
                    })
                  }
                >
                  Send
                </Button>
              )}
              {sendDisabled && !signedByHomeowner && !allowResend && (
                <Button size="small" disabled to="#">
                  Sent on {moment(events.sent).format("MMM D, YYYY")}
                </Button>
              )}
              {sendDisabled && !signedByHomeowner && allowResend && (
                <Button
                  size="small"
                  to={`/proposal/send/${proposal._id}`}
                  onClick={() =>
                    analytics.track({
                      event: generateAnalyticsPrefix(FooterSendToHomeowner),
                    })
                  }
                >
                  Resend
                </Button>
              )}
            </Left>
            <Center>
              {!signedByContractor && (
                <Button
                  size="small"
                  to="#"
                  disabled={!sendDisabled}
                  onClick={() => {
                    if (!sendDisabled) {
                      alert(
                        "Before signing proposal - share it with the homeowner."
                      );
                    } else {
                      goToSignProposal();
                    }
                  }}
                >
                  Sign
                </Button>
              )}
              {signedByContractor && (
                <Button size="small" disabled to="#">
                  {signStatusContractor}
                </Button>
              )}
            </Center>
            <Right>
              <Button
                size="small"
                outline
                onClick={() => {
                  showPrintOptionsWindow();
                }}
              >
                PDF
              </Button>
            </Right>
          </Role>
          <Role accept="homeowner">
            <Left>
              {!signedByHomeowner && (
                <Button
                  size="small"
                  to="#"
                  onClick={() => {
                    goToSignProposal();
                    analytics.track({
                      event: generateAnalyticsPrefix(FooterHomeownerSign),
                    });
                  }}
                >
                  Sign
                </Button>
              )}
              {signedByHomeowner && (
                <Button size="small" disabled to="#">
                  {signStatusHomeowner}
                </Button>
              )}
            </Left>
            <Right>
              <Button
                size="small"
                outline
                onClick={() => {
                  showPrintOptionsWindow();
                }}
              >
                PDF
              </Button>
            </Right>
          </Role>
          <Role>
            <Center>
              <Button
                size="small"
                to="/create"
                onClick={duplicateIntoCreateStore}
              >
                Send Proposal
              </Button>
            </Center>
          </Role>
        </Trimmer>
      </Holder>
    </Wrapper>
  );
};

export default ProposalViewFooter;

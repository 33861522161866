import React, { useState } from "react";

import {
  CommentsWrapper,
  Comment,
  PostCommentWrapper,
} from "./CostItemComments.styled";
import { formatDateWithTime } from "../../helpers/formatDate";
import Input from "../../elements/Input/Input";
import Button from "../../elements/Button/Button";
import Spacer from "../../elements/Spacer/Spacer";

const CostItemComments = ({
  commentList,
  addComment,
  costGroupId,
  productId,
}) => {
  const urlInputStyle = `
  border: 1px solid var(--line-color);
  border-radius: 0.4em;
  box-shadow: none;
`;
  const buttonStyle = `
  height: 47px
`;

  const [commentContent, setCommentContent] = useState("");

  const handleInputChange = (e) => {
    const commentText = e.target.value;
    setCommentContent(commentText);
  };

  const handleAddComment = () => {
    addComment(costGroupId, productId, commentContent);
    setCommentContent("");
  };

  return (
    <CommentsWrapper>
      <h4>Comments</h4>

      {commentList.length < 1 ? (
        <div className="no-comments">No comments yet</div>
      ) : (
        commentList.map((commentItem, index) => (
          <Comment key={index}>
            <div className="comment-content">
              <div className="comment-email">{commentItem?.email}</div>
              <div className="comment-text">{commentItem?.content}</div>
            </div>
            <div className="comment-date">
              {formatDateWithTime(commentItem?.createdAt)}
            </div>
          </Comment>
        ))
      )}

      <PostCommentWrapper>
        <Input.Input
          type="text"
          name="text"
          onChange={handleInputChange}
          customStyle={urlInputStyle}
          placeholder="What's on your mind?"
          value={commentContent}
          height="47px"
          wrapperWidth="50%"
        />
        <Spacer width="10" />
        <Button
          size="small"
          width="100px"
          onClick={handleAddComment}
          customStyle={buttonStyle}
          disabled={commentContent === ""}
        >
          Post
        </Button>
      </PostCommentWrapper>
    </CommentsWrapper>
  );
};

export default CostItemComments;

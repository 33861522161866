import React from 'react';
import * as Sentry from '@sentry/browser';

import Can from '../../composables/Can/index';

import Headline from '../../elements/Headline/Headline';
import HR from '../../elements/HR/HR';
import Spacer from '../../elements/Spacer/Spacer';
import Button from '../../elements/Button/Button';
import Content from '../../elements/Content/Content';
import HelpText from '../../elements/HelpText/HelpText';

import Icon from './NotFound.styled';

const NotFound = ({
  eventId = Sentry.captureException('not found'),
}) => {
  window.Sentry = Sentry;

  return ((
    <Content horizontal="20">
      <Icon />
      <Headline align="center">Well that's awkward.</Headline>
      <HR />
      <Spacer height="50" />
      <HelpText fontSize="20px" fontWeight="500">Sorry, there's been an issue and we couldn't load this page.</HelpText>
      <Spacer height="20" />
      <HelpText fontSize="16px" align="left" fullsize cursor="pointer" decoration="underline" onClick={() => Sentry.showReportDialog({ eventId })}>Get in touch so we can help fix the issue.</HelpText>
      <Spacer height="50" />
      <Can forAuthorized>
        <Button href="/dashboard" target="">Go Home</Button>
      </Can>
      <Can forUnathorized>
        <Button href="/">Go Home</Button>
      </Can>
    </Content>
  ));
};

export default NotFound;


import { connect } from 'react-redux';

import Role from './Role';

const mapStateToProps = state => ({
  authorization: state.authorization,
});

export default connect(
  mapStateToProps,
)(Role);

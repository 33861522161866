import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, withProps, lifecycle } from "recompose";

import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import { SendToHomeowner } from "../../constants/analyticEvents";

import {
  getProposal as getProposalAction,
  getHomeownerData as getHomeownerDataAction,
} from "../../ducks/proposalView";
import {
  sendProposal as sendProposalAction,
  reset as resetAction,
} from "../../ducks/emails";

import SendProposal from "./SendProposal";

const mapStateToProps = (state) => ({
  emails: state.emails,
  proposal: state.proposalView.proposal,
  homeowner: state.proposalView.homeowner,
  homeownerFetched: state.proposalView.homeowner.homeownerFetched,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProposal: getProposalAction,
      sendProposal: sendProposalAction,
      reset: resetAction,
      getHomeownerData: getHomeownerDataAction,
    },
    dispatch
  );

const props = ({ proposal, sendProposal, homeowner, history }) => ({
  proposal,
  homeowner,
  history,
  sendProposal: (email, message, clientName) => () => {
    analytics.track({
      event: generateAnalyticsPrefix(SendToHomeowner),
    });

    sendProposal(email, message, clientName);
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props),
  lifecycle({
    componentDidMount() {
      const { getProposal, getHomeownerData, match, reset } = this.props;

      reset(match.params.id);
      getProposal(match.params.id);
      getHomeownerData(match.params.id);
    },
    componentWillReceiveProps(nextProps) {
      const { history, match, reset, getProposal, getHomeownerData } =
        this.props;

      if (
        !nextProps.emails.fetching &&
        nextProps.emails.sent &&
        match.url.indexOf("sent") === -1
      ) {
        reset();
        getProposal(match.params.id, {
          cleanup: true,
        });
        history.push(`/proposal/sign/${match.params.id}/`);
      }

      if (match.params.id !== nextProps.match.params.id) {
        getProposal(match.params.id);
        getHomeownerData(match.params.id);
      }
    },
  })
)(SendProposal);

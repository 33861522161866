import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/browser';

import Navigation from '../../composables/Navigation';

import Footer from '../Footer/Footer';
import NotFound from '../NotFound/NotFound';

import Wrapper from '../../elements/Wrapper/Wrapper';
import Holder from '../../elements/Holder/Holder';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorInfo: null,
      eventId: null,
    };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', () => {
      this.setState({
        errorInfo: encodeURIComponent,
      });
    });

    window.addEventListener('error', (...e) => {
      this.setState({
        errorInfo: e,
      });
    });
  }


  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({
        errorInfo,
        eventId,
      });
    });
  }

  render() {
    const { errorInfo, eventId } = this.state;
    const { children } = this.props;


    if (errorInfo) {
      return (
        <Fragment>
          <Wrapper>
            <Holder>
              <Navigation />
              <NotFound eventId={eventId} />
            </Holder>
          </Wrapper>
          <Footer />
        </Fragment>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

import React from "react";
import LandingNavigation from "../../components/LandingNavigation/LandingNavigation";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import SectionHowItWorks from "../../components/LandingPage/SectionHowItWorks/SectionHowItWorks";
import SectionWelcome from "../../components/LandingPage/SectionWelcome/SectionWelcome";
import SectionWhatIsChoosy from "../../components/LandingPage/SectionWhatIsChoosy/SectionWhatIsChoosy";
import Footer from "../../components/Footer/Footer";

import { Wrapper } from "./LandingPage.styled";

const LandingPage = ({ authorization }) => (
  <div>
    <ScrollToTop />
    <LandingNavigation inverse />
    <Wrapper>
      <SectionWelcome isAuthorized={authorization.authorized} />
      <SectionWhatIsChoosy />
      <SectionHowItWorks />
    </Wrapper>
    <Footer />
  </div>
);

export default LandingPage;

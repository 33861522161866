import React, { useState } from "react";

import Input from "../../elements/Input/Input";
import Button from "../../elements/Button/Button";
import Spacer from "../../elements/Spacer/Spacer";
import HelpText from "../../elements/HelpText/HelpText";

import { ModalWrapper, Modal } from "./AddManualProductModal.styled";
import { allowedImageFiles } from "../../constants/mime-types";
import DropZone from "../../components/DropZone/DropZone";

const AddManualProductModal = ({
  closeModal,
  itemName,
  fields,
  uploadSingleFile,
  removeSingleFile,
  images,
  addManualProduct,
  editProduct,
}) => {
  const prefiled = fields
    ? {
        id: fields.id,
        productName: fields.productName,
        photoURL: fields.photoURL,
        price: fields.price,
        currency: fields.currency,
      }
    : { id: "", productName: "", photoURL: "", price: "", currency: "" };

  const [state, setState] = useState(prefiled);

  const handleChange = (e) => {
    const { target } = e;
    const { name } = target;

    setState({
      ...state,
      [name]: target.value,
    });
  };

  const submit = () => {
    if (!fields) {
      addManualProduct(state);
      return;
    }
    editProduct(state);
  };

  return (
    <ModalWrapper>
      <Modal>
        <HelpText align="left" fullsize fontSize="24px" fontWeight="bold">
          {!fields && `Add ${itemName}`}
        </HelpText>
        <Spacer height="30" />
        <Input.Input
          name="productName"
          type="text"
          value={state.productName}
          placeholder="Product Name"
          onChange={handleChange}
        />
        <Spacer height="15" />
        <Input.Input
          name="price"
          type="text"
          value={state.price}
          placeholder="Price"
          onChange={handleChange}
        />
        <Spacer height="15" />
        <Input.Input
          name="currency"
          type="text"
          value={state.currency}
          placeholder="Currency"
          onChange={handleChange}
        />
        <Spacer height="15" />

        <label htmlFor="productImage">Upload photo</label>
        <DropZone
          items={[images.productImage]}
          accept={allowedImageFiles}
          maxSize={15}
          label="product image"
          uploadHandler={(image, file) => {
            uploadSingleFile("productImage", {
              upload: image.target.result,
              file,
            });
          }}
          deleteHandler={(item) => removeSingleFile("productImage", item.id)}
        />
        <Spacer height="15" />
        <Button onClick={submit}>{fields ? "Update" : "Add"}</Button>
        <Spacer height="15" />
        <HelpText color="#828282">
          Or,{" "}
          <HelpText
            decoration="underline"
            display="inline"
            cursor="pointer"
            onClick={closeModal}
          >
            cancel and go back
          </HelpText>
        </HelpText>
      </Modal>
    </ModalWrapper>
  );
};

export default AddManualProductModal;

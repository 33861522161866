import React, { Fragment, useCallback, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { PoseGroup } from 'react-pose';

import analytics, { generateAnalyticsPrefix } from '../../helpers/analytics';
import { DoneSigning, ClearSigning } from '../../constants/analyticEvents';

import Headline from '../../elements/Headline/Headline';
import HR from '../../elements/HR/HR';
import Spacer from '../../elements/Spacer/Spacer';
import HelpText from '../../elements/HelpText/HelpText';
import Button from '../../elements/Button/Button';
import Spinner from '../../elements/Spinner/Spinner';

import {
  Wrapper,
  Holder,
  SignatureWrapper,
  ButtonWrapper,
} from './SignProposal.styled';

const SignProposal = ({
  proposal, user, uploadSignature, close, signOverlay, homeowner, role,
}) => {
  const [signatureCanvas, setSignatureCanvas] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [signing, setSigning] = useState(false);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setSignatureCanvas(node);
    }
  }, []);

  if (proposal === null) {
    return null;
  }

  const checkIfEmpty = () => {
    if (signatureCanvas.isEmpty()) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const clear = () => {
    analytics.track({
      event: generateAnalyticsPrefix(ClearSigning),
    });

    signatureCanvas.clear();
    setDisabled(true);
  };

  const sign = async () => {
    setSigning(true);

    analytics.track({
      event: generateAnalyticsPrefix(DoneSigning),
    });

    const image = signatureCanvas.getTrimmedCanvas().toDataURL('image/png');// .split(',')[1]

    const imageBlob = await fetch(image)
      .then(res => res.blob());

    const fr = new FileReader();
    fr.readAsArrayBuffer(imageBlob);

    fr.onload = (fileReaderEvent) => {
      uploadSignature({
        upload: fileReaderEvent.target.result,
        type: 'image/png',
      });
    };
  };

  return (
    <PoseGroup>
      <Wrapper verticalAlignTop horizontalAlignLeft onClick={close} pose={signOverlay ? 'visible' : 'hidden'} key="sign">
        <Holder id="sign_holder">
          <Spacer height="20" />
          <Headline align="left">Sign Agreement</Headline>
          <HR />
          <SignatureWrapper>
                  x &nbsp;
            <SignatureCanvas
              ref={measuredRef}
              onEnd={checkIfEmpty}
              penColor="#BCBBC1"
              canvasProps={{
                width: 400,
                height: 200,
                style: {
                  borderBottom: '2px solid #BCBBC1',
                },
              }}
            />
          </SignatureWrapper>
          <Spacer height="10" />
          <HelpText fontFamily="Inter" fontSize="13px" lineHeight="16px" color="#BCBBC1" align="center">
            {role === 'homeowner' && (
              <Fragment>I{user.name && `, ${user.name},`} agree to the legal contract {proposal.companyName && `with ${proposal.companyName}`} as outlined in this agreement.</Fragment>
            )}
            {role === 'contractor' && (
              <Fragment>I{user.name && `, ${user.name},`} agree to the legal contract {homeowner.name && `with ${homeowner.name}`} as outlined in this agreement.</Fragment>
            )}
          </HelpText>
          <Spacer height="25" />
          <ButtonWrapper>
            <Button outline width="20%" onClick={clear}>Clear</Button>
            <Button width="75%" onClick={sign} disabled={disabled}>Done Signing <Spinner show={signing} /></Button>
          </ButtonWrapper>
          <Spacer height="40" />
        </Holder>
      </Wrapper>
    </PoseGroup>

  );
};

export default SignProposal;

import React, { Component } from 'react';

import {
  Wrapper,
  NoteHeading,
  ShowMore,
  Other,
} from './CostGroupNote.styled';

class CostGroupNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentHidden: true,
    };
  }

  setHidden(value) {
    this.setState({
      contentHidden: value,
    });
  }

  render() {
    const {
      children,
    } = this.props;

    const { contentHidden } = this.state;

    const content = children.split('\n\n');

    const [first, ...rest] = content;

    return (
      <Wrapper>
        <NoteHeading>FAQ</NoteHeading>
        <div>
          <p>{first} {contentHidden && <ShowMore onClick={() => this.setHidden(false)}>Read more...</ShowMore>}</p>
          <Other show={!contentHidden}>
            {rest.map(row => (
              <p key={row}>{row}</p>
            ))}
          </Other>
        </div>
      </Wrapper>
    );
  }
}

export default CostGroupNote;

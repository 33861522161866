import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 2;

  @media print {
    display: none;
  }
`;

export const Holder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  padding: 15px 20px 20px 20px;

  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

export const Trimmer = styled.div`
  width: 100%;

  @media (min-width: 800px) {
    width: 800px;
  }

  display: flex;
  justify-content: space-between;
`;

export const SignButton = styled(Link)`
  width: 100%;
  height: 35px;

  font-family: Inter, serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #f2f2f2;

  background: #486aff;
  border-radius: 7px;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  border: 0;
  text-decoration: none;

  margin-right: 5px;
`;

const buttonBaseStyles = `
  width: 20%;
  height: 35px;
  left: 20px;
  top: 898px;
  padding: 0 5px;

  @media (max-width: 800px) {
    width: 20%;
  }

  border: 4px solid #486AFF;
  border-radius: 7px;

  font-family: Inter, serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #486AFF;
  border-radius: 7px;

  text-decoration: none;

  margin-right: 5px;

  $:last-child {
    margin-right: 0;
  }

  cursor: pointer;
  outline: 0;
`;

export const EditButton = styled(Link)`
  ${buttonBaseStyles}
`;

export const Button = styled.a`
  ${buttonBaseStyles}
`;

import styled from "styled-components";
import { xlScreen, lgScreen, mdScreen } from "../../../style/media-query";
import { lgSpacing, mdSpacing, smSpacing } from "../../../style/landing-page";

export const SectionWrapper = styled.section`
  background-color: ${(props) =>
    props.sectionColor ? props.sectionColor : "#fff"};
  color: ${(props) =>
    props.sectionTextColor ? props.sectionTextColor : "#000"};
  padding: 120px ${smSpacing} 10px ${smSpacing};

  @media (min-width: ${mdScreen}) {
    padding: 120px ${mdSpacing} 10px ${mdSpacing};
  }

  @media (min-width: ${xlScreen}) {
    padding: 120px ${lgSpacing} 10px ${lgSpacing};
  }
`;

export const StepsWrapper = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 100px;

  @media (min-width: ${lgScreen}) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5%;
  }

  @media (min-width: ${xlScreen}) {
    column-gap: 10%;
  }
`;

export const StepItem = styled.div`
  margin-bottom: 50px;

  @media (max-width: ${lgScreen}) {
    max-width: 300px;
  }
`;

export const StepNuberWrapper = styled.div`
  position: relative;
  padding: 30px;
`;

export const StepNumber = styled.div`
  font-size: 150px;
  font-weight: 700;
  display: inline-block;
  opacity: 0.2;
  line-height: 0.8;
  position: absolute;
  bottom: 0;
  left: 0;
`;

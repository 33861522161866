import React from "react";
import {
  Heading,
  Paragraph,
} from "../../../pages/LandingPage/LandingPage.styled";

import {
  SectionWrapper,
  StepsWrapper,
  StepItem,
  StepNuberWrapper,
  StepNumber,
} from "./SectionHowItWorks.styled";

const howItWorksSteps = [
  {
    step: 1,
    title: "Set up your app",
    description:
      "Start your Choosy Project by entering basic project information like the project address, the project name, team members.",
  },
  {
    step: 2,
    title: "Set up your project",
    description:
      "Configure your project by selecting the Spaces you're working on and either using Choosy Item lists or customizing them with additional Items",
  },
  {
    step: 3,
    title: "Start Choosing!",
    description:
      "Add product options to your Project by ‘clipping’ them from the internet, or manually add a product option by entering text.  See how different options look together, get feedback from the Team and then Choose your Selections.",
  },
];

const SectionHowItWorks = () => {
  return (
    <SectionWrapper
      sectionColor="#486AFF"
      headingColor="#fff"
      sectionTextColor="#fff"
    >
      <Heading as="h2" headingColor="#fff" alignment="center">
        How it works
      </Heading>
      <StepsWrapper>
        {howItWorksSteps.map((item, index) => (
          <StepItem key={index}>
            <StepNuberWrapper>
              <StepNumber>{item.step}</StepNumber>
              <Heading as="h4" headingColor="#fff" alignment="center">
                {item.title}
              </Heading>
            </StepNuberWrapper>
            <Paragraph alignment="center" textColor="#fff">
              {item.description}
            </Paragraph>
          </StepItem>
        ))}
      </StepsWrapper>
    </SectionWrapper>
  );
};

export default SectionHowItWorks;

import React from 'react';

import PlanNote from '../PlanNote/PlanNote';

import ShoppingListPreview from '../ShoppingListPreview/ShoppingListPreview';

import ProposalViewWrapper from '../../elements/ProposalViewWrapper/ProposalViewWrapper';
import ProposalViewHeader from '../../elements/ProposalViewHeader/ProposalViewHeader';
import Icon from '../../elements/Icon/Icon';
import Button from '../../elements/Button/Button';
import Link from '../../elements/Link/Link';
import Spacer from '../../elements/Spacer/Spacer';

import {
  IconHolder,
} from '../BigPictureView/BigPictureView.styled';

import SelectionsLinkWrapper from './ShoppingListView.styled'

const ShoppingListView = ({ proposal }) => {
  if (proposal.costGroups.map(costGroup => (costGroup.values.filter(({ selection }) => selection))).every(costGroup => costGroup.length === 0)) {
    return null;
  }

  const totalCost = proposal.costGroups.length > 0 ? proposal.costGroups.map(costGroup => (costGroup.values.filter(({ selection }) => selection).length))
    .reduce((a, b) => a + b) : 0;

  const list = proposal.costGroups.length > 0 ? proposal.costGroups
    .map(costGroup => costGroup.values.filter(({ selection }) => selection))
    .reduce((a, b) => [...a, ...b]) : [];

  return (
    <ProposalViewWrapper verticalAlignTop horizontalAlignLeft id="shopping-list">
      <ProposalViewHeader>Shopping List</ProposalViewHeader>

      <IconHolder>
        <Icon
          name="tile"
          customStyle={`    font-size: var(--icon-size);
        color: var(--line-color-softer);
        position: absolute;
        right: 0;`}
        />
      </IconHolder>

      <h3>{totalCost} items</h3>

      <PlanNote hideContent>
        {`The shopping list includes the materials—from faucets to floors—that need to be selected and purchased for your construction project.

Materials not being ready is one of the biggest sources of delays for construction projects, so this shopping list will help make sure purchasing happens on time.

Shopping can be done by the contractor, homeowner, designer, interior architect, or some combination. Be sure you know who's buying what and when to avoid delays.`}
      </PlanNote>
      <Spacer height="10"/>
      <SelectionsLinkWrapper>
        <Link.Router to="./selections">
          <Button outline>
            Start Shopping →
          </Button>
        </Link.Router>
      </SelectionsLinkWrapper>
      <Spacer height="20"/>
      <ShoppingListPreview list={list} left="40" />
    </ProposalViewWrapper>
  );
};

export default ShoppingListView;

import React from 'react';

import HelpText from '../../elements/HelpText/HelpText';

import FAQWrapper from './FAQ.styled';

const FAQ = () => (
  <FAQWrapper>
    <h1>FAQ</h1>

    <h3>What is Choosy?</h3>
    <p>Choosy is your one-stop-shop for home construction and renovation. We're a smart marketplace for construction services—from inspiration to punchlist we make your project happen.</p>

    <h3>Who is Choosy for?</h3>
    <p>Homeowners in need of construction services. Construction pros looking for quality projects.</p>

    <h3>Is Choosy a General Contractor?</h3>
    <p>Choosy was started by a licensed contractor in Colorado and California, however, Choosy projects happen under the individual licenses of the partner contractors in the Choosy network.</p>

    <h3>Why should I work with Choosy instead of just hiring a general contractor?</h3>
    <p>Look—at the end of the day we set out to create something that should be a total no-brainer for homeowners and contractors. When I was hiring for my renovation I probably wasted 20 hours on the phone calling general contractors and another 10 hours trying to write a detailed plan without knowing anything about construction. Some of it was fun, but the rest of the time I had no idea what I was doing. That’s why we created Choosy—if you want somebody on your side, for next to nothing, to help make your project a success then we’re for you.</p>

    <h3>What’s the difference between Choosy and services like Home Advisor, Angie’s List, Buildzoom etc.?</h3>
    <p>Choosy takes the time to talk to you about your project and develop a preliminary plan.  We then hand match you to as many as three contractors in our network.  It’s a much more hands-on process than other lead generation companies, and we don’t sell your information to as many contractors as we can.  With Choosy you’ll have one point of contact, and we’ll personally introduce you to the three best contractors for your job.</p>

    <h3>Who do I hire?</h3>
    <h5>Phase 1: For a $99 deposit you hire Choosy to:</h5>
    <ol>
      <li><p>Guide planning your project</p></li>
      <li><p>Meet you in person and talk about your project in detail</p></li>
      <li><p>Provide proposals from 3 qualified General Contractors</p></li>
    </ol>
    <h5>Phase 2: Choose one of the three proposals provided by Choosy and hire the GC for your project</h5>


    <h3>What happens if I’m not happy with your service?</h3>
    <p>We have a 100% happiness guarantee. If you’re not happy, we’ll refund your deposit and buy you a snow shovel.</p>

    <h3>Are you guys for real? Do you even have a website?</h3>
    <p>Yes! First of all, you’re on our website right now. Secondly, we started Choosy to make construction work better based on years of personal experience. Carl, a General Contractor, has been in the industry for 20 years having started out working in the trades and eventually building his own construction company. He is still a licensed contractor in California. David grew up around construction, built the industry-leading online music analytics company Next Big Sound and sold it to Pandora, and survived a <a href="https://www.bluehousegoods.com/" target="_blank" rel="noreferrer">full renovation in the Catskills</a> that inspired him to fall down the renovation rabbit hole and learn how the industry works. Carl and David teamed up to start Choosy at the end of 2018.</p>

    <h3>How does it work? </h3>
    <p>For homeowners, we’ll schedule an introductory conversation to learn more about your project. During the conversation, we’ll answer all your questions, educate you on your options and smart ways to plan your project, and if you’re ready, we’ll provide an early estimate, start introducing you to contractors, and coordinate in-person visits. </p>


    <h3>Is there somewhere I can learn more about how it works?</h3>
    <p>Keep reading. :) But really, the best way to learn more is to <a href="https://app.acuityscheduling.com/schedule.php?owner=18876024&appointmentType=12666278" target="_blank" rel="noreferrer">schedule an introductory call</a>.</p>

    <h3>What’s the process? </h3>
    <ol>
      <li>
        <p>
          <HelpText fontSize="1.05em" fontWeight="500" display="block">Plan</HelpText>
          Tell us your hopes, dreams, and ideas—we’ll put together a plan to make them a reality.
        </p>
      </li>
      <li>
        <p>
          <HelpText fontSize="1.05em" fontWeight="500" display="block">Design and documents</HelpText>
          We work with you to determine the design approach (architect, design-build firm, other) and procure necessary documents (permits, HOA approvals, board approvals, etc).
        </p>
      </li>
      <li>
        <p>
          <HelpText fontSize="1.05em" fontWeight="500" display="block">Hire</HelpText>
          Based on your plans, we’ll introduce you to a shortlist of top-rated contractors vetted by our comprehensive vetting system including a background check, reference checks, and review teardown.
        </p>
      </li>
      <li>
        <p>
          <HelpText fontSize="1.05em" fontWeight="500" display="block">Kick-off</HelpText>
          After your contract is signed and the initial deposit made we’ll facilitate your project kick-off.
        </p>
      </li>
      <li>
        <p>
          <HelpText fontSize="1.05em" fontWeight="500" display="block">Manage Project</HelpText>
          During your project, we’ll check-in with you and your contractor. Construction projects have a lot of moving pieces, so we help keep everything pointed in the right direction and the team on the same page.
        </p>
      </li>
      <li>
        <p>
          <HelpText fontSize="1.05em" fontWeight="500" display="block">Punchlist</HelpText>
          At the end of your project, we’ll review the final list of work to complete the project and ensure you’re happy with the results.
        </p>
      </li>
      <li>
        <p>
          <HelpText fontSize="1.05em" fontWeight="500" display="block">Wrap-up</HelpText>
          Hooray! You made it. Let’s celebrate.
        </p>
      </li>
    </ol>

    <h3>Does Choosy help with planning and design?</h3>
    <p>We sure do. We’ll organize a preliminary plan detailed down to the line item and based on the project we will facilitate floorplans, elevations, or renderings.</p>

    <h3>Do I need an architect?</h3>
    <p>Sometimes you need an architect, sometimes you don't, and sometimes you may not need one, but you want one to make your project exceptional. Tell us about your project and we'll advise you on what makes sense for your situation and project goals.</p>

    <h3>Who deals with permitting? </h3>
    <p>Your general contractor or architect will handle securing all necessary permits for your project.</p>

    <h3>I’m not ready to build yet, can you still help?</h3>
    <p>Yes! At $99 Choosy is a great start for your project. Use us to get an idea of what your project will cost so you can plan, and when you're ready to build just give us a call and we'll be ready.</p>

    <h3>How do you (Choosy) make money?</h3>
    <p>Choosy charges homeowners a $99 refundable deposit (credited towards their remodel) to plan their project and get three contractor proposals. Contractors also pay Choosy a small commission when we connect them with a job.</p>

    {/* Contact Us
    <p>
Email us at&nbsp;
      <a href="mailto:help@choosy.tools">help@choosy.tools</a>
    </p> */}
  </FAQWrapper>
);

export default FAQ;

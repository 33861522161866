import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1rem var(--content-padding);
  margin: 1rem var(--full-bleed-margin);
  background-color: var(--background-color-soft);
  z-index: 1;

  p:last-child {
    margin-bottom: 0;
  }

  @media print {
    background: none;

    & p {
      font-style: italic;
    }
  }
`;

export const NoteHeading = styled.div`
  display: block;
  margin-bottom: 0.5rem;
  font-family: var(--font-family-label);
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  color: var(--text-color-softer);
`;

export const ShowMore = styled.p`
  margin: 0;
  text-decoration: underline;
  cursor: pointer;

  @media print {
    display: none;
  }
`;

export const Other = styled.div`
  height: ${props => (props.show ? 'auto' : 0)};
  overflow: hidden;

  @media print {
    height: auto;
  }
`;

import styled from "styled-components";

export const SignaturesWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  &,
  & * {
    font-family: Inter, serif;
  }
`;

export const SignatureBlock = styled.div`
  width: 48%;
`;

export const Heading = styled.div`
  font-weight: 500;
`;

export const SignComponent = styled.div`
  padding-bottom: 5px;
  border-bottom: 2px solid #333;
  cursor: ${(props) => (props.active ? "pointer" : "default")};
  height: 50px;
  display: flex;
  align-items: flex-end;
`;

export const SignAction = styled.div`
  display: inline-block;
  color: #000;
  font-weight: 500;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 50%,
    #486aff70 50%
  );
`;

export const SignatureImage = styled.img`
  height: 40px;
`;

export const Name = styled.div`
  font-weight: 500;
`;

export const Date = styled.div`
  font-weight: 500;
`;

import { v4 } from "uuid";
import mime from "mime-types";

import { CREATE_RESET, signProposal, updateCostGroupValue } from "./create";
import { updateCostGroupValue as editUpdateCostGroupValue } from "./edit";

export const defaultImagesState = {
  currentCondition: [],
  personalPhoto: null,
  companyLogo: null,
  productImage: null,
  signature: false,
};

export const SET_IMAGES = "SET_IMAGES";
export const START_UPLOAD_SINGLE_FILE = "START_UPLOAD_SINGLE_FILE";
export const START_UPLOAD_MULTIPLE_FILES = "START_UPLOAD_MULTIPLE_FILES";
export const UPLOAD_SINGLE_FILE = "UPLOAD_SINGLE_FILE";
export const REMOVE_SINGLE_FILE = "REMOVE_SINGLE_FILE";
export const UPLOAD_MULTIPLE_FILES = "UPLOAD_MULTIPLE_FILES";
export const REMOVE_MULTIPLE_FILES = "REMOVE_MULTIPLE_FILES";

export const UPLOAD_COST_GROUP_IMAGE = "UPLOAD_COST_GROUP_IMAGE";
export const REMOVE_COST_GROUP_IMAGE = "REMOVE_COST_GROUP_IMAGE";

export const RESET_IMAGES = "RESET_IMAGES";

export const setImages = (images) => ({
  type: SET_IMAGES,
  payload: {
    images,
  },
});

export const resetImages = () => ({
  type: RESET_IMAGES,
});

const getExtensioned = (fileId, extension, name) => {
  return `${fileId}-NAME-${name}-NAME-.${mime.extension(extension)}`;
};

export const uploadFile = async ({
  customName,
  binary,
  type,
  originalName,
  used = false,
}) => {
  const formData = new FormData();

  formData.append(
    customName,
    new File([binary], customName, {
      type,
    })
  );
  formData.append("used", used);
  formData.append("originalName", originalName);

  return fetch("/api/proposal/photo-upload", {
    method: "POST",
    body: formData,
  });
};

export const uploadSingleFile = (field, fileObject) => async (dispatch) => {
  const fileId = `${v4()}-${field}`;
  const extensioned = getExtensioned(
    fileId,
    fileObject.file.type,
    fileObject.file.name
  );

  dispatch({
    type: START_UPLOAD_SINGLE_FILE,
    payload: {
      field,
    },
  });

  await uploadFile({
    customName: extensioned,
    binary: fileObject.upload,
    type: fileObject.file.type,
    originalName: fileObject.file.name,
  });

  return dispatch({
    type: UPLOAD_SINGLE_FILE,
    payload: {
      field,
      file: extensioned,
    },
  });
};

export const removeSingleFile = (field) => async (dispatch) => {
  return dispatch({
    type: REMOVE_SINGLE_FILE,
    payload: {
      field,
    },
  });
};

export const uploadMultipleFiles = (field, fileObject) => async (dispatch) => {
  const fileId = `${v4()}-${field}`;
  const extensioned = getExtensioned(
    fileId,
    fileObject.file.type,
    fileObject.file.name
  );

  dispatch({
    type: START_UPLOAD_MULTIPLE_FILES,
    payload: {
      id: fileObject.id,
      field,
      fileId: extensioned,
      originalName: fileObject.file.name,
    },
  });

  await uploadFile({
    customName: extensioned,
    binary: fileObject.binary,
    type: fileObject.file.type,
    originalName: fileObject.file.name,
  });

  return dispatch({
    type: UPLOAD_MULTIPLE_FILES,
    payload: {
      id: fileObject.id,
      field,
    },
  });
};

export const uploadCostGroupImage =
  (from, costGroupId, fileObject) => async (dispatch, getState) => {
    const fileId = `${v4()}-${costGroupId}`;
    const extensioned = getExtensioned(
      fileId,
      fileObject.file.type,
      fileObject.file.name
    );

    const costGroupImages = getState()[from].costGroups.find(
      ({ id }) => id === costGroupId
    ).images;

    if (from === "create") {
      dispatch(
        updateCostGroupValue(costGroupId, "images", [
          ...costGroupImages,
          {
            id: fileObject.id,
            file: extensioned,
            uploading: true,
          },
        ])
      );
    } else {
      dispatch(
        editUpdateCostGroupValue(costGroupId, "images", [
          ...costGroupImages,
          {
            id: fileObject.id,
            file: extensioned,
            uploading: true,
          },
        ])
      );
    }

    await uploadFile({
      customName: extensioned,
      binary: fileObject.upload,
      type: fileObject.file.type,
      originalName: fileObject.file.name,
    });

    const uploadedCostGroupImages = getState()
      [from].costGroups.find(({ id }) => id === costGroupId)
      .images.map((image) => {
        if (image.id === fileObject.id) {
          return {
            ...image,
            uploading: false,
          };
        }
        return image;
      });

    if (from === "create") {
      return dispatch(
        updateCostGroupValue(costGroupId, "images", uploadedCostGroupImages)
      );
    }

    return dispatch(
      editUpdateCostGroupValue(costGroupId, "images", uploadedCostGroupImages)
    );
  };

export const removeCostGroupImage =
  (from, costGroupId, targetFile) => (dispatch, getState) => {
    const newCostGroupImages = getState()
      [from].costGroups.find(({ id }) => id === costGroupId)
      .images.filter(({ file }) => file !== targetFile);

    if (from === "create") {
      return dispatch(
        updateCostGroupValue(costGroupId, "images", newCostGroupImages)
      );
    }

    return dispatch(
      editUpdateCostGroupValue(costGroupId, "images", newCostGroupImages)
    );
  };

export const removeMultipleFiles = (field, id) => ({
  type: REMOVE_MULTIPLE_FILES,
  payload: {
    field,
    id,
  },
});

export const uploadSignature = (file, role) => async (dispatch, getState) => {
  const { proposal } = getState().proposalView;

  const fileId = `${v4()}-${proposal._id}-${
    role === "contractor" ? "CONTRACTOR_SIGNATURE" : "HOMEOWNER_SIGNATURE"
  }`;

  await uploadFile({
    customName: fileId,
    binary: file.upload,
    type: file.type,
    originalName: fileId,
  });

  dispatch(signProposal(fileId));
};

export default function ImagesReducer(state = defaultImagesState, action) {
  switch (action.type) {
    case SET_IMAGES:
      return {
        ...action.payload.images,
      };

    case START_UPLOAD_SINGLE_FILE: {
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          uploading: true,
        },
      };
    }
    case START_UPLOAD_MULTIPLE_FILES: {
      return {
        ...state,
        [action.payload.field]: [
          ...state[action.payload.field],
          {
            id: action.payload.id,
            file: action.payload.fileId,
            originalName: action.payload.originalName,
            uploading: true,
          },
        ],
      };
    }
    case UPLOAD_SINGLE_FILE:
      return {
        ...state,
        [action.payload.field]: {
          file: action.payload.file,
          uploading: false,
        },
      };
    case REMOVE_SINGLE_FILE: {
      const { [action.payload.field]: deletedKey, ...rest } = state;

      return rest;
    }
    case UPLOAD_MULTIPLE_FILES:
      return {
        ...state,
        [action.payload.field]: state[action.payload.field].map((file) => {
          if (file.id === action.payload.id) {
            return {
              ...file,
              uploading: false,
            };
          }

          return file;
        }),
      };
    case REMOVE_MULTIPLE_FILES:
      return {
        ...state,
        [action.payload.field]: state[action.payload.field].filter(
          ({ id }) => id !== action.payload.id
        ),
      };
    case CREATE_RESET:
      return {
        ...state,
        ...defaultImagesState,
      };
    case RESET_IMAGES:
      return {
        ...state,
        ...defaultImagesState,
      };
    default:
      return state;
  }
}

import React from "react";

import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import { OpenedCostItem, ClosedCostItem } from "../../constants/analyticEvents";

import NavigationIconList from "../NavigationIconList/NavigationIconList";
import FormattedNumber from "../FormattedNumber/FormattedNumber";
import PlanNote from "../PlanNote/PlanNote";
import CostGroupAccordeonView from "../CostGroupAccordeonView/CostGroupAccordeonView";

import ProposalViewWrapper from "../../elements/ProposalViewWrapper/ProposalViewWrapper";
import ProposalViewHeader from "../../elements/ProposalViewHeader/ProposalViewHeader";
import Icon from "../../elements/Icon/Icon";

import { Cost } from "../../composables/CostGroupListView/CostGroupListView.styled";

import { IconHolder } from "../BigPictureView/BigPictureView.styled";

import { DetailedViewHeader, AccordeonControl } from "./PlanView.styled";

const PlanView = ({ proposal }) => {
  if (proposal.costGroups.length === 0) {
    return null;
  }

  const expandByDefault = window.location.pathname.indexOf("print") !== -1;

  return (
    <ProposalViewWrapper verticalAlignTop horizontalAlignLeft id="plan">
      <ProposalViewHeader>Plan</ProposalViewHeader>

      <IconHolder>
        <Icon
          name="mount-climb-goal"
          customStyle={`    font-size: var(--icon-size);
        color: var(--line-color-softer);
        position: absolute;
        right: 0;`}
        />
      </IconHolder>

      <PlanNote>
        {`This is the plan for the work that will take your construction project from start to finish.

    Choose any item to see the detailed work, contingency plans, and notes.`}
      </PlanNote>

      {proposal.costGroups.length > 0 && (
        <NavigationIconList
          items={proposal.costGroups}
          accordeon
          expandByDefault={expandByDefault}
          component={(item, index) => (
            <CostGroupAccordeonView
              withNote
              costGroup={item}
              costGroupNumber={index}
            />
          )}
          padding="0"
          onExpand={() => {
            analytics.track({
              event: generateAnalyticsPrefix(OpenedCostItem),
            });
          }}
          onCollapse={() => {
            analytics.track({
              event: generateAnalyticsPrefix(ClosedCostItem),
            });
          }}
          getName={(item) => item.name}
          getHelpText={(item) => {
            const costGroupCost =
              item.values.length > 0
                ? item.values
                    .map(({ cost }) => Number(cost))
                    .reduce((a, b) => a + b)
                : 0;
            return (
              <Cost>
                <FormattedNumber number={costGroupCost} />
              </Cost>
            );
          }}
          showIcon={false}
        >
          {(allCollapsed, toggleAll) => (
            <DetailedViewHeader>
              Detailed Work{" "}
              <AccordeonControl onClick={toggleAll}>
                {allCollapsed ? "Expand All" : "Collapse All"}
              </AccordeonControl>
            </DetailedViewHeader>
          )}
        </NavigationIconList>
      )}
    </ProposalViewWrapper>
  );
};

export default PlanView;

import mime from 'mime-types';

export const allowedImageFiles = [
  mime.lookup('jpeg'),
  mime.lookup('jpg'),
  mime.lookup('gif'),
  mime.lookup('png'),
];

export const allowedDocumentFiles = [
  mime.lookup('pdf'),
  mime.lookup('doc'),
  mime.lookup('docx'),
];

export const allowedFiles = [
  ...allowedDocumentFiles,
  ...allowedImageFiles,
];

import styled from 'styled-components';

export const Wrapper = styled.div`
  font-family: var(--font-family-heading);
`;

export const Header = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  min-height: 4rem;
  background-color: var(--highlight-color);
`;

export const Title = styled.div`
  margin: 0;
  padding: 1rem var(--content-padding);
  font-size: 2.3rem;
  font-weight: bold;
  color: #fff;
  color: var(--text-color-inverted);
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  font-size: 1.5rem;
  color: var(--text-color-hard);
  cursor: pointer;
`;

export const Estimate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading = styled.h2`
  font-size: 2.7em;
`;

export const Type = styled.div`
  font-family: var(--font-family-label);
  font-size: 1.2em;
  color: var(--text-color-softer);
`;

export const Value = styled.div`
  margin: 0.2rem 0 2rem;
  font-family: var(--font-family-monospace);
  font-size: 1.2em;
`;

export const CostItemsList = styled.ol`
  padding: 0;
  font-family: var(--font-family-body);
  font-size: 1.05rem;
  line-height: 1.5;
  list-style: none;
`;

export const CostItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 15px;

  &:nth-child(odd) {
    background-color: var(--background-color-soft);
  }
`;

export const CostItemNumber = styled.span`
  flex-shrink: 0;
  font-family: var(--font-family-label);
  white-space: pre;
  color: var(--text-color-softer);
`;

export const CostItemContent = styled.div`
  width: 100%;
  margin-left: 10px;
`;

export const CostItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CostItemDetail = styled.span`
`;

export const CostItemCost = styled.span`
  font-size: 0.9em;
  font-family: var(--font-family-monospace);
`;

export const CostItemNote = styled.div`
  color: var(--text-color-soft);
  font-family: var(--font-family-label);
  font-size: .8rem;
`;

export const Notes = styled.div`
  margin-top: 10px;
  padding: 0 10px;
  font-size: 1.05rem;
`;

export const Footer = styled.div`
  padding: 0.5rem 0.5rem;
  border-top: 1px solid var(--line-color-soft);

  background: #fff;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  bottom: 0;
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 0.9rem;
  font: 700 1.7rem/1 Inter, sans-serif;
  text-decoration: none;
  text-align: center;
  font-size: 1.2rem;
  color: #333;
  color: var(--text-color);
  border: 0.25rem solid #2dc16b;
  border: 0.25rem solid var(--highlight-color);
  border-radius: 100%;
  opacity: ${props => (props.show ? 1 : 0)};
`;

export const AllowanceLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid #aaa;
  background: #fff;
  padding: 1px 5px;
  margin-right: 10px
`;
import styled from 'styled-components';

export default styled.div`
  padding: 0 20px 90px 20px;

  & * {
    font-family: Inter, serif;
  }

  h3 {
    margin-top: 35px;
    margin-bottom: 10px;
  }

  h5 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 15px;
  }

  ol li {
    margin-bottom: 15px;
  }

  ol li, h5 {
    font-size: 1.05em;
  }
`;
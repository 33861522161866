import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`

export const Label = styled.small`
  font-family: var(--font-family-label);
  color: var(--text-color-softer);
`;

export const ImageContainer = styled.div`
  padding-top: 25px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 30px;
  column-gap: 30px;
`

export const Product = styled.a`
  display: block;
  width: 100%;
  text-align: center;

  img {
    width: 90%;
    height: 90%;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 10px rgba(0,0,0,0.1);
  }
`


import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import analytics, { generateAnalyticsPrefix } from '../../helpers/analytics';
import { SendContinue } from '../../constants/analyticEvents';

import SentProposal from './SentProposal';

const mapStateToProps = state => ({
  emails: state.emails,
  authorization: state.authorization,
  created: state.emails.created || state.authorization.authorizationComplete,
});

const props = ({
  emails,
  authorization,
  history,
  created,
}) => ({
  to: emails.to || authorization.to,
  postSignupAuthorized: authorization.authorizationComplete,
  authorized: authorization.authorized,
  goToDashboard: () => {
    if (!created) {
      analytics.track({
        event: generateAnalyticsPrefix(SendContinue),

      });
    }

    history.replace('/dashboard');
  },
});

export default compose(
  connect(
    mapStateToProps,
  ),
  withProps(props),
)(SentProposal);

import styled from 'styled-components';

export const ChosenLabel = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: #828283;
`

export const List = styled.div``;

export const Label = styled.b`
`;

export const Cost = styled.code`
  float: right;
  font-family: Inter;
  color: #828282;
  font-size: 14px;
`;

export const Note = styled.small`
  display: block;
  max-width: 25rem;
  margin-top: 0.5rem;
  font-weight: 400;
  font-family: var(--font-family-label);
  font-size: 0.8rem;
  line-height: 1.3;
  color: var(--text-color-soft);
`;

export const ImagesPreview = styled.div`
  display: inline-flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 20px;

  img {
    height: 2.4rem;

    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
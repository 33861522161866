import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import LandingPage from "./LandingPage";
import { reset as resetAction } from "../../ducks/authorization";

const mapStateToProps = (state) => ({
  authorization: state.authorization,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      reset: resetAction,
    },
    dispatch
  );

const props = ({ authorization }) => ({
  authorization,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props)
)(LandingPage);

import styled from "styled-components";

export default styled.div`
  position: sticky;
  top: 70px;
  width: 100%;
  background: #fff;
  padding: 15px 0 15px 20px;
  z-index: 1000;
  color: #828282;
  border-bottom: ${(props) => (props.scrolled ? "1px solid #c5c5c5" : "none")};

  & > * {
    margin: 0 8px;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &:first-child {
      /* color: #828282 */
      margin-left: 0;
    }

    &:last-child {
      /* color: #828282 */
      margin-right: 0;
    }
  }
`;


import { createSelector } from 'reselect';

const getImages = state => state.images;

export default createSelector(
  [
    getImages,
  ],
  images => images,
);

const SET_UI_STATE = 'SET_UI_STATE';

export const setUiState = (key, value) => ({
  type: SET_UI_STATE,
  payload: {
    key,
    value,
  },
});

export default function UiStateReducer (state = {}, action) {
  switch (action.type) {
    case SET_UI_STATE: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    default:
      return state;
  }
}

export const SET_UI = 'SET_UI';

export const setUi = state => ({
  type: SET_UI,
  payload: {
    state,
  },
});


export default function UiReducer (state = {
  signOverlay: false,
}, action) {
  switch (action.type) {
    case SET_UI: {
      return {
        ...state,
        ...action.payload.state,
      };
    }
    default:
      return state;
  }
}

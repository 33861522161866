import styled from 'styled-components';

export const Proposal = styled.div`
  z-index: 0;
  min-height: calc(100vh - var(--app-header-height));
  padding: var(--content-padding);
  overflow: hidden;
  background-color: var(--background-color);
`;

export const PrintPagebreak = styled.div`
  @media print {
    page-break-before: always;
  }
`;

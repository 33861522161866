import React from "react";
import Button from "../../../elements/Button/Button";
import Spacer from "../../../elements/Spacer/Spacer";
import ChoosyDesktop from "../../../assets/images/landing-page/choosy-desktop-laptop.png";

import {
  SectionWrapperHalf,
  SectionContent,
  SectionImg,
  Paragraph,
  Heading,
} from "../../../pages/LandingPage/LandingPage.styled";

const SectionWelcome = ({ isAuthorized }) => {
  return (
    <SectionWrapperHalf>
      <SectionContent>
        <Heading>
          Welcome to choosy <span>!</span>
        </Heading>
        <Paragraph>
          Thanks for visiting Choosy, we want to take your next project to the
          next level. Choosy is an app for homeowners, contractors, designers
          and architects (the Team) to choose fixtures, finishes and
          specifications for their construction project (Choosy, get it?).
        </Paragraph>
        <Spacer height="35" />
        <Button
          width="40%"
          size="small"
          to={isAuthorized ? "/dashboard" : "/signup"}
        >
          {isAuthorized ? "Go to Dashboard" : "Get Started"}
        </Button>
      </SectionContent>
      <SectionImg directionRight>
        <img src={ChoosyDesktop} alt="choosy desktop" />
      </SectionImg>
    </SectionWrapperHalf>
  );
};

export default SectionWelcome;

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import { withRouter } from "react-router-dom";

import analytics from "../../helpers/analytics";
import {
  SelectionsOptionLiked1,
  SelectionsOptionLiked2,
  SelectionsOptionLiked3,
  SelectionsOptionRemoved,
} from "../../constants/analyticEvents";
import SELECTION_STATES from "../../constants/selectionStates";

import {
  fetchProposal as fetchProposalAction,
  updateCostItemOptions as updateCostItemOptionsAction,
  iterateSelectionState as iterateSelectionStateAction,
  removeSelection as removeSelectionAction,
  updateProduct as updateProductAction,
  editCostItemNote as editCostItemNoteAction,
  changeSelectionOrder as changeSelectionOrderAction,
  showManualProductModal as showManualProductModalAction,
  updateManualProductModal as updateManualProductModalAction,
  addComment as addCommentAction,
} from "../../ducks/edit";

import { setUiState as setUiStateAction } from "../../ducks/uiState";

import ShoppingListEdit from "./ShoppingListEdit";

const mapStateToProps = (state) => ({
  authorization: state.authorization,
  proposal: state.edit,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCostItemOptions: updateCostItemOptionsAction,
      fetchProposal: fetchProposalAction,
      iterateSelectionState: iterateSelectionStateAction,
      removeSelection: removeSelectionAction,
      updateProduct: updateProductAction,
      editCostItemNote: editCostItemNoteAction,
      changeSelectionOrder: changeSelectionOrderAction,
      setUiState: setUiStateAction,
      showManualProductModal: showManualProductModalAction,
      updateManualProductModal: updateManualProductModalAction,
      addComment: addCommentAction,
    },
    dispatch
  );

const props = ({
  fetchProposal,
  iterateSelectionState,
  removeSelection,
  updateProduct,
  editCostItemNote,
  changeSelectionOrder,
  setUiState,
  updateCostItemOptions,
  showManualProductModal,
  updateManualProductModal,
  addComment,
}) => {
  return {
    iterateSelectionState:
      (costGroupId, costItemId) => (productId, currentPosition) => () => {
        iterateSelectionState({
          costGroupId,
          costItemId,
          productId,
        });

        let trackEvent = "";

        switch (currentPosition) {
          case SELECTION_STATES.NOT_SELECTED:
            trackEvent = SelectionsOptionLiked1;
            break;
          case SELECTION_STATES.LIKED:
            trackEvent = SelectionsOptionLiked2;
            break;
          case SELECTION_STATES.CHOSEN:
            trackEvent = SelectionsOptionLiked3;
            break;
          default:
            trackEvent = SelectionsOptionLiked1;
            break;
        }

        analytics.track({
          event: trackEvent,
        });
      },
    removeSelection: (costGroupId, costItemId) => (productId) => () => {
      if (window.confirm("Are you sure you want to remove this option?")) {
        analytics.track({
          event: SelectionsOptionRemoved,
        });

        removeSelection({
          costGroupId,
          costItemId,
          productId,
        });
      }
    },
    updateProduct: (costItemId) => (product) => () => {
      updateProduct({ costItemId, product });
    },
    changeSelectionOrder:
      (costGroupId, costItemId) =>
      (productId, sourceIndex, destinationIndex) => {
        changeSelectionOrder({
          costGroupId,
          costItemId,
          productId,
          sourceIndex,
          destinationIndex,
        });
      },
    editCostItemNote: (costGroupId, costItemId, note) =>
      editCostItemNote(costGroupId, costItemId, note),
    setUiState,
    fetchProposal,
    updateCostItemOptions,
    showManualProductModal,
    updateManualProductModal:
      (costGroupId, costItemId, itemName) => (product) => () => {
        setUiState("itemName", itemName);
        updateManualProductModal({
          costGroupId,
          costItemId,
          itemName,
          product,
        });
      },
    addComment: (costGroupId, costItemId, comment) =>
      addComment(costGroupId, costItemId, comment),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  lifecycle({
    componentDidMount() {
      const { fetchProposal, match, proposal } = this.props;

      if (!proposal.proposalId) {
        fetchProposal(match.params.id);
        return;
      }

      if (proposal.proposalId !== match.params.id) {
        fetchProposal(match.params.id);
      }
    },
  }),
  withProps(props)
)(ShoppingListEdit);

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: var(--full-bleed-width);
  overflow: auto;
`;

export const ImageList = styled.ul`
  height: 100%;
  display: flex;
  list-style: none;
  margin: 0;
  padding: ${props => props.padding} 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  scroll-padding: 50%;
  overflow-y: hidden;

  @media print {
    flex-direction: column;
  }
`;

export const ImageItem = styled.li`
  display: inline-block;
  padding: 5px;
  scroll-snap-align: center;
  position: relative;

  &:first-child {
    padding-left: 0;
  }
`;

export const DeleteIcon = styled.div`
  width: 20px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background: rgba(45, 193, 106, .5);
  border-radius: 20%;
  cursor: pointer;

  &:before {
    content: "✖";
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: rgba(45, 193, 106, .8);
  }
`;

export const LoadingPlaceholder = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: rgba(51, 51, 51, 0.07);
  border-radius: 5px;
`
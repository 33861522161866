import styled from 'styled-components';
import posed from 'react-pose';

const sidebarProps = {
  visible: {
    bottom: '0%',
    delay: 0,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 },
    },
  },
  hidden: {
    bottom: '-100%',
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 1300 },
    },
  },
};

export const Wrapper = styled(posed.div(sidebarProps))`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  z-index: 4;
  position: fixed;
  left: 0;

  justify-content: flex-end;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */

  @media print {
    display: none;
  }
`;

export const Holder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 0 20px 0 20px;

  @media (min-width: 800px) {
    width: 800px;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const SignatureWrapper = styled.div`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;

  color: #BCBBC1;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

import { v4 } from 'uuid';

const filterPhotos = photos => {
  const filteredPhotos = {};

  Object.keys(photos).forEach(key => {
    if(photos[key]) {
      if(photos[key].file) {
        filteredPhotos[key] = photos[key].file;
      }
    }
  })

  return filteredPhotos
}

export default function hydrateProposal(proposal, images) {
  const {
    fetching,
    proposalId,
    error,
    __v,
    _id,
    ...rest
  } = proposal;

  const { companyLogo, personalPhoto, ...restImages } = images;

  const filteredPhotos = filterPhotos({
    companyLogo,
    personalPhoto,
  })

  const {
    costGroups,
    ...restData
  } = rest;

  const filteredCostGroups = costGroups.filter(group => !group.pristine).map(({ values, ...restGroup }) => ({
    values: values.filter(value => !value.pristine && value.name).map(({id, ...restValue}) => ({id: v4(), ...restValue})),
    ...restGroup,
  }));

  return {
    costGroups: filteredCostGroups,
    ...restData,
    ...restImages,
    ...filteredPhotos,
  };
}


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { getLog as getLogAction } from '../../ducks/proposalLog';

import ProposalLog from './ProposalLog';

const mapStateToProps = state => ({
  proposalLog: state.proposalLog.data,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getLog: getLogAction,
}, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const {
        getLog,
        match,
      } = this.props;

      getLog(match.params.id);
    },
  }),
)(ProposalLog);

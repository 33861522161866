/* eslint-disable no-unused-vars */
import React from "react";

import Spacer from "../../elements/Spacer/Spacer";
import HR from "../../elements/HR/HR";

import NavigationIconList from "../../components/NavigationIconList/NavigationIconList";
import FormattedNumber from "../../components/FormattedNumber/FormattedNumber";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import CostItem from "../../components/CostItem/CostItem";
import MoodBoard from "../../components/MoodBoard/MoodBoard";

import analytics from "../../helpers/analytics";
import {
  SelectionsAllCostItemsOpened,
  SelectionsAllCostItemsClosed,
  SelectionsCostItemOpened,
  SelectionsCostItemClosed,
  SelectionsOptionAdded,
} from "../../constants/analyticEvents";

import {
  DetailedViewHeader,
  AccordeonControl,
} from "../../components/PlanView/PlanView.styled";

import {
  List,
  Cost,
  ChosenLabel,
  ImagesPreview,
} from "./ShoppingListEdit.styled";

const ShoppingListEdit = ({
  authorization,
  proposal,
  updateCostItemOptions,
  removeSelection,
  updateProduct,
  editCostItemNote,
  changeSelectionOrder,
  setUiState,
  showManualProductModal,
  updateManualProductModal,
  addComment,
}) => {
  if (proposal.proposalId === null) {
    return null;
  }

  const addProductAsOption =
    (url, costGroupId, costGroupItemId, itemName) => (event) => {
      event.preventDefault();

      analytics.track({
        event: SelectionsOptionAdded,
      });

      setUiState("itemName", itemName);

      updateCostItemOptions(url, costGroupId, costGroupItemId);
    };

  const handleShowManualProductModal = (costGroupId, costItemId, itemName) => {
    setUiState("itemName", itemName);
    showManualProductModal(costGroupId, costItemId);
  };
  if (proposal.costGroups.length === 0) {
    return null;
  }

  const costGroupsWithAllowance = proposal.costGroups.filter(
    (costGroup) =>
      costGroup.values.filter(
        (costItems) => !costItems.pristine && costItems.selection
      ).length > 0
  );

  if (costGroupsWithAllowance.length === 0) {
    return null;
  }

  const costItems = costGroupsWithAllowance
    .map((costGroup) => costGroup.values)
    .reduce((a, b) => [...a, ...b])
    .filter((costItem) => costItem.selection);

  const costItemsWithOption =
    costItems.filter((costItem) =>
      costItem.options ? costItem.options.length > 0 : false
    ).length || 0;

  const progress = (costItemsWithOption / costItems.length) * 100;

  const mapping = (value, key) => {
    if (window.location.hash) {
      if (`#${window.btoa(value.id)}` === window.location.hash) {
        return true;
      }

      return false;
    }

    return key === 0;
  };

  const { authorized } = authorization;

  return (
    <List>
      {!authorized && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            padding: "4px",
            background: "rgba(38,76,81,0.25)",
            fontSize: "14px",
            alignItems: "center",
            flex: "1 1 auto",
          }}
        >
          Warning: Your selections will not save until you{" "}
          <a href="/signin">log in</a>.
        </div>
      )}
      <h2>{proposal.projectName || "Untitled Project"}</h2>
      <HR />
      <Spacer height="20" />
      <ChosenLabel>{progress.toFixed()}% of choices have options</ChosenLabel>
      <Spacer height="6" />
      <ProgressBar progress={Math.ceil(progress)} />
      {costGroupsWithAllowance.map((costGroup, index) => {
        const pristineItems = costGroup.values.filter(
          (costItem) => !costItem.pristine && costItem.selection
        );
        const itemsWithOptions = pristineItems.filter(
          (costItem) => costItem.options?.length
        );
        const moodBoardOptions = itemsWithOptions.map(
          (item) => item.options[0]
        );

        return (
          <React.Fragment key={costGroup._id}>
            <NavigationIconList
              items={pristineItems}
              accordeon
              showIcon={false}
              expandByDefault={index === 0}
              expandMapping={mapping}
              onExpand={() => {
                analytics.track({
                  event: SelectionsCostItemOpened,
                });
              }}
              onCollapse={() => {
                analytics.track({
                  event: SelectionsCostItemClosed,
                });
              }}
              onExpandAll={() => {
                analytics.track({
                  event: SelectionsAllCostItemsOpened,
                });
              }}
              onCollapseAll={() => {
                analytics.track({
                  event: SelectionsAllCostItemsClosed,
                });
              }}
              component={(item) => (
                <CostItem
                  item={item}
                  costGroup={costGroup}
                  removeSelection={removeSelection}
                  updateProduct={updateProduct}
                  addProductAsOption={addProductAsOption}
                  editCostItemNote={editCostItemNote}
                  changeSelectionOrder={changeSelectionOrder}
                  showManualProductModal={handleShowManualProductModal}
                  updateManualProductModal={updateManualProductModal}
                  addComment={addComment}
                />
              )}
              itemStyles={`
                font-family: var(--font-family-label);
                font-weight: 500;
              `}
              padding="0"
              getName={(item) => item.name}
              getHelpText={(item) => (
                <>
                  {item.cost > 0 && (
                    <Cost>
                      <FormattedNumber number={item.cost} />
                    </Cost>
                  )}
                </>
              )}
              customItemComponents={(item, allCollapsed) => (
                <>
                  {!allCollapsed && (
                    <ImagesPreview>
                      {item.options.map((option) => (
                        <img
                          src={option.product.image}
                          key={option.product._id}
                        />
                      ))}
                    </ImagesPreview>
                  )}
                </>
              )}
            >
              {(allCollapsed, toggleAll) => (
                <DetailedViewHeader>
                  {costGroup.name}{" "}
                  <AccordeonControl onClick={toggleAll}>
                    {allCollapsed ? "Expand All" : "Collapse All"}
                  </AccordeonControl>
                </DetailedViewHeader>
              )}
            </NavigationIconList>
            {moodBoardOptions.length >= 4 ? (
              <MoodBoard name={costGroup.name} items={moodBoardOptions} />
            ) : null}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default ShoppingListEdit;

/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import initializeTagManager from './gtm'

import ProposalView from "../ProposalView";
import ProposalEdit from "../ProposalEdit";
import ProposalSelectionsView from "../ProposalSelectionsView";
import SendProposal from "../SendProposal";
import SentProposal from "../SentProposal";
import ProposalCreate from "../ProposalCreate";
import Dashboard from "../Dashboard";
import ProposalCreated from "../ProposalCreated";
import Authorization from "../Authorization";
import SignedProposal from "../SignedProposal";
import SignProposal from "../SignProposal";
import HandleAuthoriation from "../HandleAuthoriation";
import Logout from "../Logout";
import PrintProposal from "../PrintProposal";
import ProposalLog from "../ProposalLog";
import FAQ from "../FAQ";
import AllProductsView from "../AllProductsView";

import PrivateRoute from "../../composables/PrivateRoute";
import Navigation from "../../composables/Navigation";
import Hide from "../../composables/Hide";
import ProposalViewFooter from "../../composables/ProposalViewFooter";
import ProposalCreateEditFooter from "../../composables/ProposalCreateEditFooter";
import ProposalTopNavigation from "../../composables/ProposalTopNavigation";

import Footer from "../../components/Footer/Footer";
import NotFound from "../../components/NotFound/NotFound";
import PrivatePage from "../../components/PrivatePage/PrivatePage";

import Wrapper from "../../elements/Wrapper/Wrapper";
import Holder from "../../elements/Holder/Holder";

import Host from "../../hosts/index";

import Content from "./App.styled";

import LandingPage from "../LandingPage";

class App extends Component {
  componentDidMount() {
    const { authorization, authorize } = this.props;

    authorize(authorization.token);
    initializeTagManager();
  }

  render() {
    const { authorization, location } = this.props;

    if (window.location.pathname.indexOf("print") !== -1) {
      return <PrintProposal />;
    }

    if (authorization.state !== "initial") {
      return (
        <Fragment>
          <Switch>
            <Route path="/" exact component={LandingPage} />
            <Route path="/faq" exact component={FAQ} />
          </Switch>
          <Hide
            routes={[
              "/",
              "/create/",
              "/faq",
              "/proposal/edit/:id",
              "/proposal/:id/plan/:step",
            ]}
          >
            <Navigation />
            <Wrapper>
              <Holder
                nearFullWidth={
                  location.pathname.match(
                    "(proposal/)[a-zA-Z0-9/]+(/selections)"
                  ) || location.pathname.match("allProducts")
                }
              >
                <Content>
                  <Route
                    path={[
                      "/proposal/:id/selections",
                      "/proposal/received/:id/selections",
                      "/proposal/received/:id",
                      "/proposal/:id/",
                    ]}
                    exact
                    component={ProposalTopNavigation}
                  />
                  <Switch>
                    <PrivateRoute
                      path="/dashboard/"
                      exact
                      component={Dashboard}
                    />
                    <Route path="/signup/" exact component={Authorization} />
                    <Route path="/signin/" exact component={Authorization} />
                    <PrivateRoute path="/logout/" exact component={Logout} />

                    <Route
                      path="/signin/:token"
                      exact
                      component={HandleAuthoriation}
                    />

                    <Route
                      path={["/proposal/signup/:id", "/proposal/login/:id"]}
                      exact
                      component={ProposalCreated}
                    />

                    <PrivateRoute
                      path="/proposal/send/:id"
                      exact
                      component={SendProposal}
                      allow="contractor"
                    />
                    <Route
                      path="/proposal/sent/:id"
                      exact
                      component={SentProposal}
                    />

                    <PrivateRoute
                      path="/proposal/signed/:id"
                      exact
                      component={SignedProposal}
                    />

                    <PrivateRoute
                      path="/proposal/received/:id"
                      exact
                      component={ProposalView}
                      allow="homeowner"
                    />
                    <PrivateRoute
                      path="/proposal/sign/:id"
                      exact
                      component={ProposalView}
                    />
                    <Route
                      path="/proposal/:id/"
                      exact
                      component={ProposalView}
                    />
                    <PrivateRoute
                      path="/proposal/log/:id/"
                      exact
                      component={ProposalLog}
                    />

                    <Route
                      path={[
                        "/proposal/:id/selections",
                        "/proposal/received/:id/selections",
                      ]}
                      exact
                      component={ProposalSelectionsView}
                    />

                    <Route path="/success/" exact component={SentProposal} />
                    <Route
                      path="/allProducts/"
                      exact
                      component={AllProductsView}
                    />
                    <Route path="/private/" exact component={PrivatePage} />
                    <Route component={NotFound} />
                  </Switch>

                  <PrivateRoute
                    preventRedirect
                    path={[
                      "/proposal/:id/",
                      "/proposal/received/:id",
                      "/proposal/sign/:id",
                    ]}
                    exact
                    component={SignProposal}
                  />
                </Content>
              </Holder>
            </Wrapper>
            <PrivateRoute
              preventRedirect
              path={[
                "/proposal/received/:id",
                "/proposal/sign/:id",
                "/proposal/:id/",
              ]}
              exact
              component={ProposalViewFooter}
            />
            <Footer />
          </Hide>
          <Switch>
            <Route path="/create/" exact component={ProposalCreate} />
            <Route path="/error/" exact component={() => new Error()} />
            <PrivateRoute
              path="/proposal/edit/:id"
              exact
              component={ProposalEdit}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              preventRedirect
              path="/proposal/edit/:id"
              exact
              component={ProposalCreateEditFooter}
            />
            <PrivateRoute
              preventRedirect
              path="/create"
              exact
              component={ProposalCreateEditFooter}
            />
          </Switch>
          <Route component={Host} />
        </Fragment>
      );
    }

    return null;
  }
}

export default withRouter((props) => <App {...props} />);

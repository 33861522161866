
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  duplicateProposal, deleteProposal, reassignProposal, toggleProposalVisibility,
} from '../../ducks/manageProposal';

import ProposalControl from './ProposalControl';

const mapStateToProps = state => ({
  user: state.authorization.user,
  contractors: state.dashboard.contractors,
  manageProposal: state.manageProposal,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  duplicateProposal,
  deleteProposal,
  reassignProposal,
  toggleProposalVisibility,
}, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(ProposalControl);

import React from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import { PlanAddCostGroup, PlanNext } from "../../constants/analyticEvents";

import CostGroup from "../CostGroup";
import CostGroupListView from "../CostGroupListView";
import SectionToggle from "../../components/SectionToggle/SectionToggle";

import Link from "../../elements/Link/Link";

import { PreviewWrapper, DraggableCostGroupWrapper } from "./ThePlan.styled";

const ThePlan = ({
  create,
  setField,
  mode,
  createCostGroup,
  orderCostGroup,
}) => {
  const totalCost =
    create.costGroups.length > 0
      ? create.costGroups
          .map((costGroup) =>
            costGroup.values.length > 0
              ? costGroup.values.map(({ cost }) => Number(cost))
              : [0]
          )
          .reduce((a, b) => [...a, ...b])
          .reduce((a, b) => a + b)
      : 0;

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    orderCostGroup(result.source.index, result.destination.index);
  };

  const draggableCostGroup = (costGroup, index) => {
    return (
      <Draggable
        key={costGroup.id}
        draggableId={costGroup.id.toString()}
        index={index}
      >
        {(provided, snapshot) => (
          <DraggableCostGroupWrapper
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            {...provided.draggableProps}
          >
            <CostGroup
              create={create}
              mode={mode}
              costGroupId={costGroup.id}
              key={costGroup.id}
              costGroupDraggableProvided={provided}
              costGroupDraggableSnapshot={snapshot}
            />
          </DraggableCostGroupWrapper>
        )}
      </Draggable>
    );
  };

  return (
    <section id="step_4_plan" className="proposal_step">
      <div className="container">
        <div className="row">
          <div className="sticky_wrapper">
            <div className="proposal_preview_edit preview_sticky">
              <div id="preview">
                <PreviewWrapper>
                  {create.sectionVisibility.plan && (
                    <CostGroupListView
                      items={create.costGroups}
                      totalCost={totalCost}
                    />
                  )}
                </PreviewWrapper>
              </div>
              <div className="phone_bg" />
            </div>
          </div>
          <div className="proposal_content">
            <h1>4. Plan</h1>
            <SectionToggle create={create} setField={setField} section="plan" />
            <h4>
              This is the heart of the proposal, where you build the budget and
              break down the scope.
            </h4>
            <p>
              Our system of <i>Cost Groups</i> and <i>Cost Items</i> is designed
              flexibly so you can build your budgets the same way you always do.
              It is also designed to give your budgets some structure and
              organization so they look great to your customer and are easy to
              understand.
            </p>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-cost-group" type="cost-group">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {create.costGroups.map((costGroup, index) => {
                      return draggableCostGroup(costGroup, index);
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="center_button">
              <a
                className="button-small Button-module--button--2mvQU Button-module--outline--2mCFu"
                onClick={() => {
                  analytics.track({
                    event: generateAnalyticsPrefix(PlanAddCostGroup),
                  });

                  createCostGroup();
                }}
              >
                + Add cost group
              </a>
            </div>

            <div className="center_button">
              <Link.Scroll
                className="button-big Button-module--button--2mvQU"
                to="#step_5_shopping-list"
                onClick={() => {
                  analytics.track({
                    event: generateAnalyticsPrefix(PlanNext),
                  });
                }}
              >
                Next
              </Link.Scroll>
              <small>4 of 5</small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThePlan;

import styled from "styled-components";

export const Cost = styled.div`
  margin-top: 0.25rem;
  font-family: var(--font-family-monospace);
  font-size: 0.9rem;
  color: var(--text-color-soft);
`;

export const CostWrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

export const DetailedViewHeader = styled.h2`
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
`;

export const AccordeonControl = styled.span`
  color: #486aff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
`;

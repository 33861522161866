import React, { Fragment } from "react";

import ImageReel from "../ImageReel/ImageReel";

import InviteToProposal from "../InviteToProposal/InviteToProposal";

import ProposalViewWrapper from "../../elements/ProposalViewWrapper/ProposalViewWrapper";
import ProposalViewHeader from "../../elements/ProposalViewHeader/ProposalViewHeader";
import Icon from "../../elements/Icon/Icon";

import { Heading, Text } from "../../composables/BigPicture/BigPicture.styled";

import { IconHolder, Content } from "./BigPictureView.styled";

const BigPictureView = ({
  proposal,
  inviteUserToProposal,
  sendingInvite,
  isContractor,
}) => (
  <ProposalViewWrapper verticalAlignTop horizontalAlignLeft id="big-picture">
    {proposal.projectScope && (
      <ProposalViewHeader>Big Picture</ProposalViewHeader>
    )}

    <IconHolder>
      <Icon
        name="start-to-finish"
        customStyle={`    font-size: var(--icon-size);
          color: var(--line-color-softer);
          position: absolute;
          right: 10px;`}
      />
    </IconHolder>
    <Content>
      {proposal.projectScope && (
        <Fragment>
          <Heading>Goal</Heading>
          <Text>{proposal.projectScope}</Text>
        </Fragment>
      )}
      {(proposal.teamDescription || isContractor) && (
        <Fragment>
          <Heading>Team</Heading>
          <Text>{proposal.teamDescription}</Text>
          {isContractor && (
            <InviteToProposal
              proposal={proposal}
              inviteUserToProposal={inviteUserToProposal}
              sendingInvite={sendingInvite}
            />
          )}
        </Fragment>
      )}
      {proposal.currentCondition.length > 0 && (
        <Fragment>
          <Heading>Current Condition</Heading>
        </Fragment>
      )}
    </Content>
    {proposal.currentCondition.length > 0 && (
      <ImageReel items={proposal.currentCondition} />
    )}
  </ProposalViewWrapper>
);

export default BigPictureView;

import React from 'react';

import Can from '../../composables/Can/index';

import Headline from '../../elements/Headline/Headline';
import HR from '../../elements/HR/HR';
import Spacer from '../../elements/Spacer/Spacer';
import Button from '../../elements/Button/Button';
import Content from '../../elements/Content/Content';
import HelpText from '../../elements/HelpText/HelpText';

import Icon from '../NotFound/NotFound.styled';

const NotFound = () => {
  return ((
    <Content horizontal="20">
      <Icon />
      <Headline align="center">Private Project</Headline>
      <HR />
      <Spacer height="50" />
      <HelpText fontSize="20px" fontWeight="500">Please login to see this project.</HelpText>
      <Spacer height="50" />
      <Can forAuthorized>
        <Button href="/dashboard" target="">Go Home</Button>
      </Can>
      <Can forUnathorized>
        <Button to="/signin">Log In</Button>
      </Can>
    </Content>
  ));
};

export default NotFound;

/* eslint-disable no-unused-vars */
export const defaultProposalViewState = {
  proposal: null,
  fetching: false,
  homeownerFetched: false,
  contractorFetched: false,
  sendingInvite: false,
  error: null,
  homeowner: {
    name: "",
    email: "",
  },
  contractor: {
    name: "",
    email: "",
  },
};

const GET_PROPOSAL_START = "GET_PROPOSAL_START";
const GET_PROPOSAL_SUCCESS = "GET_PROPOSAL_SUCCESS";
const GET_PROPOSAL_ERROR = "GET_PROPOSAL_ERROR";

const GET_HOMEOWNER_START = "GET_HOMEOWNER_START";
const GET_HOMEOWNER_SUCCESS = "GET_HOMEOWNER_SUCCESS";
const GET_HOMEOWNER_ERROR = "GET_HOMEOWNER_ERROR";

const GET_CONTRACTOR_START = "GET_CONTRACTOR_START";
const GET_CONTRACTOR_SUCCESS = "GET_CONTRACTOR_SUCCESS";
const GET_CONTRACTOR_ERROR = "GET_CONTRACTOR_ERROR";

const CREATE_INVITE_USER = "CREATE_INVITE_USER";
const CREATE_INVITE_USER_SUCCESS = "CREATE_INVITE_USER_SUCCESS";
const CREATE_INVITE_USER_ERROR = "CREATE_INVITE_USER_ERROR";

export const getProposal =
  (
    id,
    options = {
      cleanup: false,
    }
  ) =>
  (dispatch) => {
    dispatch({
      type: GET_PROPOSAL_START,
      payload: {
        options,
      },
    });

    fetch(`/api/proposal/${id}`, {
      method: "GET",
    })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json();
        }

        throw new Error(res.status);
      })
      .then((proposal) =>
        dispatch({
          type: GET_PROPOSAL_SUCCESS,
          payload: {
            proposal,
          },
        })
      )
      .catch((error) =>
        dispatch({
          type: GET_PROPOSAL_ERROR,
          payload: {
            error,
          },
        })
      );
  };

export const getHomeownerData = (id) => (dispatch) => {
  dispatch({
    type: GET_HOMEOWNER_START,
  });

  fetch(`/api/proposal/homeowner/${id}`, {
    method: "GET",
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }

      throw new Error(res);
    })
    .then((homeowner) =>
      dispatch({
        type: GET_HOMEOWNER_SUCCESS,
        payload: {
          homeowner,
        },
      })
    )
    .catch((error) =>
      dispatch({
        type: GET_HOMEOWNER_ERROR,
        payload: {
          error,
        },
      })
    );
};

export const getContractorData = (id) => (dispatch) => {
  dispatch({
    type: GET_CONTRACTOR_START,
  });

  fetch(`/api/proposal/contractor/${id}`, {
    method: "GET",
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }

      throw new Error(res);
    })
    .then((contractor) =>
      dispatch({
        type: GET_CONTRACTOR_SUCCESS,
        payload: {
          contractor,
        },
      })
    )
    .catch((error) =>
      dispatch({
        type: GET_CONTRACTOR_ERROR,
        payload: {
          error,
        },
      })
    );
};

export const inviteUserToProposal = (id, inviteeEmail) => (dispatch) => {
  dispatch({
    type: CREATE_INVITE_USER,
  });

  fetch(`/api/proposal/${id}/invite`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ inviteeEmail }),
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.text();
      }

      throw new Error(res);
    })
    .then(() => {
      dispatch({
        type: CREATE_INVITE_USER_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: CREATE_INVITE_USER_ERROR,
        payload: {
          error,
        },
      });
    });
};

export default function ProposalViewReducer(
  state = defaultProposalViewState,
  action
) {
  switch (action.type) {
    case GET_PROPOSAL_START: {
      return {
        ...state,
        proposal: action.payload.options.cleanup ? null : state.proposal,
        fetching: true,
        error: null,
      };
    }
    case GET_PROPOSAL_SUCCESS: {
      return {
        ...state,
        fetching: false,
        proposal: {
          ...action.payload.proposal,
          sectionVisibility: action.payload.proposal.sectionVisibility || {
            cover: true,
            welcome: true,
            bigpicture: true,
            plan: true,
            shoppinglist: true,
            contract: true,
          },
        },
      };
    }
    case GET_PROPOSAL_ERROR: {
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    }
    case GET_HOMEOWNER_START:
      return {
        ...state,
        fetching: true,
        homeownerFetched: false,
        homeowner: defaultProposalViewState.homeowner,
      };
    case GET_HOMEOWNER_SUCCESS:
      return {
        ...state,
        fetching: true,
        homeowner: action.payload.homeowner,
        homeownerFetched: true,
      };
    case GET_CONTRACTOR_START:
      return {
        ...state,
        fetching: true,
        contractorFetched: false,
        contractor: defaultProposalViewState.contractor,
      };
    case GET_CONTRACTOR_SUCCESS:
      return {
        ...state,
        fetching: true,
        contractor: action.payload.contractor,
        contractorFetched: true,
      };
    case CREATE_INVITE_USER:
      return { ...state, sendingInvite: true };
    case CREATE_INVITE_USER_SUCCESS:
      return { ...state, sendingInvite: false };
    case CREATE_INVITE_USER_ERROR:
      return { ...state, sendingInvite: false };
    default:
      return state;
  }
}

import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import Input from '../../elements/Input/Input';
import Spacer from '../../elements/Spacer/Spacer';
import { Container, Label, ImageContainer, Product } from './AllProductsView.styled';

const LIMIT = 8;

class AllProductsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      loading: false,
      searchTerms: '',
      page: 1,
      allProductsLoaded: false
    }

    this.onChange = _.debounce((searchTerms) => {
      this.setState({ searchTerms, page: 1 });
      this.fetchProducts(false);
    }, 400);

    window.onscroll = _.debounce(() => {
      const { loading, allProductsLoaded } = this.state;

      if (loading || allProductsLoaded) {
        return;
      }

      if (
        window.innerHeight + document.documentElement.scrollTop
        >= document.documentElement.offsetHeight
      ) {
        this.fetchProducts();
      }
    }, 100);
  }

  componentDidMount() {
    this.fetchProducts();
  }

  fetchProducts(isAppending = true) {
    const { searchTerms, products, page } = this.state;
    this.setState({ loading: true });

    let params = `limit=${LIMIT}&page=${page}`;
    params += searchTerms ? `&searchTerms=${encodeURIComponent(searchTerms)}` : '';

    fetch(`/api/products?${params}`).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }

      throw new Error(res.status);
    })
    .then(newProducts => {
      const displayProducts = isAppending ? [...products, ...newProducts] : newProducts;
      const allProductsLoaded = newProducts.length < LIMIT;
      this.setState({
        products: displayProducts,
        loading: false,
        allProductsLoaded,
        page: allProductsLoaded ? page : page + 1
      });

      // ensures the screen will always scroll
      if (window.innerHeight >= document.documentElement.scrollHeight) {
        this.fetchProducts();
      }
    });
  }



  render() {
    const { loading, products } = this.state;

    return (
      <Container>
        <Spacer height="30" />
        <Input.Input type="text" placeholder="Search Products" onChange={(e) => { this.onChange(e.target.value) }} />
        <Spacer height="5" />
        { !loading &&
          (
            <Label><strong>Displaying {products.length} products. </strong>
            Filter by brand (Kohler, Semi-hand made), type (sink, faucet) or room (kitchen, bath).</Label>
          )
        }
        <ImageContainer>
          { products.map(product =>
            <Product key={product._id} href={product.url} target="_blank">
              <img title={`$${product.price} at ${product.hostname}`} src={product.image} />
            </Product>
          )}
        </ImageContainer>
        { loading && (
          <>
            <Spacer height="30" />
            <Label>loading more...</Label>
          </>
        )}
      </Container>
    )
  }
}

export default AllProductsView;

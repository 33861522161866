import React from 'react';

import ShoppingListEdit from '../../composables/ShoppingListEdit';

import ProposalSelection from './ProposalSelectionsView.styled';

const ProposalSelectionsView = () => {
  return (
    <ProposalSelection>
      <ShoppingListEdit left="40" />
    </ProposalSelection>
  )
};

export default ProposalSelectionsView;

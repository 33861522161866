import React from 'react';

import Image from './ExifImage.styled';

const ExifImage = ({ src }) => (<Image
  src={src}
  height="260"
  alt=""
/>);

export default ExifImage;


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { authorize } from '../../ducks/authorization';

import App from './App';

const mapStateToProps = state => ({
  authorization: state.authorization,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  authorize,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

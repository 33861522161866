import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";

import {
  createCostItem as createCostItemAction,
  updateCostItemValue as updateCostItemValueAction,
  updateCostGroupValue as updateCostGroupValueAction,
  removeCostGroup as removeCostGroupAction,
  removeCostGroupItem as removeCostGroupItemAction,
  orderCostGroupItems as orderCostGroupItemsAction,
} from "../../ducks/create";
import {
  createCostItem as editCreateCostItemAction,
  updateCostItemValue as editUpdateCostItemValueAction,
  updateCostGroupValue as editUpdateCostGroupValueAction,
  removeCostGroup as editRemoveCostGroupAction,
  removeCostGroupItem as editRemoveCostGroupItemAction,
  orderCostGroupItems as editOrderCostGroupItemsAction,
} from "../../ducks/edit";

import { uploadCostGroupImage, removeCostGroupImage } from "../../ducks/images";

import CostGroup from "./CostGroup";

const mapStateToProps = (state) => ({
  lastCostGroupId: state.uiState.lastCostGroupId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createCostItem: createCostItemAction,
      updateCostItemValue: updateCostItemValueAction,
      updateCostGroupValue: updateCostGroupValueAction,
      removeCostGroup: removeCostGroupAction,
      removeCostGroupItem: removeCostGroupItemAction,
      orderCostGroupItems: orderCostGroupItemsAction,
      uploadCostGroupImage,
      removeCostGroupImage,
      editCreateCostItem: editCreateCostItemAction,
      editUpdateCostItemValue: editUpdateCostItemValueAction,
      editUpdateCostGroupValue: editUpdateCostGroupValueAction,
      editRemoveCostGroup: editRemoveCostGroupAction,
      editRemoveCostGroupItem: editRemoveCostGroupItemAction,
      editOrderCostGroupItems: editOrderCostGroupItemsAction,
    },
    dispatch
  );

const props = ({
  mode,
  createCostItem,
  updateCostItemValue,
  updateCostGroupValue,
  removeCostGroup,
  removeCostGroupItem,

  editCreateCostItem,
  editUpdateCostItemValue,
  editUpdateCostGroupValue,
  editRemoveCostGroup,
  editRemoveCostGroupItem,
}) => {
  if (mode === "create") {
    return {
      createCostItem,
      updateCostItemValue,
      updateCostGroupValue,
      removeCostGroup,
      removeCostGroupItem,
    };
  }

  return {
    createCostItem: editCreateCostItem,
    updateCostItemValue: editUpdateCostItemValue,
    updateCostGroupValue: editUpdateCostGroupValue,
    removeCostGroup: editRemoveCostGroup,
    removeCostGroupItem: editRemoveCostGroupItem,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props)
)(CostGroup);

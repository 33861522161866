import styled from 'styled-components';

import media, {MEDIUM} from '../../style/media-query';

import iconNav from '../../assets/images/icon-nav.svg'
import phone from '../../assets/images/phone.svg'
import envelope from '../../assets/images/envelope.svg'

export const FooterWrapper = styled.div`
  font-family: Inter, serif;
`;

export const IconNav = styled.div`
  width: 66px;
  height: 66px;
  background-image: url(${iconNav});
`;

export const FooterNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const FooterNavigationBlock = styled.div`
  flex: 0 33%;
  display: flex;
  flex-direction: column;

  ${media.lessThan(MEDIUM)`
    flex: 0 100%;
  `}

  &:last-child {
    margin-right: 0;

    ${media.lessThan(MEDIUM)`
      margin-top: 60px;
    `}
  }

  & > * {
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #333333;
  }
`;

export const FooterNavigationItem = styled.span`
  display: flex;
  align-items: center;

  justify-content: ${props => {
    switch (props.align) {
      case 'right':
        return 'flex-end'
      case 'left':
        return 'flex-start'
      default:
        return 'inherit'
    }
  }};

  ${props => props.align === 'right' && media.lessThan(MEDIUM)`
    justify-content: flex-start;
  `}
`;

export const Copyright = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${media.lessThan(MEDIUM)`
    flex-direction: column-reverse;

    & > *:first-child {
      margin-top: 10px;
    }

    & > *:last-child {
      text-align: left;
    }
  `}
`;

export const Icon = styled.span`
  ${props => props.phone ? `
    background-image: url(${phone});
    width:14.4px;
    height:14.4px;
  ` : `
    background-image: url(${envelope});
    width:16px;
    height:11.2px;
  `}

  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;

  margin-right: ${props => props.margin ? '7px' : '0'};
`;
import React, { Component } from "react";

import Input from "../../elements/Input/Input";
import Spacer from "../../elements/Spacer/Spacer";
import Button from "../../elements/Button/Button";

import SelectionReel from "../SelectionReel/SelectionReel";
import CostItemComments from "../CostItemComments/CostItemComments";

import {
  ListItem,
  AddItemWrapper,
  ListItemNote,
  NoteLabel,
  NoteInput,
  DnDTooltip,
  AddProductInfo,
  AddManualLink,
} from "./CostItem.styled";

class CostItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      placeholder: this.generatePlaceholder(props.item),
      note: props.item.note,
    };
  }

  componentDidMount() {
    this.resizeTextareaIfNeeded();

    const { item } = this.props;

    if (
      window.location.hash &&
      `#${window.btoa(item.id)}` === window.location.hash
    ) {
      const $el = document.querySelector(window.location.hash);

      window.scrollTo({
        left: $el.offsetLeft,
        top: $el.offsetTop,
        behavior: "smooth",
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.item.recentlyAddedOption) {
      const { item } = this.props;

      this.setState({
        inputValue: "",
      });

      item.recentlyAddedOption = false;
    }
  }

  generatePlaceholder = (item) => {
    const article = item.name.match("^[aieouxAIEOUX].*") ? "an" : "a";
    return `Add ${article} ${item.name.toLowerCase()} by pasting its store URL`;
  };

  /*
    Allows the "note" textarea to expand as the user types
  */
  resizeTextareaIfNeeded = () => {
    if (
      this.textarea &&
      this.textarea.scrollHeight > this.textarea.offsetHeight
    ) {
      this.textarea.style.height = `${this.textarea.scrollHeight}px`;
    }
  };

  handleInputChange = (key) => (e) => {
    if (key === "note") {
      this.resizeTextareaIfNeeded(e.target);
    }

    this.setState({
      [key]: e.target.value,
    });
  };

  handleBlur = () => {
    const { item, costGroup, editCostItemNote } = this.props;

    const { note } = this.state;

    if (note !== item.note) {
      editCostItemNote(costGroup._id, item.id, note);
    }
  };

  render() {
    const {
      item,
      costGroup,
      removeSelection,
      updateProduct,
      addProductAsOption,
      changeSelectionOrder,
      showManualProductModal,
      updateManualProductModal,
      addComment,
    } = this.props;

    const { inputValue, placeholder, note } = this.state;

    return (
      <ListItem key={item.id} id={window.btoa(item.id)}>
        {item.options?.length > 1 ? (
          <DnDTooltip>&larr; Drag & Drop to Reorder &rarr;</DnDTooltip>
        ) : null}
        <SelectionReel
          item={item}
          items={item.options}
          removeSelection={removeSelection(costGroup._id, item.id)}
          updateProduct={updateProduct(item._id)}
          updateManualProductModal={updateManualProductModal(
            costGroup._id,
            item._id,
            item.name
          )}
          addProductAsOption={addProductAsOption}
          changeSelectionOrder={changeSelectionOrder(costGroup._id, item.id)}
        />
        <Spacer height="30" />
        <AddProductInfo>
          {placeholder}{" "}
          <AddManualLink
            onClick={() =>
              showManualProductModal(costGroup._id, item._id, item.name)
            }
          >
            or manually add an item of your choice
          </AddManualLink>
        </AddProductInfo>
        <AddItemWrapper
          onSubmit={addProductAsOption(
            inputValue,
            costGroup._id,
            item._id,
            item.name
          )}
        >
          <Input.Input
            value={inputValue}
            onChange={this.handleInputChange("inputValue")}
            name="url"
            height="47px"
            wrapperWidth="50%"
          />
          <Spacer width="10" />
          <Button
            size="small"
            color="#fff"
            background="#486AFF"
            width="100px"
            type="submit"
            disabled={inputValue.length < 1}
          >
            Add
          </Button>
        </AddItemWrapper>
        <Spacer height="30" />
        <ListItemNote>
          <NoteLabel>Note</NoteLabel>
          <NoteInput
            onChange={this.handleInputChange("note")}
            onBlur={this.handleBlur}
            ref={(el) => {
              this.textarea = el;
            }}
            value={note}
          />
        </ListItemNote>
        <CostItemComments
          commentList={item.comments}
          addComment={addComment}
          productId={item.id}
          costGroupId={costGroup._id}
        />
      </ListItem>
    );
  }
}

export default CostItem;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const linkBase = (props) => `
  font-family: inherit;
  font-style: normal;
  font-weight: inherit;
  line-height: 20px;
  text-decoration: ${props.underline ? "underline" : "none"};
  color: inherit;
  line-height: inherit;
  transition: color .1s ease-in;

  ${
    props.nohover
      ? ""
      : `
    &:hover, &:focus {
      color: #486AFF;
    }
  `
  }

  ${
    props.active
      ? `
    color: #486AFF;
  `
      : ""
  }

  ${props.customStyle ? props.customStyle : ""}
`;

export const HtmlLink = styled.a.attrs({
  target: "_blank",
})`
  ${(props) => linkBase(props)}
`;

export const RouterLink = styled(Link)`
  ${(props) => linkBase(props)}
`;

const scrollWithOffset = ({ el, offset }) => {
  const elementPosition = el.offsetTop - offset;

  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth",
  });
};

export const ScrollLink = ({ to, offset = 40, children, onClick, ...rest }) => {
  const handleClick = () => {
    const el = document.querySelector(to);

    scrollWithOffset({ el, offset });
    onClick();
  };

  return (
    <div onClick={handleClick} {...rest}>
      {children}
    </div>
  );
};

export default {
  Html: HtmlLink,
  Router: RouterLink,
  Scroll: ScrollLink,
};

import React, { Fragment } from "react";

import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import { IntroLetsGo } from "../../constants/analyticEvents";

import Icon from "../../elements/Icon/Icon";
import Link from "../../elements/Link/Link";

const Intro = ({ create, edit }) => (
  <section className={`proposal_intro edit-${!!edit}`}>
    <div className="container">
      <div className="icon_container">
        <Icon name="paper-stack" className="icon_intro" />
      </div>
      <div className="proposal_content">
        <h1>
          {create && (
            <Fragment>
              Create a <span className="highlight">Proposal</span>
            </Fragment>
          )}
          {edit && (
            <Fragment>
              Edit your <span className="highlight">Proposal</span>
            </Fragment>
          )}
        </h1>
        <h3>
          {create && (
            <Fragment>
              Smooth construction projects run on Choosy. In five easy steps you
              will get:
            </Fragment>
          )}
          {edit && <Fragment>Need to make changes? No problem.</Fragment>}
        </h3>
        {create && (
          <Fragment>
            <ul>
              <li>
                &#10004; A professional proposal that stands out from the crowd
              </li>
              <li>
                &#10004; An easy-to-use project plan and allowance manager
              </li>
              <li>
                &#10004; A solid contract that protects both contractor and
                homeowner
              </li>
            </ul>
            <div className="center_button">
              <Link.Scroll
                className="button-big Button-module--button--2mvQU"
                to="#step_1_cover"
                style={{ marginTop: "3rem" }}
                onClick={() => {
                  analytics.track({
                    event: generateAnalyticsPrefix(IntroLetsGo),
                  });
                }}
              >
                Let's go!
              </Link.Scroll>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  </section>
);

export default Intro;

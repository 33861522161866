import React, { useState } from 'react';

import Icon from '../../elements/Icon/Icon';

import IconHolder from './AutoCalcInput.styled';

const safeEvalInternal = (obj) => {
    try {
        // eslint-disable-next-line no-new-func
        return Function(`"use strict";return (${  obj  })`)()
    } catch (e) {
        return null;
    }
};

const isValidMath = (math) => {

    const lastChar = math.substring(math.length - 1);
    const firstChar = math.charAt(0);

    const isValidStart = !Number.isNaN(firstChar) || firstChar === '(';
    const isValidEnd = !Number.isNaN(lastChar) || lastChar === ')';

    return isValidStart && isValidEnd;
}

const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

const onChangeHandler = ({ calculationHandler, setInputState, setSelectionStart, setSelectionEnd }) => (e) => {
    const tvRaw = e.target.value;

    if (!tvRaw) {
        setInputState(prevState => {
            return {...prevState, defaultValue: tvRaw, rawValue: tvRaw, value: tvRaw }
        });

        calculationHandler(tvRaw, tvRaw);

        return;
    }

    const splitedTv = tvRaw.split('=');

    const tv = splitedTv[0].split(",").join(".");

    if (splitedTv.length > 2) return;

    if (!/^[\d ()+-.\/\*]+$/g.test(tv)) return;

    const numbers = tvRaw.split(/[^0-9\.]+/);

    // eslint-disable-next-line no-restricted-syntax
    for (const entry of numbers) {
        if (entry && !entry.endsWith('.')) {
            const test = /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/g.test(entry);
            if (!test) return;
        }
    }

    setSelectionStart(e.target.selectionStart);
    setSelectionEnd(e.target.selectionEnd);

    setInputState(prevState => {
        return {...prevState, defaultValue: tvRaw, rawValue: tvRaw }
    });

    if (!isValidMath(tv)) return;

    const evVal = round(safeEvalInternal(tv));

    setInputState(prevState => {
        return {...prevState, value: evVal }
    });

    if (splitedTv.length > 1) {
        splitedTv[1] = evVal;
        const fullCalculatedValue = splitedTv.join('=');
        setInputState(prevState => {
            return {...prevState, defaultValue: fullCalculatedValue, rawValue: fullCalculatedValue }
        });
    }

    const val1 = evVal.toString().split(",").join(".");
    const val2 = tv.split(",").join(".");

    setInputState(prevState => {
        return {...prevState, isCalcActive: val1 !== val2 }
    });

    calculationHandler(evVal, tv);
};

const onFocusStateChangeHandler = ({ value, setInputState, focus }) => () => {
    setInputState(prevState => ({...prevState, defaultValue: value, focus }));
};


const keypressHandler = ({ input }) => (e) => {
    if (e.key === "Enter") {
        input.current.blur();
    }
};

const AutoCalcInput = ({
    defaultValueProp,
    defaultRawValueProp,
    calculationHandler
}) => {
    const [inputState, setInputState] = useState({
        defaultValue: round(defaultValueProp) || "",
        value: round(defaultValueProp) || "",
        rawValue: defaultRawValueProp || defaultValueProp || "",
        isCalcActive: defaultRawValueProp && defaultRawValueProp !== defaultValueProp
    });
    const [selectionStart, setSelectionStart] = React.useState(0);
    const [selectionEnd, setSelectionEnd] = React.useState(0);
    const input = React.useRef(null);

    React.useEffect(() => {
        if(inputState.focus) {
            input.current.selectionStart = selectionStart;
            input.current.selectionEnd = selectionEnd;
        }
    }, [inputState, selectionStart, selectionEnd]);

    return (
        <div>
<IconHolder hideIcon = { !inputState.isCalcActive }>
      <Icon
        name="calculator-icon"
        size= { 20 }
        customStyle={`    font-size: var(--icon-size);
          color: var(--line-color-softer);
          position: absolute;
          top: 3px;`}
      />
    </IconHolder>
        <input
        type = "text"
        ref = { input }
        value = { inputState.defaultValue }
        onChange = { onChangeHandler({ calculationHandler, setInputState, setSelectionStart, setSelectionEnd }) }
        onFocus = { onFocusStateChangeHandler({ value: inputState.rawValue, setInputState, focus: true }) }
        onBlur = { onFocusStateChangeHandler({ value: inputState.value, setInputState, focus: false }) }
        onKeyPress = { keypressHandler({ input }) }
        />
        </div>

    );
}


export default AutoCalcInput;
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import {
  setField,
  createCostGroup,
  createProposal,
  resetProposalId as resetProposalIdAction,
  orderCostGroup as orderCostGroupAction,
} from "../../ducks/create";

import {
  reset as emailResetAction,
  sendCreatedProposal as sendCreatedProposalAction,
} from "../../ducks/emails";
import {
  uploadSingleFile,
  removeSingleFile,
  uploadMultipleFiles,
  removeMultipleFiles,
} from "../../ducks/images";

import getImages from "../../selectors/getImages";

import ProposalCreate from "./ProposalCreate";

const mapStateToProps = (state) => ({
  create: state.create,
  authorization: state.authorization,
  emails: state.emails,
  images: getImages(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setField,
      uploadSingleFile,
      removeSingleFile,
      uploadMultipleFiles,
      removeMultipleFiles,
      createCostGroup,
      createProposal,
      sendCreatedProposal: sendCreatedProposalAction,
      resetProposalId: resetProposalIdAction,
      emailReset: emailResetAction,
      orderCostGroup: orderCostGroupAction,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { emailReset, resetProposalId } = this.props;
      resetProposalId();
      emailReset();
    },
    componentWillReceiveProps(nextProps) {
      const { history, match, authorization, sendCreatedProposal } = this.props;

      if (
        !nextProps.create.fetching &&
        nextProps.create.proposalId !== null &&
        match.url.indexOf("create") !== -1
      ) {
        if (authorization.authorized) {
          if (
            nextProps.emails.fetching === false &&
            nextProps.emails.sent === true &&
            nextProps.emails.created === true
          ) {
            history.push(`/proposal/${nextProps.create.proposalId}/`);
          }

          if (
            !nextProps.emails.fetching &&
            !nextProps.emails.sent &&
            !nextProps.emails.created
          ) {
            sendCreatedProposal(authorization.user.email);
          }
        } else {
          history.push(`/proposal/signup/${nextProps.create.proposalId}/`);
        }
      }
    },
  })
)(ProposalCreate);

import { useEffect } from 'react';

const TrailingSlashHost = ({ history }) => {
  useEffect(() => {
    let replace = false
    let newURL = window.location.pathname;

    if (window.location.pathname[window.location.pathname.length - 1] !== '/') {
      newURL = `${window.location.pathname}/`;
      replace = true
    }

    if (window.location.pathname.indexOf('//') !== -1) {
      newURL = newURL.replace(/\/\//g, "/")
      replace = true
    }

    if(replace) {
      history.replace(newURL);
    }

  });


  return null;
};
export default TrailingSlashHost;

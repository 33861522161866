export const IntroLetsGo = 'Intro - Let\'s Go';
export const CoverNext = 'Cover - Next';
export const WelcomeNext = 'Welcome - Next';
export const BigPictureNext = 'Big Picture - Next';
export const PlanCostGroup = 'Plan - Cost Group';
export const PlanCostItem = 'Plan - Cost Item';
export const PlanRemoveCostGroup = 'Plan - Remove Cost Group';
export const PlanRemoveCostItem = 'Plan - Remove Cost Item';
export const PlanAllowance = 'Plan - Allowance';
export const PlanAddCostGroup = 'Plan - Add Cost Group';
export const PlanNext = 'Plan - Next';
export const ShoppingListEditPlan = 'Shopping List - Edit Plan';
export const ShoppingListNext = 'Shopping List - Next';
export const ContractGenerateProposal = 'Contract - Generate Proposal';
export const ModalViewProposal = 'Modal - View Proposal';

export const ViewerCoverOpenProposal = 'Viewer: Cover - Open Proposal';
export const ViewerWelcomeContinue = 'Viewer: Welcome - Continue';
export const ViewerBigPictureContinue = 'Viewer: Big Picture - Continue';
export const ViewerPlanDetailedWork = 'Viewer: Plan - Detailed Work';
export const ViewerPlanContinue = 'Viewer: Plan - Continue';
export const ViewerShoppingListContinue = 'Viewer: Shopping List - Continue';
export const ViewerContractSignContract = 'Viewer: Contract - Sign Contract';
export const ViewerHamburgermenu = 'Viewer: Hamburger menu';
export const ViewerHelp = 'Viewer: Help';
export const ViewerLogo = 'Viewer: Logo';
export const HoorayOk = 'Viewer: Hooray - Ok';

export const DashboardCreateProposal = 'Dashboard: Create New Proposal';
export const DashboardOpenProposal = 'Dashboard: Open Proposal';

export const Login = 'Login: Submits Login';
export const Signup = 'Signup: Submits Signup';

export const CreatorViewProposal = 'Creator: Signup and enter email to view proposal';
export const CreatorLogin = 'Creator: Login and enter email to view proposal';

export const FooterSendToHomeowner = 'Viewer: Overlay - Send to Homeowner';
export const FooterEdit = 'Viewer: Overlay - Edit';
export const FooterHomeownerSign = 'Viewer: Overlay - Sign';

export const SendToHomeowner = 'Viewer: Send - Send to Homeowner';

export const SendContinue = 'Viewer: Send - Confirmation';

export const DoneSigning = 'Viewer: Overlay - Signature Submission';
export const ClearSigning = 'Viewer: Overlay - Clear Signature';

export const PlanAttachCostGroupPhoto = 'Plan - Attach Cost Group Photo';
export const PlanRemoveCostGroupPhoto = 'Plan - Remove Cost Group Photo';
export const PlanOpenPhotoFullScreen = 'Plan - Open Photo Full Screen';

export const DashboardDuplicate = 'Dashboard: Duplicate';
export const DashboardDelete = 'Dashboard: Delete';
export const DashboardEdit = 'Dashboard: Edit';
export const DashboardTransferOwnership = 'Dashboard: Transfer Ownership';

export const LoggedOutHamburgerMenu = 'Logged Out: Hamburger Menu';
export const LoggedinHamburgerMenu = 'Logged-in: Hamburger Menu';

export const OpenedCostItem = 'Opened Cost Item';
export const ClosedCostItem = 'Closed Cost Item';

export const YellowSignCTA = 'Yellow Sign CTA';
export const PDFButton = 'PDF Button';

export const HOLandingClicksTopCTA = 'HO Landing: Clicks Top CTA';
export const HOLandingClicksBottomCTA = 'HO Landing: Clicks Bottom CTA';
export const HOLandingClicksschedulecall = 'HO Landing: Clicks schedule call';
export const ContractorLandingClicksTopCTA = 'Contractor Landing: Clicks Top CTA';
export const ContractorLandingClicksBottomCTA = 'Contractor Landing: Clicks Bottom CTA';

export const SelectionsAllCostItemsOpened = 'Selections: all cost items opened';
export const SelectionsAllCostItemsClosed = 'Selections: all cost items closed';
export const SelectionsCostItemOpened = 'Selections: cost item opened';
export const SelectionsCostItemClosed = 'Selections: cost item closed';
export const SelectionsOptionAdded = 'Selections: option added';
export const SelectionsExternalProductPageOpened = 'Selections: external product page opened';
export const SelectionsOptionLiked1 = 'Selections: option liked-1';
export const SelectionsOptionLiked2 = 'Selections: option liked-2';
export const SelectionsOptionLiked3 = 'Selections: option liked-3';
export const SelectionsOptionRemoved = 'Selections: option removed';

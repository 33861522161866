import styled from "styled-components";

const gray = "#82828240";

export const ImagesWrapper = styled.div`
  padding-left: 20px;
`;

export const InputHolder = styled.div`
  display: flex;
  align-items: center;
  font-family: Inter, sans-serif;
  margin-top: 20px;
`;

export const DraggableIcon = styled.td`
  width: unset !important;
  border: 0 !important;
  background-color: ${(props) => (props.isDragging ? gray : "")};

  &:hover {
    background-color: ${gray};
  }

  div {
    display: flex;
    justify-content: center;
    padding: 0 5px;
  }
`;

export const CostGroupWrapper = styled.div`
  padding: 20px 2px 5px 2px;
`;

export const DraggableCostItem = styled.tr`
  user-select: none;
  background: ${(props) => (props.isDragging ? gray : "")};
`;


import {
  createStore, compose, applyMiddleware, combineReducers,
} from 'redux';
import thunk from 'redux-thunk';
import cookie from 'cookie';

import localStorageMiddleware from '../middleware/localStorageMiddleware';
// import syncProposalEditMiddleware from '../middleware/syncProposalEditMiddleware';

import createReducer, { defaultCreateState, generateCostItem } from '../ducks/create';
import editReducer, { defaultEditState } from '../ducks/edit';
import imagesReducer, { defaultImagesState } from '../ducks/images';
import proposalViewReducer, { defaultProposalViewState } from '../ducks/proposalView';
import uiStateReducer from '../ducks/uiState';
import modalsReducer from '../ducks/modals';
import emailsReducer from '../ducks/emails';
import dashboardReducer from '../ducks/dashboard';
import manageProposalReducer from '../ducks/manageProposal';
import authorizationReducer, { defaultAuthorizationState } from '../ducks/authorization';
import uiReducer from '../ducks/ui';
import proposalLogReducer from '../ducks/proposalLog';

const LOCAL_STORAGE_VERSION = '3.0.0';

const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true, traceLimit: 25,
  }) : compose;

const middleware = applyMiddleware(thunk, localStorageMiddleware, /* syncProposalEditMiddleware */);

const rootReducer = combineReducers({
  create: createReducer,
  edit: editReducer,
  images: imagesReducer,
  proposalView: proposalViewReducer,
  uiState: uiStateReducer,
  modals: modalsReducer,
  emails: emailsReducer,
  dashboard: dashboardReducer,
  authorization: authorizationReducer,
  ui: uiReducer,
  manageProposal: manageProposalReducer,
  proposalLog: proposalLogReducer,
});

const initialState = {
  create: defaultCreateState,
  edit: defaultEditState,
  images: defaultImagesState,
  proposalView: defaultProposalViewState,
  authorization: defaultAuthorizationState,
};

const getInitialState = () => {
  const cookies = cookie.parse(document.cookie);

  if (localStorage.getItem('VERSION') !== LOCAL_STORAGE_VERSION) {
    localStorage.clear();
    localStorage.setItem('VERSION', LOCAL_STORAGE_VERSION);
  }

  try {
    if (localStorage.getItem('create')) {
      const create = JSON.parse(localStorage.getItem('create'));

      if (Object.keys(create).length !== 0) {
        initialState.create = Object.assign(initialState.create, create);
        initialState.create.costGroups = initialState.create.costGroups.map((costGroup) => {
          if(costGroup.values.length === 0) {
            return {
              ...costGroup,
              values: [generateCostItem()]
            }
          }

          return costGroup;
        })
      }
    }
    if (localStorage.getItem('edit')) {
      const edit = JSON.parse(localStorage.getItem('edit'));

      if (Object.keys(edit).length !== 0) {
        initialState.edit = Object.assign(initialState.edit, edit);
      }
    }

    if (localStorage.getItem('images')) {
      const images = JSON.parse(localStorage.getItem('images'));

      if (Object.keys(images).length !== 0) {
        initialState.images = Object.assign(initialState.images, images);
      }
    }

    if (cookies.session) {
      initialState.authorization = Object.assign(initialState.authorization, {
        token: cookies.session,
      });
    }

    return initialState;
  } catch (e) {
    return initialState;
  }
};


export default createStore(rootReducer, getInitialState(), composeEnhancers(
  middleware,
));

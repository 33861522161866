import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: ${props => (props.fullheight ? '100%' : 'inherit')};

  min-height: calc(100vh - 130px);

  @media (max-width: 800px) {
    display: block;
  }
`;

export default Wrapper;

import styled from "styled-components";

export const Wrapper = styled.div`
  background: rgba(38, 76, 81, 0.25);
`;

export const Progress = styled.div`
  height: 10px;
  background: #486aff;
  width: ${(props) => (props.progress ? props.progress : "0")}%;
  transition: width 0.2s ease-in-out;
`;

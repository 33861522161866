import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, withProps, lifecycle } from "recompose";

import {
  getProposal as getProposalAction,
  getHomeownerData as getHomeownerDataAction,
  getContractorData as getContractorDataAction,
  inviteUserToProposal as inviteUserToProposalAction,
} from "../../ducks/proposalView";

import ProposalView from "./ProposalView";

const mapStateToProps = (state) => ({
  proposal: state.proposalView.proposal,
  homeowner: state.proposalView.homeowner,
  sendingInvite: state.proposalView.sendingInvite,
  error: state.proposalView.error,
  user: state.authorization.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProposal: getProposalAction,
      getHomeownerData: getHomeownerDataAction,
      getContractorData: getContractorDataAction,
      inviteUserToProposal: inviteUserToProposalAction,
    },
    dispatch
  );

const props = ({ match }) => ({
  forContractor: match.path.indexOf("received") === -1,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props),

  lifecycle({
    componentDidMount() {
      const { getProposal, getHomeownerData, getContractorData, match } =
        this.props;
      getProposal(match.params.id);
      getHomeownerData(match.params.id);
      getContractorData(match.params.id);
    },
    componentWillReceiveProps(nextProps) {
      const { history } = this.props;

      if (nextProps.error) {
        history.replace("/private");
      }
    },
  })
)(ProposalView);

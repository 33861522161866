import styled from "styled-components";
import media, { MEDIUM, SMALL } from "../../style/media-query";

export const Container = styled.div`
  min-width: 100%;
  display: flex;
  padding: 25px 0;

  ${media.lessThan(MEDIUM)`
    min-width: ${(props) => (props.noOptions ? "100%" : "150%")};
  `}

  ${media.lessThan(SMALL)`
    min-width: ${(props) => (props.noOptions ? "100%" : "1000px")};
  `}
`;

export const CTAContainer = styled.div`
  width: 25%;

  ${media.lessThan(MEDIUM)`
    width: ${(props) => (props.fullWidth ? "100%" : "25%")};
  `}
  padding-right: 50px;
  padding-top: 20px;
  padding-left: ${(props) => (props.addPadding ? "50px" : "0")};
  display: flex;
  align-items: center;
`;

export const OptionsContainer = styled.div`
  width: 75%;
  text-align: center;
  border-left: 1px solid #eeeeee;
  display: flex;
  align-items: center;
`;

export const OptionsReel = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 60px;
`;

export const OptionImageWrapper = styled.div`
  width: 30%;
`;

export const OptionImage = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export const Description = styled.span`
  color: var(--text-color-softer);
  font-family: var(--font-family-label);
  font-size: 12px;
`;

export const CostItemCTALink = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  display: flex;
  align-items: center;

  color: #bcbbc1;
`;

export default {
  'planning and permits': `Planning and permits include costs associated with planning, design, and professional services.

Typically related to the planning and design phase of a project, may also include ongoing professional services throughout the project.
`,
  utilities: `Utilities include water, sewer, electricity, gas (including propane and heating oil).

This category is typically used for work involving utility infrastructure or connections.  A rule of thumb is that utility work is usually done upstream of and including the service connections, that is, the water meter, gas meter, electrical meter, etc.
`,
  demolition: `Demolition involves the removal of parts of an existing space as needed based on the proposed scope of work.

Demolition may include waste removal costs, or they can be included in the General category.  Costs associated with temporary precautions, such as safety or site protection, that are directly related to demolition.
`,
  concrete: `Concrete includes both structural and non-structural flatwork and walls.

Includes slabs, floors, sidewalks, and walls, including any prep such as wire mesh and rebar, gravel base, formwork, waterproofing, etc.
`,
  excavation: `Excavation includes digging and trenching, both by machine and by hand.

This category also includes site clearing and prep, backfilling, drainage details, compaction, etc.
`,
  framing: `Framing involves construction and/or modification of structural and non-structural elements of the project.

Structural steelwork, as well as things like exterior sheathing and blocking for cabinets or wall-mounted features.
`,
  exterior: `Exterior is a big category that includes all finishes for the exterior of the project.

Sub-categories include windows, roofing, gutters, decking, exterior doors, etc.  Some framing, as for decks and exterior stairs may be included in this category, or they could be put in Framing and only the finishes for the deck and stairs, such as railings and decking included in this Group.
`,
  plumbing: `Plumbing includes the labor and materials necessary for rough and final plumbing, as well as any gas work.

This includes things like copper and PVC pipe, PEX, consumables, vent ducting, etc.  It does not include the plumbing fixtures themselves.   Hydronic work could be included here or in Mechanical.
`,
  mechanical: `Mechanical includes all HVAC work, including equipment and ductwork.

 Includes venting of water heaters, furnaces, fireplaces, bath fans, etc.
`,
  electrical: `Electrical includes labor and materials for rough and final electrical work, as well as all lighting fixtures and devices.

Also includes low voltage and data wiring and equipment.  Fixtures should be included separately as allowance items with locations specified.
`,
  insulation: `Insulation includes all types of insulation, including thermal and sound.

Also includes insulating hot water heater and pipes, and underslab and foundation wall insulation.
`,
  'wall coverings': `Wall coverings include interior surfaces like drywall and paneling.

Includes materials and installation/finishing and may include multiple layers, for example, if drywall is installed underneath paneling they both fall in this category, even though the drywall is covered up.
`,
  'wall finishes': `Finishes include all painting and staining, both interior and exterior.

Also includes interior wall finishes such as wall paper, venetian plaster or clay, faux paint, etc.
`,
  'interior trim': `Interior trim is a broad Group that includes doors, baseboard and casing, millwork, stair systems and closets.

Includes both materials and installation, some items such as millwork and doors may be allowance items, installation would be included separately.
`,
  bathrooms: `Bathrooms includes all finishes and installation for bathrooms.

Typically costs involving rough plumbing, electrical, HVAC, etc. will be put in their own Groups.
`,
  kitchens: `Kitchens includes all finishes and installation for kitchens, including cabinets, appliances, accessories, etc.

Typically costs involving rough plumbing, electrical, HVAC, etc. will be put in their own Groups, but allowances for plumbing fixtures and appliances will be included in this group.
`,
  flooring: `Flooring includes all types of interior flooring,  including hardwood, tile, vinyl, etc.

Also included are prep and underlayment costs.  Typically there is an allowance for the flooring itself, and a separate installation cost.  Sometimes floor tile for bathroom will be included here, or it can be included in the Bathroom group.
`,
  general: `General includes costs such as rentals, common labor, punchlist, waste removal, etc.

This category can be used for items that don't really fit in a group, but are not really groups themselves.
`,
};

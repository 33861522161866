import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import {
  fetchProposal as fetchProposalAction,
  orderCostGroup as orderCostGroupAction,
  editProposal,
  setField,
  createCostGroup,
  reset,
} from "../../ducks/edit";

import {
  uploadSingleFile,
  removeSingleFile,
  uploadMultipleFiles,
  removeMultipleFiles,
} from "../../ducks/images";

import getImages from "../../selectors/getImages";

import ProposalEdit from "./ProposalEdit";

const mapStateToProps = (state) => ({
  edit: state.edit,
  images: getImages(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setField,
      uploadSingleFile,
      removeSingleFile,
      uploadMultipleFiles,
      removeMultipleFiles,
      createCostGroup,
      fetchProposal: fetchProposalAction,
      orderCostGroup: orderCostGroupAction,
      editProposal,
      reset,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchProposal, match } = this.props;

      fetchProposal(match.params.id);
    },
    componentWillReceiveProps(nextProps) {
      const { history, match } = this.props;

      if (
        !nextProps.edit.fetching &&
        nextProps.edit.edited &&
        match.url.indexOf("edit") !== -1
      ) {
        history.push(`/proposal/${match.params.id}/`);
      }
    },
  })
)(ProposalEdit);

import React from "react";

import { S3_URL } from "../../constants";
import ChoosyLogo from "../../assets/images/choosy_logo.png";

import {
  Wrapper,
  Logo,
  CompanyName,
  ProjectName,
  Address,
} from "./CoverPreview.styled";

const CoverPreview = ({
  mode = "default",
  companyLogo,
  companyName,
  projectName,
  projectAddress,
  showLogo,
}) => (
  <Wrapper mode={mode}>
    {mode !== "view" && (
      <Logo
        src={
          companyLogo
            ? companyLogo.file
              ? `${S3_URL}/${companyLogo.file}`
              : ChoosyLogo
            : ChoosyLogo
        }
      />
    )}
    {mode === "view" && (
      <Logo src={companyLogo ? `${S3_URL}/${companyLogo}` : ChoosyLogo} />
    )}

    {mode === "view" && companyName && showLogo && (
      <CompanyName>{companyName || "Company Name"}</CompanyName>
    )}
    {mode !== "view" && (
      <CompanyName>{companyName || "Company Name"}</CompanyName>
    )}
    {mode === "view" && projectName && (
      <ProjectName>{projectName || "Project Name"}</ProjectName>
    )}
    {mode !== "view" && (
      <ProjectName>{projectName || "Project Name"}</ProjectName>
    )}
    {mode === "view" && projectAddress && (
      <Address>{projectAddress || "City, State Zipcode"}</Address>
    )}
    {mode !== "view" && (
      <Address>{projectAddress || "City, State Zipcode"}</Address>
    )}
  </Wrapper>
);

export default CoverPreview;

/* eslint-disable no-unused-vars */
import CONTRACT from "../constants/contract";
import INTRODUCTORY_NOTE from "../constants/introductoryNote";
import SELECTION_STATES from "../constants/selectionStates";
import { triggerModal } from "./modals";

import hydrateProposal from "../helpers/hydrateProposal";

import { setImages, resetImages } from "./images";

import { setUiState } from "./uiState";

import removeUrlAndKeepAfterCom from "../helpers/removeUrlAndKeepAfterCom";

import {
  KITCHEN_MODULE,
  BATHROOM_MODULE,
  POWDER_ROOM_MODULE,
  kitchenModuleItems,
  bathroomModuleItems,
  powderRoomModuleItems,
  costGroupsData,
} from "../constants/costGroupData";

const formImages = (images) => {
  const formedImages = {};

  Object.keys(images).forEach((key) => {
    if (images[key] !== undefined) {
      formedImages[key] = {
        file: images[key],
        uploading: false,
      };
    }
  });

  return formedImages;
};

const generateCostItem = (
  name = "",
  cost = "",
  costType = "",
  selection = false,
  note = "",
  pristine = true
) => ({
  id: Date.now().toString(36) + Math.random().toString(36).substring(2),
  name,
  cost,
  costType,
  selection,
  note,
  pristine,
});

const generateCostGroup = () => ({
  id: Date.now(),
  name: "",
  note: "",
  pristine: true,
  values: [generateCostItem()],
  images: [],
});

export const defaultEditState = {
  costGroups: [generateCostGroup()],
  contract: CONTRACT,
  introductoryNote: INTRODUCTORY_NOTE,
  proposalId: null,
  fetching: false,
  error: null,
  edited: false,
  date: Date.now(),
  COST_ITEM_ID_FOR_PRODUCT: false,
  COST_GROUP_ID_FOR_PRODUCT: false,
};

export const EDIT_SET_FIELD = "EDIT_SET_FIELD";
export const EDIT_CREATE_COST_GROUP = "EDIT_CREATE_COST_GROUP";
export const EDIT_UPDATE_COST_GROUP_NAME = "EDIT_UPDATE_COST_GROUP_NAME";
export const EDIT_CREATE_COST_ITEM = "EDIT_CREATE_COST_ITEM";
export const EDIT_UPDATE_COST_ITEM_VALUE = "EDIT_UPDATE_COST_ITEM_VALUE";
export const EDIT_REMOVE_COST_GROUP = "EDIT_REMOVE_COST_GROUP";
export const EDIT_REMOVE_COST_GROUP_ITEM = "EDIT_REMOVE_COST_GROUP_ITEM";
export const EDIT_ORDER_COST_GROUP_ITEM = "EDIT_ORDER_COST_GROUP_ITEM";
export const EDIT_ORDER_COST_GROUP = "EDIT_ORDER_COST_GROUP";

export const GET_PROPOSAL_EDIT_START = "GET_PROPOSAL_EDIT_START";
export const GET_PROPOSAL_EDIT_SUCCESS = "GET_PROPOSAL_EDIT_SUCCESS";
export const GET_PROPOSAL_EDIT_ERROR = "GET_PROPOSAL_EDIT_ERROR";

export const START_EDIT_PROPOSAL = "START_EDIT_PROPOSAL";
export const SUCCESS_EDIT_PROPOSAL = "SUCCESS_EDIT_PROPOSAL";
export const ERROR_EDIT_PROPOSAL = "ERROR_EDIT_PROPOSAL";

export const UPDATE_COST_ITEM_OPTIONS = "UPDATE_COST_ITEM_OPTIONS";
export const ITERATE_SELECTION_STATE = "ITERATE_SELECTION_STATE";
export const REMOVE_SELECTION = "REMOVE_SELECTION";
export const EDIT_COST_ITEM_NOTE = "EDIT_COST_ITEM_NOTE";
export const SELECTIONS_CANCEL_FETCHING = "SELECTIONS_CANCEL_FETCHING";
export const CHANGE_SELECTION_ORDER = "CHANGE_SELECTION_ORDER";

export const EDIT_RESET = "EDIT_RESET";

export const SET_COST_ITEM_ID_FOR_PRODUCT = "SET_COST_ITEM_ID_FOR_PRODUCT";

export const SET_COST_GROUP_ID_FOR_PRODUCT = "SET_COST_GROUP_ID_FOR_PRODUCT";

export const POST_COMMENT = "POST_COMMENT";

export const setField = (field, value) => ({
  type: EDIT_SET_FIELD,
  payload: {
    field,
    value,
  },
});

export const createCostGroup = () => (dispatch) => {
  const newCostGroup = generateCostGroup();

  dispatch(setUiState("lastCostGroupId", newCostGroup.id));

  dispatch({
    type: EDIT_CREATE_COST_GROUP,
    payload: {
      ...newCostGroup,
    },
  });
};

export const updateCostGroupValue = (costGroupId, name, value) => ({
  type: EDIT_UPDATE_COST_GROUP_NAME,
  payload: {
    costGroupId,
    name,
    value,
  },
});

export const createCostItem = (costGroupId) => ({
  type: EDIT_CREATE_COST_ITEM,
  payload: {
    costGroupId,
  },
});

export const updateCostItemValue = (costGroupId, costItemId, field, value) => ({
  type: EDIT_UPDATE_COST_ITEM_VALUE,
  payload: {
    costGroupId,
    costItemId,
    field,
    value,
  },
});

export const removeCostGroup = (costGroupId) => ({
  type: EDIT_REMOVE_COST_GROUP,
  payload: {
    costGroupId,
  },
});

export const removeCostGroupItem = (costGroupId, costItemId) => ({
  type: EDIT_REMOVE_COST_GROUP_ITEM,
  payload: {
    costGroupId,
    costItemId,
  },
});

export const orderCostGroupItems = (
  costGroupId,
  sourceIndex,
  destinationIndex
) => ({
  type: EDIT_ORDER_COST_GROUP_ITEM,
  payload: {
    costGroupId,
    sourceIndex,
    destinationIndex,
  },
});

export const orderCostGroup = (sourceIndex, destinationIndex) => ({
  type: EDIT_ORDER_COST_GROUP,
  payload: {
    sourceIndex,
    destinationIndex,
  },
});

export const editProposal =
  (isRedirectNeeded = true) =>
  (dispatch, getState) => {
    dispatch({
      type: START_EDIT_PROPOSAL,
    });

    const { proposalId } = getState().edit;

    const filteredData = hydrateProposal(getState().edit, getState().images);

    fetch(`/api/proposal/${proposalId}`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(filteredData),
    })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.text();
        }

        throw new Error(res);
      })
      .then((data) => {
        const { id } = JSON.parse(data);
        // localStorage.setItem('lastUpdated', `${proposalId}-${edited}`);
        if (isRedirectNeeded) {
          dispatch({
            type: SUCCESS_EDIT_PROPOSAL,
            payload: {
              id,
            },
          });
        }
      })
      .catch((error) =>
        dispatch({
          type: ERROR_EDIT_PROPOSAL,
          payload: {
            error,
          },
        })
      );
  };

export const setCostItemIdForProduct = (COST_ITEM_ID_FOR_PRODUCT) => ({
  type: SET_COST_ITEM_ID_FOR_PRODUCT,
  payload: COST_ITEM_ID_FOR_PRODUCT,
});

export const setCostGroupIdForProduct = (COST_GROUP_ID_FOR_PRODUCT) => ({
  type: SET_COST_GROUP_ID_FOR_PRODUCT,
  payload: COST_GROUP_ID_FOR_PRODUCT,
});

export const updateCostItemOptions =
  (url, costGroupId, _costItemId, manual = false, manualProductData) =>
  async (dispatch, getState) => {
    const productId = Date.now();
    const addedBy = getState().authorization.user._id;

    let costItemId;

    if (manual) {
      costItemId = getState().edit.COST_ITEM_ID_FOR_PRODUCT;
    } else {
      costItemId = _costItemId;
    }

    dispatch({
      type: UPDATE_COST_ITEM_OPTIONS,
      payload: {
        costItemId,
        productId,
        addedBy,
        fetching: true,
      },
    });

    fetch(`/api/products/`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: manual
        ? JSON.stringify({
            costItemId,
            manual,
            manualProductData,
            url: manualProductData.productURL,
          })
        : JSON.stringify({ url }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json();
        }

        dispatch(setCostGroupIdForProduct(costGroupId));
        dispatch(setCostItemIdForProduct(costItemId));
        dispatch(
          triggerModal({
            name: "addManualProduct",
            state: true,
          })
        );

        return false;
      })
      .then((productData) => {
        if (!productData) {
          return dispatch({
            type: SELECTIONS_CANCEL_FETCHING,
            payload: {
              productId,
            },
          });
        }

        if (manual) {
          return dispatch(
            triggerModal({
              name: "addProduct",
              state: false,
            })
          );
        }

        const isDuplicate = getState().edit.costGroups.find((costGroup) => {
          return costGroup.values.find((costItem) => {
            if (costItem.options && costItem.options.length > 0) {
              return costItem.options.find(({ product }) => {
                if (!product) {
                  return false;
                }

                return (
                  costItem._id === costItemId && product._id === productData._id
                );
              });
            }
            return false;
          });
        });

        if (!isDuplicate) {
          dispatch({
            type: UPDATE_COST_ITEM_OPTIONS,
            payload: {
              productData,
              costItemId,
              productId,
              fetching: false,
              success: true,
            },
          });
          return dispatch(editProposal());
        }

        return dispatch({
          type: SELECTIONS_CANCEL_FETCHING,
          payload: {
            productId,
          },
        });
      });
  };

export const showManualProductModal =
  (costGroupId, costItemId) => (dispatch) => {
    dispatch(setCostGroupIdForProduct(costGroupId));
    dispatch(setCostItemIdForProduct(costItemId));
    dispatch(
      triggerModal({
        name: "addManualProduct",
        state: true,
      })
    );
  };

export const updateManualProductModal =
  ({ costGroupId, costItemId, product }) =>
  (dispatch) => {
    const productImage = removeUrlAndKeepAfterCom(product.image);

    dispatch(setCostGroupIdForProduct(costGroupId));
    dispatch(setCostItemIdForProduct(costItemId));
    dispatch(
      setImages({
        ...formImages({
          productImage,
        }),
      })
    );
    dispatch(
      triggerModal({
        name: "addManualProduct",
        state: true,
        fields: {
          id: product?._id,
          productName: product?.title,
          photoURL: product?.image,
          price: product?.price,
          currency: product?.currency,
        },
      })
    );
  };

export const createManualProduct = (product) => async (dispatch, getState) => {
  const addedBy = getState().authorization.user._id;
  const productId = Date.now();
  const { proposalId } = getState().edit;
  const costGroupId = getState().edit.COST_GROUP_ID_FOR_PRODUCT;
  const costItemId = getState().edit.COST_ITEM_ID_FOR_PRODUCT;
  const { productImage } = getState().images;
  const imageUrl = `https://struk-choosy.s3.us-west-1.amazonaws.com/${productImage?.file}`;

  if (!productImage) {
    alert("Please enter image");
    return;
  }

  const prodData = {
    costGroupId,
    costItemId,
    title: product.productName,
    image: imageUrl,
    price: product.price,
    currency: product.currency,
    url: "",
  };

  dispatch({
    type: UPDATE_COST_ITEM_OPTIONS,
    payload: {
      costItemId,
      productId,
      addedBy,
      manualProduct: true,
      fetching: true,
      success: true,
    },
  });

  fetch(`/api/proposal/${proposalId}/addProduct/`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(prodData),
  }).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      const response = res.json();

      dispatch(
        triggerModal({
          name: "addManualProduct",
          state: false,
        })
      );

      response.then((productData) => {
        dispatch({
          type: UPDATE_COST_ITEM_OPTIONS,
          payload: {
            productData,
            costItemId,
            productId,
            addedBy,
            manualProduct: true,
            fetching: false,
            success: true,
          },
        });

        dispatch(resetImages());

        return dispatch(editProposal());
      });
    }
  });
};

export const iterateSelectionState =
  ({ costGroupId, costItemId, productId }) =>
  (dispatch, getState) => {
    const costItem = getState()
      .edit.costGroups.find((costGroup) => costGroup._id === costGroupId)
      .values.find((_costItem) => _costItem.id === costItemId);

    const selection = costItem.options.find(
      (_selection) => _selection.product._id === productId
    );
    const otherSelections = costItem.options.filter(
      (_selection) => _selection.product._id !== productId
    );
    const otherChosenSelections = otherSelections.some(
      (_selection) => _selection.position === SELECTION_STATES.CHOSEN
    );

    let nextPosition = "";

    switch (selection.position) {
      case SELECTION_STATES.NOT_SELECTED:
        nextPosition = SELECTION_STATES.LIKED;
        break;
      case SELECTION_STATES.LIKED:
        nextPosition = SELECTION_STATES.CHOSEN;

        if (otherChosenSelections) {
          otherSelections.forEach((otherSelection) => {
            if (otherSelection.position === SELECTION_STATES.CHOSEN) {
              dispatch({
                type: UPDATE_COST_ITEM_OPTIONS,
                payload: {
                  productData: otherSelection.product,
                  costItemId,
                  position: SELECTION_STATES.LIKED,
                },
              });
            }
          });
        }

        break;
      case SELECTION_STATES.CHOSEN:
        nextPosition = SELECTION_STATES.NOT_SELECTED;
        break;
      default:
        nextPosition = SELECTION_STATES.LIKED;
    }

    dispatch({
      type: UPDATE_COST_ITEM_OPTIONS,
      payload: {
        productData: selection.product,
        costItemId,
        position: nextPosition,
      },
    });

    dispatch(editProposal());
  };

export const removeSelection =
  ({ costGroupId, costItemId, productId }) =>
  (dispatch) => {
    dispatch({
      type: REMOVE_SELECTION,
      payload: {
        costGroupId,
        costItemId,
        productId,
      },
    });

    dispatch(editProposal());
  };

export const updateProduct =
  ({ costItemId, product }) =>
  (dispatch) => {
    dispatch(setCostItemIdForProduct(costItemId));
    dispatch(
      triggerModal({
        name: "addProduct",
        state: true,
        fields: {
          id: product._id,
          productName: product.title,
          productURL: product.url,
          photoURL: product.image,
          price: product.price,
        },
      })
    );
  };

export const changeSelectionOrder =
  ({ costGroupId, costItemId, productId, sourceIndex, destinationIndex }) =>
  (dispatch) => {
    dispatch({
      type: CHANGE_SELECTION_ORDER,
      payload: {
        costGroupId,
        costItemId,
        productId,
        sourceIndex,
        destinationIndex,
      },
    });

    dispatch(editProposal());
  };

export const editCostItemNote =
  (costGroupId, costItemId, costItemNote) => (dispatch) => {
    dispatch({
      type: EDIT_COST_ITEM_NOTE,
      payload: {
        costGroupId,
        costItemId,
        costItemNote,
      },
    });

    dispatch(editProposal());
  };

export const addComment =
  (costGroupId, costItemId, comment) => (dispatch, getState) => {
    const date = new Date();

    dispatch({
      type: POST_COMMENT,
      payload: {
        costGroupId,
        costItemId,
        comment: {
          content: comment,
          createdAt: date.getTime(),
          email: getState().authorization.user.email,
        },
      },
    });

    dispatch(editProposal());
  };

export const fetchProposal = (id /* sync = false */) => async (dispatch) => {
  dispatch({
    type: GET_PROPOSAL_EDIT_START,
    payload: {
      // sync
    },
  });

  const proposal = await fetch(`/api/proposal/${id}`, {
    method: "GET",
  });

  const { personalPhoto, companyLogo, currentCondition, ...rest } =
    await proposal.json();

  const contractor = await fetch(`/api/proposal/contractor/${id}`, {
    method: "GET",
  });

  const { name } = await contractor.json();

  // if (!sync) {
  dispatch(
    setImages({
      ...formImages({
        personalPhoto,
        companyLogo,
      }),
      currentCondition,
    })
  );
  // }

  rest.costGroups =
    rest.costGroups.length === 0
      ? [generateCostGroup()]
      : rest.costGroups.map((costGroup) => {
          costGroup.values.push(generateCostItem());

          return costGroup;
        });

  rest.name = name;

  // let lastClientUpdated = {};
  // const lastServerUpdated = rest.events.edited;

  // if(localStorage.getItem('lastUpdated')) {
  //     const [proposalId, lastUpdated] = localStorage.getItem('lastUpdated').split('-')
  //     lastClientUpdated = {
  //         proposalId,
  //         lastUpdated
  //     }
  // }

  // if (!lastClientUpdated || !sync || id !== lastClientUpdated.proposalId) {
  //     localStorage.setItem('lastUpdated', `${id}-${lastServerUpdated}`);
  //     lastClientUpdated.lastUpdated = lastServerUpdated;
  // }

  // let updateToActual = !getState().edit.initialLoadCompleted;

  // if (lastClientUpdated.lastUpdated != lastServerUpdated && lastServerUpdated !== null && lastServerUpdated !== undefined) {
  //     updateToActual = window.confirm("A more recent version of this proposal is available. Update with the most recent changes?");
  // }

  // let proposalOverride = updateToActual;

  // if (updateToActual == true) {
  //     localStorage.setItem('lastUpdated', `${id}-${lastServerUpdated}`);
  // } else {
  //     if (id == lastClientUpdated.proposalId) {

  //         dispatch(editProposal(false));
  //     } else {
  //         proposalOverride = true
  //     }
  // }

  dispatch({
    type: GET_PROPOSAL_EDIT_SUCCESS,
    payload: {
      proposal: rest,
      // withEdit: proposalOverride
    },
  });
};

export const editProduct =
  (manualProductData) => async (dispatch, getState) => {
    const costItemId = getState().edit.COST_ITEM_ID_FOR_PRODUCT;
    const addedBy = getState().authorization.user._id;

    const { productImage } = getState().images;
    const imageUrl = `https://struk-choosy.s3.us-west-1.amazonaws.com/${productImage?.file}`;

    if (manualProductData.manual && !productImage) {
      alert("Please enter image");
      return;
    }

    dispatch({
      type: UPDATE_COST_ITEM_OPTIONS,
      payload: {
        costItemId,
        productId: manualProductData._id,
        addedBy,
        fetching: true,
        isEdit: true,
      },
    });

    const preprocessManualProductData = {
      ...manualProductData,
      image: imageUrl,
    };

    fetch(`/api/products/${manualProductData._id}`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(
        manualProductData.manual
          ? preprocessManualProductData
          : manualProductData
      ),
    })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json();
        }

        dispatch(
          triggerModal({
            name: "addProduct",
            state: true,
          })
        );

        return false;
      })
      .then((productData) => {
        dispatch(
          triggerModal({
            name: `${
              manualProductData.manual ? "addManualProduct" : "addProduct"
            }`,
            state: false,
          })
        );

        dispatch({
          type: UPDATE_COST_ITEM_OPTIONS,
          payload: {
            productData,
            costItemId,
            productId: manualProductData._id,
            fetching: false,
            success: true,
            isEdit: true,
          },
        });

        if (manualProductData.manual) dispatch(resetImages());
      });
  };

export const reset = () => ({
  type: EDIT_RESET,
});

export default function EditReducer(state = defaultEditState, action) {
  switch (action.type) {
    case EDIT_COST_ITEM_NOTE: {
      const { costGroupId, costItemId, costItemNote } = action.payload;

      const { costGroups, ...rest } = state;

      return {
        ...rest,
        costGroups: costGroups.map((costGroup) => {
          if (costGroup._id === costGroupId) {
            const { values, ...restCostGroup } = costGroup;

            return {
              values: costGroup.values.map((costItem) => {
                if (costItem.id === costItemId) {
                  const { note, ...restCostItem } = costItem;

                  return {
                    note: costItemNote,
                    ...restCostItem,
                  };
                }

                return costItem;
              }),
              ...restCostGroup,
            };
          }

          return costGroup;
        }),
      };
    }
    case POST_COMMENT: {
      const { costGroupId, costItemId, comment } = action.payload;
      const { costGroups, ...rest } = state;

      return {
        ...rest,
        costGroups: costGroups.map((costGroup) => {
          if (costGroup._id === costGroupId) {
            const { values, ...restCostGroup } = costGroup;

            return {
              values: costGroup.values.map((costItem) => {
                if (costItem.id === costItemId) {
                  const { comments, ...restCostItem } = costItem;

                  return {
                    comments: [...comments, comment],
                    ...restCostItem,
                  };
                }

                return costItem;
              }),
              ...restCostGroup,
            };
          }

          return costGroup;
        }),
      };
    }
    case EDIT_SET_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case EDIT_CREATE_COST_GROUP:
      return {
        ...state,
        costGroups: [...state.costGroups, action.payload],
      };
    case EDIT_UPDATE_COST_GROUP_NAME: {
      const costGroup = state.costGroups.find(
        ({ id }) => id === action.payload.costGroupId
      );

      if (
        [KITCHEN_MODULE, BATHROOM_MODULE, POWDER_ROOM_MODULE].includes(
          action.payload.value
        )
      ) {
        costGroup.values = [];
        const costItems =
          action.payload.value === KITCHEN_MODULE
            ? kitchenModuleItems
            : action.payload.value === BATHROOM_MODULE
            ? bathroomModuleItems
            : powderRoomModuleItems;

        costItems.forEach((costItem) => {
          costGroup.values.push(
            generateCostItem(
              costItem.name,
              undefined,
              costItem.costType,
              costItem.selection,
              undefined,
              costItem.pristine
            )
          );
        });
        costGroup.values.push(generateCostItem());
      }

      costGroup[action.payload.name] = action.payload.value;

      if (costGroup.pristine) {
        costGroup.pristine = false;
      }

      return {
        ...state,
        costGroups: state.costGroups,
      };
    }
    case EDIT_CREATE_COST_ITEM: {
      const costGroup = state.costGroups.find(
        ({ id }) => id === action.payload.costGroupId
      );

      costGroup.values.push(generateCostItem());

      return {
        ...state,
        costGroups: state.costGroups,
      };
    }
    case EDIT_UPDATE_COST_ITEM_VALUE: {
      const costGroup = state.costGroups.find(
        ({ id }) => id === action.payload.costGroupId
      );

      const costItem = costGroup.values.find(
        ({ id }) => id === action.payload.costItemId
      );

      costItem[action.payload.field] = action.payload.value;

      if (costItem.pristine) {
        costItem.pristine = false;
      }

      if (costGroup.pristine) {
        costGroup.pristine = false;
      }

      const costGroupItems = costGroupsData[costGroup.name];
      if (costGroupItems) {
        // auto populate cost item row with necessary data
        costGroupItems.map((item) => {
          if (item.name === costItem.name) {
            costItem.costType =
              costItem.costType === "" ? item.costType : costItem.costType;
          }

          return false;
        });
      }

      return {
        ...state,
        costGroups: state.costGroups,
      };
    }
    case EDIT_REMOVE_COST_GROUP:
      return {
        ...state,
        costGroups: state.costGroups.filter(
          ({ id }) => id !== action.payload.costGroupId
        ),
      };
    case EDIT_REMOVE_COST_GROUP_ITEM: {
      return {
        ...state,
        costGroups: state.costGroups.map((costGroup) => {
          if (costGroup.id === action.payload.costGroupId) {
            const { values, ...rest } = costGroup;

            return {
              values: values.filter(
                ({ id }) => id !== action.payload.costItemId
              ),
              ...rest,
            };
          }
          return costGroup;
        }),
      };
    }
    case EDIT_ORDER_COST_GROUP_ITEM: {
      const { costGroupId, destinationIndex, sourceIndex } = action.payload;
      const { costGroups, ...rest } = state;

      return {
        ...rest,
        costGroups: costGroups.map((costGroup) => {
          if (costGroup.id === costGroupId) {
            const { values, ...restCostItem } = costGroup;
            const sortedOptions = Array.from(values);
            const [removed] = sortedOptions.splice(sourceIndex, 1);
            sortedOptions.splice(destinationIndex, 0, removed);
            return {
              ...restCostItem,
              values: sortedOptions,
            };
          }
          return costGroup;
        }),
      };
    }
    case EDIT_ORDER_COST_GROUP: {
      const { destinationIndex, sourceIndex } = action.payload;
      const { costGroups, ...rest } = state;
      const sortedOptions = Array.from(costGroups);
      const [removed] = sortedOptions.splice(sourceIndex, 1);
      sortedOptions.splice(destinationIndex, 0, removed);
      return {
        ...rest,
        costGroups: sortedOptions,
      };
    }
    case START_EDIT_PROPOSAL:
      return {
        ...state,
        error: null,
        fetching: true,
        edited: false,
      };
    case SUCCESS_EDIT_PROPOSAL:
      return {
        ...state,
        // ...defaultEditState,
        fetching: false,
        edited: true,
        // initialLoadCompleted: false,
      };
    case GET_PROPOSAL_EDIT_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    case ERROR_EDIT_PROPOSAL:
      return {
        ...state,
        fetching: false,
        edited: false,
        error: action.payload.error,
      };
    case GET_PROPOSAL_EDIT_START:
      /* const { sync } = action.payload

            const resetState = sync ? {} : defaultEditState */

      return {
        ...state,
        ...defaultEditState,
        error: null,
        fetching: true,
        edited: false,
      };
    case GET_PROPOSAL_EDIT_SUCCESS: {
      // if (action.payload.withEdit) {
      return {
        ...state,
        ...action.payload.proposal,
        error: null,
        // eslint-disable-next-line no-underscore-dangle
        proposalId: action.payload.proposal._id,
        fetching: false,
        // initialLoadCompleted: true
        sectionVisibility: action.payload.proposal.sectionVisibility || {
          cover: true,
          welcome: true,
          bigpicture: true,
          plan: true,
          shoppinglist: true,
          contract: true,
        },
      };
      // }

      /* return {
                    ...state,
                    error: null,
                    // eslint-disable-next-line no-underscore-dangle
                    proposalId: action.payload.proposal._id,
                    fetching: false
                }; */
    }
    case EDIT_RESET: {
      return {
        ...state,
        ...defaultEditState,
        proposalId: null,
        signed: false,
        edited: false,
      };
    }
    case UPDATE_COST_ITEM_OPTIONS: {
      const {
        costItemId,
        productData,
        position,
        fetching,
        addedBy,
        productId,
        success,
        isEdit,
      } = action.payload;

      const costGroupID = state.costGroups.find((costGroups) =>
        costGroups.values.find((item) => item._id === costItemId)
      ).id;

      const newCostGroups = [...state.costGroups];
      const costGroup = newCostGroups.find(({ id }) => id === costGroupID);
      const costItem = costGroup.values.find(
        ({ _id }) => _id === action.payload.costItemId
      );

      const newOption = {
        position,
        productId,
        fetching,
        addedBy,
        success,
      };

      // if we are in the process of fetching, add the new option to the list item options so that the fetching spinner will be displayed
      if (fetching) {
        if (!isEdit) {
          if (Array.isArray(costItem.options) && costItem.options.length > 0) {
            costItem.options = [newOption, ...costItem.options];
          } else {
            costItem.options = [newOption];
          }
        } else {
          costItem.options = costItem.options.map((selection) => {
            if (
              selection.product._id === productId &&
              productId !== undefined
            ) {
              return {
                ...selection,
                productId,
              };
            }

            return selection;
          });
        }
      } else {
        // otherwise, first map so that the selected product is no longer displaying the "fetching" state
        costItem.options = costItem.options.map((selection) => {
          if (selection.productId === productId && productId !== undefined) {
            return {
              ...selection,
              fetching,
              product: productData,
            };
          }

          return selection;
        });

        // then update the position
        costItem.options = costItem.options.map(
          ({ product, position: existingPosition, ...selection }) => {
            if (
              product._id === productData._id &&
              existingPosition !== position
            ) {
              return {
                ...selection,
                product,
                position,
              };
            }

            return {
              ...selection,
              product,
              position: existingPosition,
            };
          }
        );
      }

      costItem.recentlyAddedOption = success;

      return {
        ...state,
        costGroups: newCostGroups,
      };
    }
    case ITERATE_SELECTION_STATE: {
      return state;
    }
    case REMOVE_SELECTION: {
      const { costGroupId, costItemId, productId } = action.payload;

      const { costGroups, ...rest } = state;

      return {
        ...rest,
        costGroups: costGroups.map((costGroup) => {
          if (costGroup._id === costGroupId) {
            const { values, ...restCostGroup } = costGroup;

            return {
              values: costGroup.values.map((costItem) => {
                if (costItem.id === costItemId) {
                  const { options, ...restCostItem } = costItem;

                  return {
                    options: options.filter(
                      (selection) => selection.product._id !== productId
                    ),
                    ...restCostItem,
                  };
                }

                return costItem;
              }),
              ...restCostGroup,
            };
          }

          return costGroup;
        }),
      };
    }
    case SELECTIONS_CANCEL_FETCHING: {
      const { productId } = action.payload;

      return {
        ...state,
        costGroups: state.costGroups.map(({ values, ...restValues }) => ({
          ...restValues,
          values: values.map(({ options, ...restOptions }) => {
            return {
              ...restOptions,
              options: options
                ? options.filter(
                    ({ productId: iProductId, fetching }) =>
                      iProductId !== productId && !fetching
                  )
                : options,
            };
          }),
        })),
      };
    }
    case CHANGE_SELECTION_ORDER: {
      const { costGroupId, costItemId, destinationIndex, sourceIndex } =
        action.payload;

      const { costGroups, ...rest } = state;

      return {
        ...rest,
        costGroups: costGroups.map((costGroup) => {
          if (costGroup._id === costGroupId) {
            const { values, ...restCostGroup } = costGroup;

            return {
              values: costGroup.values.map((costItem) => {
                if (costItem.id === costItemId) {
                  const { options, ...restCostItem } = costItem;

                  const sortedOptions = Array.from(options);
                  const [removed] = sortedOptions.splice(sourceIndex, 1);
                  sortedOptions.splice(destinationIndex, 0, removed);

                  return {
                    options: sortedOptions,
                    ...restCostItem,
                  };
                }

                return costItem;
              }),
              ...restCostGroup,
            };
          }

          return costGroup;
        }),
      };
    }
    case SET_COST_ITEM_ID_FOR_PRODUCT:
      return {
        ...state,
        COST_ITEM_ID_FOR_PRODUCT: action.payload,
      };
    case SET_COST_GROUP_ID_FOR_PRODUCT:
      return {
        ...state,
        COST_GROUP_ID_FOR_PRODUCT: action.payload,
      };
    default:
      return state;
  }
}

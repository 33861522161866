import { getProposals } from './dashboard';

const DUPLICATE_START = 'DUPLICATE_START';
const DUPLICATE_ERROR = 'DUPLICATE_ERROR';
const DUPLICATE_SUCCESS = 'DUPLICATE_SUCCESS';

const DELETE_START = 'DELETE_START';
const DELETE_ERROR = 'DELETE_ERROR';
const DELETE_SUCCESS = 'DELETE_SUCCESS';

const REASSIGN_START = 'REASSIGN_START';
const REASSIGN_ERROR = 'REASSIGN_ERROR';
const REASSIGN_SUCCESS = 'REASSIGN_SUCCESS';

const TOGGLE_PROPOSAL_VISIBILITY_START = 'TOGGLE_PROPOSAL_VISIBILITY_START';
const TOGGLE_PROPOSAL_VISIBILITY_ERROR = 'TOGGLE_PROPOSAL_VISIBILITY_ERROR';
const TOGGLE_PROPOSAL_VISIBILITY_SUCCESS = 'TOGGLE_PROPOSAL_VISIBILITY_SUCCESS';

export const duplicateProposal = id => (dispatch) => {
  dispatch({
    type: DUPLICATE_START,
    payload: {
      proposalId: id,
    },
  });

  fetch('/api/proposal/duplicate', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      id,
    }),
  }).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.text();
    }

    throw new Error(res);
  })
    .then(() => {
      dispatch({
        type: DUPLICATE_SUCCESS,
      });
      dispatch(getProposals());
    })
    .catch(error => dispatch({
      type: DUPLICATE_ERROR,
      payload: {
        error,
      },
    }));
};

export const deleteProposal = id => (dispatch) => {
  dispatch({
    type: DELETE_START,
    payload: {
      proposalId: id,
    },
  });

  fetch('/api/proposal/delete', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      id,
    }),
  }).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.text();
    }

    throw new Error(res);
  })
    .then(() => {
      dispatch({
        type: DELETE_SUCCESS,
      });
      dispatch(getProposals());
    })
    .catch(error => dispatch({
      type: DELETE_ERROR,
      payload: {
        error,
      },
    }));
};

export const reassignProposal = (id, newOwner, createNew = false) => (dispatch) => {
  dispatch({
    type: REASSIGN_START,
    payload: {
      proposalId: id,
      createNew,
    },
  });

  fetch('/api/proposal/reassign', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      id,
      newOwner,
      createNew,
    }),
  }).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.text();
    }

    throw new Error(res);
  })
    .then((res) => {
      dispatch({
        type: REASSIGN_SUCCESS,
        payload: {
          res,
        },
      });
      dispatch(getProposals());
    })
    .catch(error => dispatch({
      type: REASSIGN_ERROR,
      payload: {
        error,
      },
    }));
};

export const toggleProposalVisibility = id => (dispatch) => {
  dispatch({
    type: TOGGLE_PROPOSAL_VISIBILITY_START,
    payload: {
      proposalId: id,
    },
  });

  fetch('/api/proposal/toggleVisibility', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      id,
    }),
  }).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.text();
    }

    throw new Error(res);
  })
    .then((res) => {
      dispatch({
        type: TOGGLE_PROPOSAL_VISIBILITY_SUCCESS,
        payload: {
          res,
        },
      });
      dispatch(getProposals());
    })
    .catch(error => dispatch({
      type: TOGGLE_PROPOSAL_VISIBILITY_ERROR,
      payload: {
        error,
      },
    }));
};

export default function ManageProposalReducer (state = {
  fetching: false,
  res: null,
  createNew: false,
  proposalId: null,
  duplicated: false,
  deleted: false,
  reassign: false,
  toggleVisibility: false,
  error: null,
}, action) {
  switch (action.type) {
    case DUPLICATE_START:
    case DELETE_START:
    case REASSIGN_START:
    case TOGGLE_PROPOSAL_VISIBILITY_START:
      return {
        ...state,
        res: null,
        fetching: true,
        createNew: action.payload.createNew,
        proposalId: action.payload.id,
        duplicated: false,
        deleted: false,
        reassign: false,
        toggleVisibility: false,
        error: null,
      };
    case DUPLICATE_ERROR:
    case DELETE_ERROR:
    case REASSIGN_ERROR:
    case TOGGLE_PROPOSAL_VISIBILITY_ERROR:
    {
      return {
        ...state,
        fetching: false,
        res: null,
        duplicated: false,
        deleted: false,
        reassign: false,
        toggleVisibility: false,
        error: action.payload.error,
      };
    }
    case DUPLICATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        duplicated: true,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        fetching: false,
        deleted: true,
      };
    case REASSIGN_SUCCESS:
      return {
        ...state,
        res: action.payload.res,
        fetching: false,
        reassign: true,
      };
    case TOGGLE_PROPOSAL_VISIBILITY_SUCCESS:
      return {
        ...state,
        res: action.payload.res,
        fetching: false,
        toggleVisibility: true,
      };
    default:
      return state;
  }
}

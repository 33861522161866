const removeUrlAndKeepAfterCom = (inputString) => {
  const urlRegex = /https?:\/\/[^\s]*\.com\/(.*)/;
  const match = inputString.match(urlRegex);

  if (match && match[1]) {
    return match[1].trim();
  }
  return inputString;
};

export default removeUrlAndKeepAfterCom;

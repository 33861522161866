import React from 'react';
import moment from 'moment';

import { S3_URL } from '../../constants';
import analytics from '../../helpers/analytics';
import {
  YellowSignCTA,
} from '../../constants/analyticEvents';

import ProposalViewWrapper from '../../elements/ProposalViewWrapper/ProposalViewWrapper';
import Headline from '../../elements/Headline/Headline';
import Spacer from '../../elements/Spacer/Spacer';

import {
  SignaturesWrapper,
  SignatureBlock,
  SignComponent,
  SignAction,
  SignatureImage,
  Heading,
  Name,
  Date,
} from './ProposalSignatures.styled';

const ProposalSignatures = ({
  user,
  proposal,
  homeowner,
  contractor,
  goToSignProposal,
}) => {
  if (!proposal && !user && !homeowner) {
    return null;
  }

  const { signedByHomeowner, signedByContractor, sent } = proposal.events;

  const signEnabledForHomeowner = !signedByHomeowner && user.role === 'homeowner';
  const signEnabledForContractor = !signedByContractor && user.role === 'contractor';
  const contractorSharedProposal = !!sent;

  const HomeownerSignature = () => {
    if (signedByHomeowner) {
      return <SignatureImage src={`${S3_URL}/${proposal.homeownerSignature}`} />;
    }

    if (user.role === 'homeowner') {
      return <SignAction>Click to sign</SignAction>;
    }

    return <SignAction />;
  };
  const ContractorsSignature = () => {
    if (signedByContractor) {
      return <SignatureImage src={`${S3_URL}/${proposal.contractorsSignature}`} />;
    }

    if (user.role === 'contractor') {
      return <SignAction>Click to sign</SignAction>;
    }

    return <SignAction />;
  };

  return (
    <ProposalViewWrapper verticalAlignTop horizontalAlignLeft id="sign">
      <Headline>Sign Below</Headline>

      <SignaturesWrapper>
        <SignatureBlock>
          <Heading>Homeowner</Heading>
          <Spacer height="10" />
          <SignComponent
            active={signEnabledForHomeowner}
            onClick={() => {
              if (signEnabledForHomeowner) {
                analytics.track({
                  event: YellowSignCTA,
                });

                goToSignProposal();
              }
            }}
          >
            <HomeownerSignature />
          </SignComponent>
          <Spacer height="10" />
          <Name>{homeowner.name || 'Client'}</Name>
          <Date>{signedByHomeowner ? moment(signedByHomeowner).format('MMM D, YYYY') : moment(moment.now()).format('MMM D, YYYY')}</Date>
        </SignatureBlock>
        <SignatureBlock>
          <Heading>Contractor</Heading>
          <Spacer height="10" />
          <SignComponent
            active={signEnabledForContractor}
            onClick={() => {
              if (signedByContractor) {
                return null;
              }

              if (contractorSharedProposal && signEnabledForContractor) {
                goToSignProposal();
                analytics.track({
                  event: YellowSignCTA,
                });
              } else {
                alert('Before signing proposal - share it with the homeowner.');
              }

              return null;
            }}
          >
            <ContractorsSignature />
          </SignComponent>
          <Spacer height="10" />
          <Name>{contractor.name || 'Contractor'}</Name>
          <Date>{signedByContractor ? moment(signedByContractor).format('MMM D, YYYY') : moment(moment.now()).format('MMM D, YYYY')}</Date>
        </SignatureBlock>
      </SignaturesWrapper>

    </ProposalViewWrapper>
  );
};


export default ProposalSignatures;

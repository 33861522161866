import styled from 'styled-components';
import { Link } from 'react-router-dom';

import checkIconImage from '../../assets/images/check-icon.png';

export const ModalWrapper = styled.div`
  position: sticky;
  top: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  max-width: 90%;
  border-radius: 28px;
  background-color: #ffffff;
  padding: 20px 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ModalText = styled.h1``;

export const CheckIcon = styled.div`
  background-image: url(${checkIconImage});
  width: 95px;
  height: 95px;
  margin: 1.5rem auto;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalButton = styled(Link)`
  margin: 1.5rem 0;
  padding: 0.5em 1.2em 0.6em;
  font-family: var(--font-family-label);
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  border: 0.2em solid transparent;
  border-radius: 2em;
  cursor: pointer;
  color: var(--text-color);
  background-color: transparent;
  border-color: var(--highlight-color);
`;

const GET_LOG_START = 'GET_LOG_START';
const GET_LOG_SUCCESS = 'GET_LOG_SUCCESS';
const GET_LOG_ERROR = 'GET_LOG_ERROR';

export const getLog = proposalId => (dispatch) => {
  dispatch({
    type: GET_LOG_START,
  });

  fetch('/api/proposal/log', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      proposalId,
    }),
  }).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }

    throw new Error(res);
  })
    .then((res) => {
      dispatch({
        type: GET_LOG_SUCCESS,
        payload: {
          res,
        },
      });
    })
    .catch(error => dispatch({
      type: GET_LOG_ERROR,
      payload: {
        error,
      },
    }));
};

export default function ProposalLogError (state = {
  fetching: false,
  error: false,
  data: null,
}, action) {
  switch (action.type) {
    case GET_LOG_START:
      return {
        ...state,
        fetching: true,
        error: false,
        data: null,
      };
    case GET_LOG_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        data: action.payload.res,
      };
    case GET_LOG_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

import styled from 'styled-components';

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.align ? props.align : 'center')};
  height: ${props => (props.fullheight ? '100%' : 'inherit')};
  background: #fff;
  position: relative;

  @media (min-width: 800px) {
    width: ${props => {
      if (props.fullwidth) {
        return '100%'
      }
      if (props.nearFullWidth) {
        return '90%'
      }
      
        return '800px'
      
    }
  }
`;

export default Holder;

import * as Sentry from "@sentry/browser";
// import { client } from '../helpers/analytics';

export const defaultAuthorizationState = {
  fetching: false,
  error: null,
  authorizationComplete: false,
  email: null,
  user: {},
  authorized: false,
  state: "initial",
};

const START_SIGN_UP = "START_SIGN_UP";
const SUCCESS_SIGN_UP = "SUCCESS_SIGN_UP";
const ERROR_SIGN_UP = "ERROR_SIGN_UP";

const START_SIGN_IN = "START_SIGN_IN";
const SUCCESS_SIGN_IN = "SUCCESS_SIGN_IN";
const ERROR_SIGN_IN = "ERROR_SIGN_IN";

const START_AUTHORIZE = "START_AUTHORIZE";
export const SUCCESS_AUTHORIZE = "SUCCESS_AUTHORIZE";
export const ERROR_AUTHORIZE = "ERROR_AUTHORIZE";

const START_LOGOUT = "START_LOGOUT";
export const SUCCESS_LOGOUT = "SUCCESS_LOGOUT";
const ERROR_LOGOUT = "ERROR_LOGOUT";

const AUTHORIZATION_RESET = "AUTHORIZATION_RESET";

export const reset = () => ({
  type: AUTHORIZATION_RESET,
});

export const signIn = (user) => (dispatch) => {
  dispatch({
    type: START_SIGN_IN,
    payload: {
      email: user.email,
    },
  });

  fetch("/api/authorization/signin", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      email: user.email.toLocaleLowerCase(),
    }),
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return;
      }

      if (res.status === 404) {
        throw new Error("Not found");
      }

      if (res.status === 401) {
        throw new Error("User with this email was not found");
      }

      throw new Error(res);
    })
    .then(() => {
      dispatch({
        type: SUCCESS_SIGN_IN,
      });
    })
    .catch((error) => {
      return dispatch({
        type: ERROR_SIGN_IN,
        payload: {
          error,
        },
      });
    });
};

export const signUp = (user) => (dispatch) => {
  const payload = {
    email: user.email.toLocaleLowerCase(),
    name: user.name,
    phoneNumber: user.phoneNumber,
    companyName: user.companyName,
    role: "contractor",
  };

  dispatch({
    type: START_SIGN_UP,
    payload,
  });

  fetch("/api/authorization/signup", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return;
      }
      if (res.status === 400) {
        throw new Error("Email is already associated with another account");
      }
      throw new Error(res);
    })
    .then(() => {
      dispatch({
        type: SUCCESS_SIGN_UP,
      });
    })
    .catch((error) => {
      if (error.message === "400") {
        return dispatch(signIn({ email: user.email }));
      }

      return dispatch({
        type: ERROR_SIGN_UP,
        payload: {
          error,
        },
      });
    });
};

export const authorize = (token) => (dispatch) => {
  dispatch({
    type: START_AUTHORIZE,
  });

  fetch("/api/authorization/verify/", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ token }),
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }

      throw new Error(res.status);
    })
    .then((user) => {
      // client.identify({
      //   userId: user._id,
      //   traits: {
      //     email: user.email,
      //     name: user.name,
      //     role: user.role,
      //   },
      // });

      Sentry.setUser({ email: user.email, id: user._id });

      dispatch({
        type: SUCCESS_AUTHORIZE,
        payload: {
          user,
        },
      });
    })
    .catch((e) => {
      if (e.message === "403") {
        dispatch({
          type: ERROR_AUTHORIZE,
        });
      }
    });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: START_LOGOUT,
  });

  fetch("/api/authorization/logout/", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return;
      }

      throw new Error(res.status);
    })
    .then(() => {
      dispatch({
        type: SUCCESS_LOGOUT,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOGOUT,
      });
    });
};

export default function AuthorizationReducer(
  state = defaultAuthorizationState,
  action
) {
  switch (action.type) {
    case AUTHORIZATION_RESET:
      return {
        ...state,
        email: null,
        fetching: false,
        error: null,
        authorizationComplete: false,
      };
    case START_LOGOUT:
      return {
        fetching: true,
        error: null,
      };
    case START_SIGN_UP:
    case START_SIGN_IN:
      return {
        ...state,
        fetching: true,
        error: null,
        authorizationComplete: false,
        email: action.payload.email,
      };
    case START_AUTHORIZE:
      return {
        ...state,
        fetching: true,
        error: null,
        authorized: false,
        user: {},
      };
    case SUCCESS_SIGN_UP:
      return {
        ...state,
        fetching: false,
        authorizationComplete: true,
      };
    case SUCCESS_SIGN_IN:
      return {
        ...state,
        fetching: false,
        authorizationComplete: true,
      };
    case SUCCESS_AUTHORIZE:
      return {
        ...state,
        fetching: false,
        authorized: true,
        user: action.payload.user,
        state: "initialized",
      };
    case ERROR_SIGN_UP:
    case ERROR_SIGN_IN:
      return {
        ...state,
        error: action.payload.error.message,
      };
    case ERROR_AUTHORIZE: {
      return {
        ...state,
        error: 403,
        fetching: false,
        state: "initialized",
      };
    }
    case SUCCESS_LOGOUT:
      return {
        ...defaultAuthorizationState,
        state: "initialized",
      };
    default:
      return state;
  }
}

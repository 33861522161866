
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { logout as logoutAction } from '../../ducks/authorization';

const mapStateToProps = state => ({
  authorization: state.authorization,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAction,
}, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const { logout, authorization } = this.props;

      if (!authorization.fetching) {
        logout();
      }
    },
  }),
)(() => null);

import React from "react";

import PreviewWrapper from "./Contract.styled";

import SectionToggle from "../../components/SectionToggle/SectionToggle";

const Contract = ({ create, setField }) => (
  <section id="step_6_contract" className="proposal_step contract">
    <div className="container">
      <div className="row">
        <div className="proposal_preview_edit">
          <div id="preview">
            <PreviewWrapper>
              {create.contract.split("\n").map((sentance, index) => (
                <p key={index}>{sentance}</p>
              ))}
            </PreviewWrapper>
          </div>
          <div className="phone_bg" />
        </div>
        <div className="proposal_content">
          <h1>Contract</h1>
          <SectionToggle
            create={create}
            setField={setField}
            section="contract"
          />
          <form className="form">
            <p>
              <label>Edit the contract or paste-in your own</label>
              <textarea
                className="contract"
                onChange={(e) => setField("contract", e.target.value)}
                defaultValue={create.contract}
              />
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
);

export default Contract;

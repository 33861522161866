import React, { useState } from "react";
import Icon from "../../elements/Icon/Icon";
import ProposalControl from "../../composables/ProposalControl";

import {
  Wrapper,
  ListItem,
  ListHeader,
  ComponentWrapper,
  IconHolder,
  ItemData,
  Name,
  Arrow,
  ActionsWrapper,
} from "./NavigationIconList.styled";

const NavigationIconList = ({
  children = () => {},
  accordeon = false,
  expandByDefault = false,
  expandMapping = false,
  onCollapse,
  onExpand,
  onCollapseAll = () => {},
  onExpandAll = () => {},
  component,
  items,
  iconSize = "40",
  padding,
  onItemCick = () => {},
  mapIcon = () => {},
  getName = () => {},
  getHelpText = () => {},
  noborder = false,
  itemStyles = false,
  arrowCustomStyle = "",
  showIcon = true,
  customItemComponents = null,
}) => {
  const [accordeonesStates, setAccordeonStates] = useState(
    expandMapping
      ? items.map((value, key) => expandMapping(value, key))
      : items.map(() => expandByDefault)
  );
  const [allCollapsed, setAllCollapsed] = useState(true);

  if (accordeonesStates.length < items.length) {
    setAccordeonStates([
      ...accordeonesStates,
      ...Array(items.length - accordeonesStates.length).fill(false),
    ]);
  }

  const toggleAll = () => {
    if (allCollapsed) {
      setAccordeonStates(accordeonesStates.map(() => true));
      setAllCollapsed(false);
      onCollapseAll();
    } else {
      setAccordeonStates(accordeonesStates.map(() => false));
      setAllCollapsed(true);
      onExpandAll();
    }
  };

  const toggleAccordeon = (index) => () => {
    const isOpened = accordeonesStates[index];
    const newState = accordeonesStates.map((value, i) =>
      i === index ? !value : value
    );

    if (isOpened) {
      onCollapse();
    } else {
      onExpand();
    }

    if (newState.some((state) => state === true)) {
      setAllCollapsed(false);
    } else {
      setAllCollapsed(true);
    }

    setAccordeonStates(newState);
  };

  const blueArrow = "#486aff";

  if (accordeon) {
    const accordeonChildren = items.map((item, index) => (
      <ListItem key={item.id} noborder={noborder} itemStyles={itemStyles}>
        <ListHeader onClick={toggleAccordeon(index)}>
          {showIcon && (
            <IconHolder>
              <Icon name={mapIcon(item)} size={iconSize} />
            </IconHolder>
          )}
          <ItemData>
            <Name>{getName(item)}</Name>
            {getHelpText(item)}
          </ItemData>
          <Arrow
            arrowCustomStyle={arrowCustomStyle}
            accordeon
            opened={accordeonesStates[index]}
          >
            <Icon name="arrow-right" color={blueArrow} />
            mue
          </Arrow>
        </ListHeader>
        <ComponentWrapper pose={accordeonesStates[index] ? "open" : "closed"}>
          {component(item, index)}
        </ComponentWrapper>
      </ListItem>
    ));

    return (
      <Wrapper padding={padding}>
        {children(allCollapsed, toggleAll, accordeonChildren)}
        {items.map((item, index) => (
          <ListItem key={item.id} noborder={noborder} itemStyles={itemStyles}>
            <ListHeader onClick={toggleAccordeon(index)}>
              {showIcon && (
                <IconHolder>
                  <Icon name={mapIcon(item)} size={iconSize} />
                </IconHolder>
              )}
              <ItemData>
                <Name>{getName(item)}</Name>
                {getHelpText(item)}
              </ItemData>
              {customItemComponents
                ? customItemComponents(item, accordeonesStates[index])
                : null}
              <Arrow
                arrowCustomStyle={arrowCustomStyle}
                accordeon
                opened={accordeonesStates[index]}
              >
                <Icon name="arrow-right" color={blueArrow} />
              </Arrow>
            </ListHeader>
            <ComponentWrapper
              pose={accordeonesStates[index] ? "open" : "closed"}
            >
              {component(item, index)}
            </ComponentWrapper>
          </ListItem>
        ))}
      </Wrapper>
    );
  }

  return (
    <Wrapper padding={padding}>
      {items.map((item) => (
        <ListItem
          key={JSON.stringify(item)}
          noborder={noborder}
          onClick={(e) => {
            e.stopPropagation();
            onItemCick(item);
          }}
          itemStyles={itemStyles}
        >
          <ListHeader>
            <IconHolder>
              <Icon name={mapIcon(item)} size={iconSize} />
            </IconHolder>
            <ItemData>
              <Name>{getName(item)}</Name>
              {getHelpText(item)}
            </ItemData>
            <ActionsWrapper>
              <ProposalControl proposal={item} />
              <Arrow arrowCustomStyle={arrowCustomStyle}>
                <Icon name="arrow-right" color={blueArrow} />
              </Arrow>
            </ActionsWrapper>
          </ListHeader>
        </ListItem>
      ))}
    </Wrapper>
  );
};

export default React.memo(NavigationIconList);

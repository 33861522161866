import React from "react";

import Spacer from "../../elements/Spacer/Spacer";

import FAQComponent from "../../components/FAQ/FAQ";
import LandingNavigation from "../../components/LandingNavigation/LandingNavigation";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Media from "../../components/Media/Media";

import { Wrapper, Section } from "../Home/Home.styled";

const FAQ = () => (
  <Wrapper>
    <ScrollToTop />
    <LandingNavigation inverse />
    <Media medium>
      <Spacer height="50" />
    </Media>
    <Section>
      <FAQComponent />
    </Section>
  </Wrapper>
);

export default FAQ;

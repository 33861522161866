import React from "react";
import mime from "mime-types";
import PulseLoader from "react-spinners/PulseLoader";

import ExifImage from "../ExifImage/ExifImage";
import DocumentLink from "../DocumentLink/DocumentLink";

import { S3_URL } from "../../constants";
import { allowedDocumentFiles } from "../../constants/mime-types";
import addPhotoImage from "../../assets/images/add-photo.png";

import {
  Wrapper,
  ImageList,
  ImageItem,
  DeleteIcon,
  LoadingPlaceholder,
} from "./ImageReel.styled";

const ImageReel = ({
  items = [],
  onDeleteClick = null,
  onImageClick = () => {},
  showLastItemPlaceholder = false,
  padding = 0,
}) => (
  <Wrapper>
    <ImageList padding={padding}>
      {items.map((image) => {
        if (!image) {
          return null;
        }

        const encodedFileName = encodeURIComponent(image.file);
        const url = `${S3_URL}/${encodedFileName}`;

        let FileComponent = null;

        if (allowedDocumentFiles.indexOf(mime.lookup(image.file)) !== -1) {
          const extension =
            mime.extension(mime.lookup(image.file)) === "pdf" ? "pdf" : "doc";

          FileComponent = (
            <DocumentLink
              type={extension}
              url={url}
              onClick={() => onImageClick({ ...image, url })}
            />
          );
        } else {
          FileComponent = (
            <ExifImage
              src={url}
              onClick={() => onImageClick({ ...image, url })}
            />
          );
        }

        return image.uploading ? (
          <LoadingPlaceholder key={image.id}>
            <PulseLoader size="10px" margin="5px" color="#486AFF" />
            {image.originalName}
          </LoadingPlaceholder>
        ) : (
          <ImageItem key={image.id}>
            {FileComponent}
            {onDeleteClick !== null && (
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteClick(image);
                }}
              />
            )}
          </ImageItem>
        );
      })}
      {showLastItemPlaceholder && (
        <ImageItem>
          <ExifImage src={addPhotoImage} />
        </ImageItem>
      )}
    </ImageList>
  </Wrapper>
);

export default ImageReel;

// import Analytics from 'analytics-node';

// export const client = new Analytics('p2hbeMjsDpJQskAEaprRYh8YvOgyhNcC');

let anonymousId = Date.now();

if (localStorage.getItem('authorization') === null) {
  if (localStorage.getItem('sb-ANALYTICS') === null) {
    localStorage.setItem('sb-ANALYTICS', anonymousId);
  } else {
    anonymousId = localStorage.getItem('sb-ANALYTICS');
  }

  // client.identify({
  //   anonymousId,
  // });
}

export const generateAnalyticsPrefix = (event) => {
  if (window.location.href.indexOf('edit') !== -1) {
    return `Editor: ${event}`;
  }

  if (window.location.href.indexOf('create') !== -1) {
    return `Creator: ${event}`;
  }

  return event;
};

export default {
  track: () => {
    const identificationObject = {};

    if (localStorage.getItem('authorization') !== null) {
      identificationObject.userId = JSON.parse(localStorage.getItem('authorization'))._id;
    } else {
      identificationObject.anonymousId = anonymousId;
    }

    // client.track({
    //   ...identificationObject,
    //   ...data,
    // });
  },
};

import React, { Fragment } from "react";

import CoverView from "../../components/CoverView/CoverView";
import WelcomeView from "../../components/WelcomeView/WelcomeView";
import BigPictureView from "../../components/BigPictureView/BigPictureView";
import PlanView from "../../components/PlanView/PlanView";
import ShoppingListView from "../../components/ShoppingListView/ShoppingListView";
import ContractView from "../../components/ContractView/ContractView";
import BudgetView from "../../components/BudgetView/BudgetView";

import ProposalSignatures from "../../composables/ProposalSignatures";

import { Proposal } from "./ProposalView.styled";

const ProposalView = ({
  proposal,
  history,
  homeowner,
  forContractor,
  inviteUserToProposal,
  sendingInvite,
  user,
}) => {
  const isContractor = user.role === "contractor";

  if (proposal === null) {
    return null;
  }
  return (
    <Fragment>
      <Proposal>
        {proposal.sectionVisibility.cover && <CoverView proposal={proposal} />}
        {proposal.sectionVisibility.welcome && (
          <WelcomeView
            proposal={proposal}
            to={forContractor ? homeowner.name : "me"}
          />
        )}
        {proposal.sectionVisibility.bigpicture && (
          <BigPictureView
            proposal={proposal}
            inviteUserToProposal={inviteUserToProposal}
            sendingInvite={sendingInvite}
            isContractor={isContractor}
          />
        )}
        {proposal.sectionVisibility.plan && (
          <PlanView proposal={proposal} history={history} />
        )}
        {proposal.sectionVisibility.shoppinglist && (
          <ShoppingListView proposal={proposal} />
        )}
        {proposal.sectionVisibility.shoppinglist && (
          <BudgetView proposal={proposal} />
        )}
        {proposal.sectionVisibility.contract && (
          <ContractView proposal={proposal} />
        )}
        {proposal.sectionVisibility.contract && <ProposalSignatures />}
      </Proposal>
    </Fragment>
  );
};

export default ProposalView;

import styled from "styled-components";

export const CostHeading = styled.div`
  font-family: var(--font-family-label);
  font-size: 1.2rem;
  color: var(--text-color-softer);
`;

export const Cost = styled.div`
  margin: 0.2rem 0 2rem;
  font-family: var(--font-family-monospace);
  font-size: 1.5rem;
`;

export const DetailedViewHeader = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AccordeonControl = styled.span`
  color: #486aff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;

  @media print {
    display: none;
  }
`;

import styled from 'styled-components';

export const ItemContainer = styled.div`
  width: 100%;
  display: grid;
  border: 1px solid #EEEEEE;
  padding: 40px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
`;

export const ItemImage = styled.img`
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
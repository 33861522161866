export default `1. All work shall be completed in a workman-like manner and in compliance with all building codes and other applicable laws.

2. The contractor shall furnish a plan and scale drawing showing the shape, size dimensions, and construction and equipment specifications for home improvements, a description of the work to be done and description of the materials to be used and the equipment to be used or installed, and the agreed consideration for the work.

3. To the extent required by law all work shall be performed by individuals duly licensed and authorized by law to perform said work.

4. Contractor may at its discretion engage subcontractors to perform work hereunder, provided. Contractor shall fully pay said subcontractor and in all instances remain responsible for the proper completion of this Contract.

5. Contractor shall furnish Owner appropriate releases or waivers of lien for all work performed or materials provided at the time the next periodic payment shall be due.

6. All change orders shall be in writing and signed both by Owner and Contractor, and shall be incorporated in, and become a part of the contract.

7. Contractor warrants it is adequately insured for injury to its employees and others incurring loss or injury as a result of the acts of Contractor or its employees or subcontractors.

8. Contractor shall at its own expense obtain all permits necessary for the work to be performed.

9. Contractor agrees to remove all debris and leave the premises in broom clean condition.

10. In the event Owner shall fail to pay any periodic or installment payment due hereunder. Contractor may cease work without breach pending payment or resolution of any dispute.

11. All disputes hereunder shall be resolved by binding arbitration in accordance with rules of the American Arbitration Association.

12. Contractor shall not be liable for any delay due to circumstances beyond its control including strikes, casualty or general unavailability of materials.

13. Contractor warrants all work for a period of 24 months following completion.`;

/* eslint-disable no-unused-vars */
import React from "react";
import moment from "moment";
import PulseLoader from "react-spinners/PulseLoader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import analytics from "../../helpers/analytics";
import { SelectionsExternalProductPageOpened } from "../../constants/analyticEvents";

import Spacer from "../../elements/Spacer/Spacer";
import Icon from "../../elements/Icon/Icon";
import Link from "../../elements/Link/Link";
import UserAvatar from "../UserAvatar/UserAvatar";

import CostItemCTA from "../CostItemCTA/CostItemCTA";

import {
  Wrapper,
  SpinnerWrapper,
  SelectionItem,
  Header,
  SelectionLabelWrapper,
  SelectionLabel,
  ChoisePositionLabel,
  SelectionLink,
  SelectionImage,
  BottomPanel,
  DraggingZone,
  RemoveSelectionButton,
  NoCurrency,
} from "./SelectionReel.styled";

const SelectionReel = ({
  items = [],
  item = null,
  removeSelection,
  updateProduct,
  changeSelectionOrder,
  addProductAsOption,
  updateManualProductModal,
}) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    changeSelectionOrder(
      result.draggableId,
      result.source.index,
      result.destination.index
    );
  };

  const getEditLink = (value) => {
    return (
      <SelectionLink
        onClick={
          value.product.manual
            ? updateManualProductModal(value.product)
            : updateProduct(value.product)
        }
        color="#486AFF"
      >
        Edit
      </SelectionLink>
    );
  };

  const generateChoicePrefix = (index) => {
    if (index === items.length - 1 && items.length > 1) {
      return "Last";
    }

    if (index === 0) {
      return "First";
    }

    return moment.localeData().ordinal(index + 1);
  };

  const noCurrency = (price) => (
    <NoCurrency>
      <a className="info_tooltip left small">
        i{" "}
        <span className="tooltip_content small">
          We could not get the price currency. This is a placeholder. Visit the
          website for more details.
        </span>
      </a>
      <span className="default_currency">$</span>
      {price}{" "}
    </NoCurrency>
  );

  const formatCommonCurrencyPrice = (price, currencyCode) => {
    const currencySymbols = {
      EUR: "€",
      GBP: "£",
      USD: "$",
    };
    const currencySymbol = currencySymbols[currencyCode];

    if (currencyCode === null) return noCurrency(price);

    return ["GBP", "USD"].includes(currencyCode)
      ? `${currencySymbol}${price}`
      : currencyCode === "EUR"
      ? `${price}${currencySymbol} `
      : `${price}${currencyCode} `;
  };

  const getSelection = (value, index) => {
    if (value.fetching) {
      return (
        <SelectionItem key={value?.productId}>
          <Header>
            {/*
        Temporarily removed the like button
        <SelectionButton position={value.position}/>
        */}
            <SelectionLabelWrapper>
              <SelectionLabel />
            </SelectionLabelWrapper>
            <Icon
              name="small-close"
              size="17"
              customStyle={`
          cursor: pointer;
        `}
            />
          </Header>
          <SpinnerWrapper>
            <PulseLoader size="10px" margin="5px" color="#486AFF" />
          </SpinnerWrapper>
        </SelectionItem>
      );
    }
    return (
      <Draggable
        isDragDisabled={items.length === 1}
        key={value?.product?._id || value.productId}
        draggableId={value?.product?._id || value.productId}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <SelectionItem isDragging={snapshot.isDragging}>
              <Header>
                {/*
                      Temporarily removed the like button
                      <SelectionButton onClick={iterateSelectionState(value.product._id, value.position)} position={value.position}/>
                      */}
                <SelectionLabelWrapper>
                  <RemoveSelectionButton>
                    <Icon
                      name="small-close"
                      size="17"
                      onClick={removeSelection(value.product?._id)}
                      customStyle={`
                        cursor: pointer;
                      `}
                    />
                  </RemoveSelectionButton>

                  <SelectionLabel title={value.product?.title}>
                    <Link.Html
                      onClick={() => {
                        analytics.track({
                          event: SelectionsExternalProductPageOpened,
                        });
                      }}
                      href={value.product?.url}
                    >
                      {value.product?.title}
                    </Link.Html>
                  </SelectionLabel>
                  <ChoisePositionLabel>
                    {generateChoicePrefix(index)} Choice
                  </ChoisePositionLabel>
                </SelectionLabelWrapper>
              </Header>
              <Spacer height="13" />
              <Link.Html
                onClick={() => {
                  analytics.track({
                    event: SelectionsExternalProductPageOpened,
                  });
                }}
                href={value.product?.url}
                customStyle={`
                      line-height: inherit;
                    `}
              >
                <SelectionImage src={value.product?.image} />
              </Link.Html>
              <Spacer height="10" />
              <BottomPanel>
                <SelectionLink>
                  <Link.Html
                    onClick={() => {
                      analytics.track({
                        event: SelectionsExternalProductPageOpened,
                      });
                    }}
                    href={value.product?.url}
                  >
                    {value.product?.price && (
                      <>
                        {formatCommonCurrencyPrice(
                          value.product?.price,
                          value.product?.currency
                        )}
                        {!value.product.manual && "at "}
                      </>
                    )}
                    {value.product?.hostname}
                  </Link.Html>
                </SelectionLink>
                {getEditLink(value)}
                {value.product?.createdBy?.name ||
                value.product?.createdBy?.email ? (
                  <UserAvatar
                    size="36"
                    name={
                      value.product?.createdBy?.name ||
                      value.product?.createdBy?.email
                    }
                  />
                ) : (
                  // this div will keep the same card height if the avatar is missing
                  <div style={{ width: "36px", height: "36px" }} />
                )}
              </BottomPanel>
            </SelectionItem>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <DraggingZone
              ref={provided.innerRef}
              isDragging={snapshot.isDraggingOver}
              {...provided.droppableProps}
            >
              {items.map((value, index) => {
                return getSelection(value, index);
              })}
              {provided.placeholder}
              <CostItemCTA
                item={item}
                addProductAsOption={addProductAsOption}
                updateProduct={updateProduct}
                addPadding={items.length > 0}
                selections={items}
              />
            </DraggingZone>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
};

export default SelectionReel;

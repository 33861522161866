import numeral from 'numeral';

const FormattedNumber = ({ number }) => {
  if (Number.isNaN(number)) {
    return null;
  }

  const formatted = numeral(number).format('$0,0.00');

  return formatted;
};

export default FormattedNumber;

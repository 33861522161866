import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const Wrapper = styled.div`
  display: inline-block;
  opacity: ${props => (props.show ? '1' : '0')};
  width: 20px;
  height: 25px;
  margin-left: 10px;
  transition: opacity .2s ease;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 2px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Spinner = ({ show }) => (
  <Wrapper show={show}>
    <div />
    <div />
    <div />
    <div />
  </Wrapper>
);

export default Spinner;

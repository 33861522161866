import styled from 'styled-components';

export const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 2;

  @media print {
    display: none;
  }
`;

export const Holder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  padding: 15px 20px 40px 20px;

  background: #FFFFFF;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

export const Trimmer = styled.div`
  width: 100%;

  @media (min-width: 800px) {
    width: 800px;
  }

  display: flex;
  justify-content: space-between;

  & > * {
    margin: 0 2.5px;

    &:first-child {
      margin-left: 0
    }

    &:last-child {
      margin-right: 0
    }
  }
`;

export const Left = styled.div`
  flex: 4;
`;

export const Center = styled.div`
  flex: 4;
`;

export const Right = styled.div`
  flex: 1;
`;
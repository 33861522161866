import {useEffect, useState} from 'react';

import {MEDIUM, breakpoints} from '../../style/media-query';

const Media = ({medium, small, children}) => {
  const [ width, setWidth ] = useState(window.innerWidth)

  useEffect(() => {
    const handleScroll = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleScroll)

    return () => {
      window.removeEventListener('resize', handleScroll)
    }
  })

  const mediumValue = Number(breakpoints[MEDIUM].replace('px', ''));

  if((medium && width<=mediumValue) || (small && width>mediumValue)) {
    return null
  }

  return children;
}

export default Media;
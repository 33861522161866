
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import SignedProposal from './SignedProposal';

const mapStateToProps = state => ({
  companyName: '.' || state.proposalView.proposal.companyName,
});

const props = ({
  history,
}) => ({
  goToDashboard: () => {
    history.replace('/dashboard');
  },
});

export default compose(
  connect(
    mapStateToProps,
  ),
  withProps(props),
)(SignedProposal);



import { connect } from 'react-redux';

import ShoppingList from './ShoppingList';

const mapStateToProps = (state, props) => ({
  list: state[props.mode].costGroups.length > 0 ? state[props.mode].costGroups
    .map(costGroup => costGroup.values.filter(value => value.selection))
    .reduce((a, b) => [...a, ...b]) : [],
});

export default connect(
  mapStateToProps,
)(ShoppingList);

import { useEffect } from 'react';

const HideHubSpotHost = () => {
  useEffect(() => {

    const $hs = document.querySelector('#hubspot-messages-iframe-container');

    if($hs) {
      if (window.location.pathname.indexOf('proposal') !== -1) {
        document.querySelector('#hubspot-messages-iframe-container').classList.add('hidden');
      } else {
        document.querySelector('#hubspot-messages-iframe-container').classList.remove('hidden');
      }
    }
});

  return null;
};

export default HideHubSpotHost;


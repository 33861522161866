export const KITCHEN_MODULE = "Kitchen Module";
export const BATHROOM_MODULE = "Bathroom Module";
export const POWDER_ROOM_MODULE = "Powder Room Module";

export const costGroupsItems = [
  "Planning and Permits",
  "Utilities",
  "Demolition",
  "Concrete",
  "Excavation",
  "Framing",
  "Exterior",
  "Plumbing",
  "Mechanical",
  "Electrical",
  "Insulation",
  "Wall Coverings",
  "Wall Finishes",
  "Interior trim",
  "Bathrooms",
  "Kitchens",
  "Flooring",
  "Landscaping",
  "General",
  "General Conditions",
  "Kitchen Module",
  "Bathroom Module",
  "Powder Room Module",
];

export const costTypes = [
  { value: 1, name: "Labor" },
  { value: 2, name: "Materials" },
  { value: 3, name: "Labor and materials" },
  { value: 4, name: "Allowance" },
  { value: 5, name: "Professional services" },
  { value: 6, name: "Uncategorized" },
];

export const planningAndPermitsItems = [
  { name: "Architecture", costType: "5" },
  { name: "Drafting and Design", costType: "5" },
  { name: "Engineering", costType: "5" },
  { name: "Hazardous materials testing", costType: "5" },
  { name: "Permit fees", costType: "5" },
  { name: "Preconstruction Services", costType: "5" },
  { name: "Surveys and Professional Services", costType: "5" },
];

export const utilitiesItems = [
  { name: "Electrical service repair/upgrade", costType: "3" },
  { name: "Gas", costType: "3" },
  { name: "Sewer line repair/upgrade", costType: "3" },
  { name: "Solar thermal", costType: "3" },
  { name: "Solar voltaic", costType: "3" },
  { name: "Utilities labor", costType: "1" },
  { name: "Utilities materials", costType: "2" },
  { name: "Utility labor and materials", costType: "3" },
  { name: "Water meter repair/upgrade", costType: "3" },
];

export const demolitionItems = [
  { name: "Concrete cutting", costType: "1" },
  { name: "Concrete demo", costType: "1" },
  { name: "Demolition labor", costType: "1" },
  { name: "Demolition labor and materials", costType: "3" },
  { name: "Demolition materials", costType: "2" },
  { name: "Hazardous materials removal and remediation", costType: "3" },
  { name: "Remove floor", costType: "1" },
  { name: "Remove roof", costType: "1" },
  { name: "Remove walls", costType: "1" },
  { name: "Site protection", costType: "3" },
  { name: "Tree removal", costType: "1" },
];

export const concreteItems = [
  { name: "Concrete labor", costType: "1" },
  { name: "Concrete materials", costType: "3" },
  { name: "Concrete labor and materials", costType: "2" },
  { name: "Foundation walls", costType: "3" },
  { name: "Foundation footers", costType: "3" },
  { name: "Pads", costType: "3" },
  { name: "Piers", costType: "3" },
  { name: "Grade beams", costType: "3" },
  { name: "Structural slab", costType: "3" },
  { name: "Flatwork", costType: "3" },
  { name: "Special", costType: "3" },
];

export const excavationItems = [
  { name: "Backfill and compact", costType: "3" },
  { name: "Dig foundation", costType: "3" },
  { name: "Dig other", costType: "3" },
  { name: "Excavation labor", costType: "1" },
  { name: "Excavation labor and materials", costType: "3" },
  { name: "Excavation materials", costType: "2" },
  { name: "Foundation waterproofing", costType: "3" },
  { name: "Grading and drainage", costType: "3" },
  { name: "Import/export material", costType: "3" },
  { name: "Site prep", costType: "3" },
];

export const framingItems = [
  { name: "Deck framing", costType: "2" },
  { name: "Floor framing", costType: "2" },
  { name: "Framing labor", costType: "1" },
  { name: "Framing labor and materials", costType: "3" },
  { name: "Framing materials", costType: "2" },
  { name: "Roof framing", costType: "2" },
  { name: "Special", costType: "3" },
  { name: "Structural details", costType: "2" },
  { name: "Wall framing", costType: "2" },
];

export const exteriorItems = [
  { name: "Decks", costType: "2" },
  { name: "Entries", costType: "2" },
  { name: "Exterior doors", costType: "4" },
  { name: "Exterior labor", costType: "1" },
  { name: "Exterior labor and materials", costType: "3" },
  { name: "Exterior materials", costType: "2" },
  { name: "Exterior siding, trim and fascia", costType: "4" },
  { name: "Exterior stairs and landings", costType: "4" },
  { name: "Fences and gates", costType: "2" },
  { name: "Gutters", costType: "3" },
  { name: "Roofing", costType: "3" },
  { name: "Special", costType: "3" },
  { name: "Windows and skylights", costType: "4" },
];

export const plumbingItems = [
  { name: "Final plumbing", costType: "3" },
  { name: "Hydronics", costType: "3" },
  { name: "Plumbing labor", costType: "1" },
  { name: "Plumbing labor and materials", costType: "3" },
  { name: "Plumbing materials", costType: "2" },
  { name: "Rough plumbing", costType: "3" },
  { name: "Special", costType: "3" },
  { name: "Water heater", costType: "4" },
];

export const mechanicalItems = [
  { name: "Air conditioning", costType: "3" },
  { name: "ERV", costType: "2" },
  { name: "Fireplace", costType: "4" },
  { name: "Forced air furnace", costType: "3" },
  { name: "Heat Pump system", costType: "3" },
  { name: "Humidifier", costType: "3" },
  { name: "Mechanical labor and materials", costType: "1" },
  { name: "Mechanical labor", costType: "3" },
  { name: "Mechanical materials", costType: "2" },
  { name: "Special", costType: "3" },
  { name: "Ventilation and ductwork", costType: "3" },
];

export const electricalItems = [
  { name: "Electrical devices", costType: "4" },
  { name: "Electrical labor", costType: "1" },
  { name: "Electrical labor and materials", costType: "3" },
  { name: "Electrical materials", costType: "2" },
  { name: "Final electrical", costType: "3" },
  { name: "Light fixtures", costType: "4" },
  { name: "Outdoor light fixtures", costType: "4" },
  { name: "Recessed cans", costType: "4" },
  { name: "Rough electrical", costType: "3" },
  { name: "Special", costType: "3" },
  { name: "Undercabinet lighting", costType: "4" },
];

export const insulationItems = [
  { name: "Blown in cellulose insulation", costType: "3" },
  { name: "Fiberglass batt insulation", costType: "3" },
  { name: "Foundation insulation", costType: "3" },
  { name: "Insulation labor and materials", costType: "1" },
  { name: "Insulation labor", costType: "3" },
  { name: "Insulation materials", costType: "2" },
  { name: "Rigid insulation", costType: "2" },
  { name: "Special", costType: "" },
  { name: "Spray insulation", costType: "3" },
  { name: "Underslab insulation", costType: "3" },
  { name: "Water pipe insulation", costType: "3" },
];

export const wallCoveringsItems = [
  { name: "Drywall", costType: "3" },
  { name: "Paneling", costType: "2" },
  { name: "Wall coverings labor", costType: "1" },
  { name: "Wall coverings labor and materials", costType: "3" },
  { name: "Wall coverings materials", costType: "2" },
];

export const wallFinishesItems = [
  { name: "Exterior painting and staining", costType: "3" },
  { name: "Faux finishes", costType: "4" },
  { name: "Finishes labor", costType: "1" },
  { name: "Finishes labor and material", costType: "3" },
  { name: "Finishes material", costType: "2" },
  { name: "Interior painting and staining", costType: "3" },
  { name: "Special", costType: "3" },
  { name: "Venetian plasters", costType: "4" },
  { name: "Wallpaper", costType: "4" },
];

export const interiorTrimItems = [
  { name: "Baseboard", costType: "4" },
  { name: "Built-ins", costType: "4" },
  { name: "Cabinetry and casework", costType: "4" },
  { name: "Casing", costType: "4" },
  { name: "Closets", costType: "4" },
  { name: "Installation", costType: "1" },
  { name: "Interior doors", costType: "4" },
  { name: "Interior trim labor", costType: "1" },
  { name: "Interior trim labor and materials", costType: "3" },
  { name: "Interior trim materials", costType: "2" },
  { name: "Paneling", costType: "4" },
  { name: "Railings", costType: "4" },
  { name: "Stairs", costType: "4" },
];

export const bathroomModuleItems = [
  {
    name: "Bathroom accent grout",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom accent tile",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom accessories",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom backsplash grout",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom backsplash tile",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom exhaust fan",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom floor tile",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom floor tile grout",
    costType: "4",
    selection: true,
    pristine: false,
  },
  { name: "Bathroom labor", costType: "1", selection: false, pristine: false },
  {
    name: "Bathroom labor and materials",
    costType: "3",
    selection: false,
    pristine: false,
  },
  {
    name: "Bathroom materials",
    costType: "2",
    selection: false,
    pristine: false,
  },
  { name: "Bathroom mirror", costType: "4", selection: true, pristine: false },
  { name: "Bathroom sink", costType: "4", selection: true, pristine: false },
  {
    name: "Bathroom sink faucet",
    costType: "4",
    selection: true,
    pristine: false,
  },
  { name: "Bathroom vanity", costType: "4", selection: true, pristine: false },
  {
    name: "Bathroom vanity lights",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom vanity top",
    costType: "4",
    selection: true,
    pristine: false,
  },
  { name: "Bathtub", costType: "4", selection: true, pristine: false },
  { name: "Heated floor", costType: "4", selection: true, pristine: false },
  { name: "Installation", costType: "4", selection: false, pristine: false },
  { name: "Shower enclosure", costType: "4", selection: true, pristine: false },
  {
    name: "Shower floor grout",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Shower floor tile",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Shower wall grout",
    costType: "4",
    selection: true,
    pristine: false,
  },
  { name: "Shower wall tile", costType: "4", selection: true, pristine: false },
  {
    name: "Tile installation",
    costType: "3",
    selection: false,
    pristine: false,
  },
  { name: "Toilet", costType: "4", selection: true, pristine: false },
  { name: "Toilet seat", costType: "4", selection: true, pristine: false },
  { name: "Tub/shower valve", costType: "4", selection: true, pristine: false },
  {
    name: "Tub/shower valve trim",
    costType: "4",
    selection: true,
    pristine: false,
  },
];

export const kitchenModuleItems = [
  {
    name: "Appliance installation",
    costType: "3",
    selection: false,
    pristine: false,
  },
  {
    name: "Countertop installation",
    costType: "1",
    selection: false,
    pristine: false,
  },
  { name: "Dishwasher", costType: "4", selection: true, pristine: false },
  { name: "Garbage disposal", costType: "4", selection: true, pristine: false },
  { name: "Instahot", costType: "4", selection: true, pristine: false },
  {
    name: "Kitchen backsplash",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Kitchen backsplash grout",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Kitchen cabinet installation",
    costType: "1",
    selection: false,
    pristine: false,
  },
  { name: "Kitchen cabinets", costType: "4", selection: true, pristine: false },
  {
    name: "Kitchen countertops",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Kitchen hardware/cabinet pulls",
    costType: "4",
    selection: true,
    pristine: false,
  },
  { name: "Kitchen island", costType: "4", selection: true, pristine: false },
  {
    name: "Kitchen island countertop",
    costType: "4",
    selection: true,
    pristine: false,
  },
  { name: "Kitchen labor", costType: "1", selection: false, pristine: false },
  {
    name: "Kitchen labor and materials",
    costType: "3",
    selection: false,
    pristine: false,
  },
  {
    name: "Kitchen materials",
    costType: "2",
    selection: false,
    pristine: false,
  },
  { name: "Kitchen Range", costType: "4", selection: true, pristine: false },
  { name: "Kitchen rangetop", costType: "4", selection: true, pristine: false },
  { name: "Kitchen shelving", costType: "4", selection: true, pristine: false },
  { name: "Kitchen sink", costType: "4", selection: true, pristine: false },
  {
    name: "Kitchen sink accessories",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Kitchen sink faucet",
    costType: "4",
    selection: true,
    pristine: false,
  },
  {
    name: "Kitchen water filter faucet",
    costType: "4",
    selection: true,
    pristine: false,
  },
  { name: "Microwave", costType: "4", selection: true, pristine: false },
  { name: "Range vent hood", costType: "4", selection: true, pristine: false },
  {
    name: "Refridgerator/freezer",
    costType: "4",
    selection: true,
    pristine: false,
  },
  { name: "Special", costType: "3", selection: false, pristine: false },
  { name: "Steam oven", costType: "4", selection: true, pristine: false },
  { name: "Toaster oven", costType: "4", selection: true, pristine: false },
  {
    name: "Undercabinet lighting",
    costType: "4",
    selection: true,
    pristine: false,
  },
  { name: "Wall oven", costType: "4", selection: true, pristine: false },
  { name: "Wine cooler", costType: "4", selection: true, pristine: false },
];

export const powderRoomModuleItems = [
  {
    name: "Bathroom accent grout",
    costType: "",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom accent tile",
    costType: "",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom accessories",
    costType: "",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom backsplash grout",
    costType: "",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom backsplash tile",
    costType: "",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom exhaust fan",
    costType: "",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom floor tile",
    costType: "",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom floor tile grout",
    costType: "",
    selection: true,
    pristine: false,
  },
  { name: "Bathroom labor", costType: "", selection: false, pristine: false },
  {
    name: "Bathroom labor and materials",
    costType: "",
    selection: false,
    pristine: false,
  },
  {
    name: "Bathroom materials",
    costType: "",
    selection: false,
    pristine: false,
  },
  { name: "Bathroom mirror", costType: "", selection: true, pristine: false },
  { name: "Bathroom sink", costType: "", selection: true, pristine: false },
  {
    name: "Bathroom sink faucet",
    costType: "",
    selection: true,
    pristine: false,
  },
  { name: "Bathroom vanity", costType: "", selection: true, pristine: false },
  {
    name: "Bathroom vanity lights",
    costType: "",
    selection: true,
    pristine: false,
  },
  {
    name: "Bathroom vanity top",
    costType: "",
    selection: true,
    pristine: false,
  },
  { name: "Heated floor", costType: "", selection: true, pristine: false },
  { name: "Installation", costType: "", selection: false, pristine: false },
  {
    name: "Tile installation",
    costType: "",
    selection: false,
    pristine: false,
  },
  { name: "Toilet", costType: "", selection: true, pristine: false },
  { name: "Toilet seat", costType: "", selection: true, pristine: false },
];

export const flooringItems = [
  { name: "Carpet", costType: "4" },
  { name: "Flooring labor", costType: "1" },
  { name: "Flooring labor and materials", costType: "3" },
  { name: "Flooring materials", costType: "2" },
  { name: "Hardwood flooring", costType: "4" },
  { name: "Linoleum/marmoleum flooring", costType: "4" },
  { name: "Special", costType: "2" },
  { name: "Tile flooring", costType: "4" },
];

export const landscapingItems = [
  { name: "BBQ", costType: "4" },
  { name: "Fences, gates and stairs", costType: "2" },
  { name: "Hardscapes", costType: "2" },
  { name: "Hot Tub", costType: "3" },
  { name: "Irrigation", costType: "3" },
  { name: "Landscaping labor", costType: "1" },
  { name: "Landscaping labor and materials", costType: "3" },
  { name: "Landscaping materials", costType: "2" },
  { name: "Outdoor fridge", costType: "4" },
  { name: "Outdoor Kitchen", costType: "3" },
  { name: "Outdoor kitchen accessories", costType: "4" },
  { name: "Outdoor Lighting", costType: "2" },
  { name: "Plants", costType: "2" },
  { name: "Pool", costType: "3" },
  { name: "Sauna", costType: "4" },
];

export const generalItems = [
  { name: "Labor", costType: "" },
  { name: "Materials", costType: "" },
  { name: "Labor and Materials", costType: "" },
  { name: "Dumpsters", costType: "" },
  { name: "Site maintenance", costType: "" },
  { name: "Port-o-potty", costType: "" },
  { name: "Rentals", costType: "" },
  { name: "Common labor", costType: "" },
  { name: "Extra credit", costType: "" },
];

export const customItems = [
  { name: "Bathrooms", costType: "" },
  { name: "Concrete", costType: "" },
  { name: "Demolition", costType: "" },
  { name: "Electrical", costType: "" },
  { name: "Excavation", costType: "" },
  { name: "Exterior", costType: "" },
  { name: "Framing", costType: "" },
  { name: "General", costType: "" },
  { name: "Insulation", costType: "" },
  { name: "Interior trim", costType: "" },
  { name: "Kitchens", costType: "" },
  { name: "Mechanical", costType: "" },
  { name: "Planning and permits", costType: "" },
  { name: "Plumbing", costType: "" },
  { name: "Utilities", costType: "" },
  { name: "Wall coverings", costType: "" },
  { name: "Wall finishes", costType: "" },
  { name: "Architecture", costType: "" },
  { name: "Draughting", costType: "" },
  { name: "Engineering", costType: "" },
  { name: "Hazardous materials testing", costType: "" },
  { name: "Permit fees", costType: "" },
  { name: "Labor", costType: "" },
  { name: "Materials", costType: "" },
  { name: "Labor and Materials", costType: "" },
  { name: "Electrical service repair and upgrade", costType: "" },
  { name: "Water meter repair and upgrade", costType: "" },
  { name: "Sewer line repair and upgrade", costType: "" },
  { name: "General demo", costType: "" },
  { name: "Site protection", costType: "" },
  { name: "Tree removal", costType: "" },
  { name: "Hazardous materials removal and remediation", costType: "" },
  { name: "Remove walls", costType: "" },
  { name: "Remove roof", costType: "" },
  { name: "Remove floor", costType: "" },
  { name: "Concrete demo", costType: "" },
  { name: "Concrete cutting", costType: "" },
  { name: "Foundation walls", costType: "" },
  { name: "Foundation footers", costType: "" },
  { name: "Piers", costType: "" },
  { name: "Grade beams", costType: "" },
  { name: "Flatwork", costType: "" },
  { name: "Special", costType: "" },
  { name: "Site prep", costType: "" },
  { name: "Import/export material", costType: "" },
  { name: "Dig foundation", costType: "" },
  { name: "Dig other", costType: "" },
  { name: "Backfill and compact", costType: "" },
  { name: "Grading and drainage", costType: "" },
  { name: "Foundation waterproofing", costType: "" },
  { name: "General framing", costType: "" },
  { name: "Structural details", costType: "" },
  { name: "Deck framing", costType: "" },
  { name: "Roof framing", costType: "" },
  { name: "Floor framing", costType: "" },
  { name: "Wall framing", costType: "" },
  { name: "Modify opening", costType: "" },
  { name: "Rough plumbing", costType: "" },
  { name: "Final plumbing", costType: "" },
  { name: "Water heater", costType: "" },
  { name: "Hydronics", costType: "" },
  { name: "Heating and cooling", costType: "" },
  { name: "Ventilation and ductwork", costType: "" },
  { name: "ERV", costType: "" },
  { name: "Humidifier", costType: "" },
  { name: "Fireplace", costType: "" },
  { name: "Rough electrical", costType: "" },
  { name: "Final electrical", costType: "" },
  { name: "Recessed lighting", costType: "" },
  { name: "Undercabinet lighting", costType: "" },
  { name: "Fixtures", costType: "" },
  { name: "Devices", costType: "" },
  { name: "Outdoor lighting", costType: "" },
  { name: "Outdoor lighting fixtures", costType: "" },
  { name: "Fiberglass batt insulation", costType: "" },
  { name: "Blown in cellulose insulation", costType: "" },
  { name: "Rigid insulation", costType: "" },
  { name: "Spray insulation", costType: "" },
  { name: "Water pipe insulation", costType: "" },
  { name: "Foundation insulation", costType: "" },
  { name: "Underslab insulation", costType: "" },
  { name: "Interior painting and staining", costType: "" },
  { name: "Exterior painting and staining", costType: "" },
  { name: "Wallpaper", costType: "" },
  { name: "Venetian plasters", costType: "" },
  { name: "Drywall", costType: "" },
  { name: "Paneling", costType: "" },
  { name: "Tile underlayment", costType: "" },
  { name: "Plaster", costType: "" },
  { name: "Interior doors", costType: "" },
  { name: "Baseboard", costType: "" },
  { name: "Casing", costType: "" },
  { name: "Stairs", costType: "" },
  { name: "Railings", costType: "" },
  { name: "Built-ins", costType: "" },
  { name: "Closets", costType: "" },
  { name: "Cabinetry and casework", costType: "" },
  { name: "Tiles", costType: "" },
  { name: "Tile installation", costType: "" },
  { name: "Sink", costType: "" },
  { name: "Sink faucet", costType: "" },
  { name: "Toilet", costType: "" },
  { name: "Vanity", costType: "" },
  { name: "Vanity top", costType: "" },
  { name: "Tub", costType: "" },
  { name: "Tub/shower valve", costType: "" },
  { name: "Tub/shower valve trim", costType: "" },
  { name: "Mirror", costType: "" },
  { name: "Shower enclosure", costType: "" },
  { name: "Heated floor", costType: "" },
  { name: "Accessories", costType: "" },
  { name: "Exhaust fan", costType: "" },
  { name: "Cabinets", costType: "" },
  { name: "Hardware/cabinet pulls", costType: "" },
  { name: "Countertops", costType: "" },
  { name: "Shelving", costType: "" },
  { name: "Island", costType: "" },
  { name: "Island top", costType: "" },
  { name: "Backsplash", costType: "" },
  { name: "Kitchen sink", costType: "" },
  { name: "Kitchen sink faucet", costType: "" },
  { name: "Kitchen sink accessories", costType: "" },
  { name: "Garbage disposal", costType: "" },
  { name: "Microwave", costType: "" },
  { name: "Dishwasher", costType: "" },
  { name: "Range/rangetop", costType: "" },
  { name: "Wall oven", costType: "" },
  { name: "Refridgerator/freezer", costType: "" },
  { name: "Wine cooler", costType: "" },
  { name: "Range vent hood", costType: "" },
  { name: "Appliance installation", costType: "" },
  { name: "Flooring", costType: "" },
  { name: "Hardwood floors", costType: "" },
  { name: "Carpet", costType: "" },
  { name: "Linoleum/mMrmoleum/Vinyl", costType: "" },
  { name: "Tile", costType: "" },
  { name: "Dumpsters", costType: "" },
  { name: "Site maintenance", costType: "" },
  { name: "Port-o-potty", costType: "" },
  { name: "Rentals", costType: "" },
  { name: "Common labor", costType: "" },
  { name: "Extra credit", costType: "" },
  { name: "Punchlist", costType: "" },
  { name: "Choosy Fee", costType: "" },
  { name: "Project Management", costType: "" },
  { name: "Insurance", costType: "" },
  { name: "GC Fee", costType: "" },
  { name: "Overhead and Profit", costType: "" },
  { name: "Supervision", costType: "" },
];

export const generalConditionsItems = [
  { name: "Common labor", costType: "1" },
  { name: "Dumpsters", costType: "2" },
  { name: "GC Fee", costType: "4" },
  { name: "General conditions", costType: "4" },
  { name: "General conditions labor", costType: "1" },
  { name: "General conditions labor and materials", costType: "3" },
  { name: "General conditions materials", costType: "2" },
  { name: "Insurance", costType: "4" },
  { name: "Port-o-potty", costType: "2" },
  { name: "Project Management", costType: "4" },
  { name: "Rentals", costType: "2" },
  { name: "Site maintenance", costType: "1" },
  { name: "Supervision", costType: "4" },
];

export const costGroupsData = {
  "Planning and Permits": planningAndPermitsItems,
  Utilities: utilitiesItems,
  Demolition: demolitionItems,
  Concrete: concreteItems,
  Excavation: excavationItems,
  Framing: framingItems,
  Exterior: exteriorItems,
  Plumbing: plumbingItems,
  Mechanical: mechanicalItems,
  Electrical: electricalItems,
  Insulation: insulationItems,
  "Wall Coverings": wallCoveringsItems,
  "Wall Finishes": wallFinishesItems,
  "Interior trim": interiorTrimItems,
  Bathrooms: bathroomModuleItems,
  Kitchens: kitchenModuleItems,
  Flooring: flooringItems,
  Landscaping: landscapingItems,
  General: generalItems,
  "General Conditions": generalConditionsItems,
  "Kitchen Module": kitchenModuleItems,
  "Bathroom Module": bathroomModuleItems,
  "Powder Room Module": powderRoomModuleItems,
};

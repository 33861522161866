import React from "react";

import PlanNote from "../PlanNote/PlanNote";

import FormattedNumber from "../FormattedNumber/FormattedNumber";

import ProposalViewWrapper from "../../elements/ProposalViewWrapper/ProposalViewWrapper";
import ProposalViewHeader from "../../elements/ProposalViewHeader/ProposalViewHeader";

import { BudgetTable, BudgetItem, BudgetLabel } from "./BudgetView.styled";

const sum = (items) =>
  items.length > 0 ? items.map(({ cost }) => cost).reduce((a, b) => a + b) : 0;

const BudgetView = ({ proposal }) => {
  const costItemsFromCostGroup = proposal.costGroups.map((costGroup) =>
    costGroup.values.length > 0
      ? costGroup.values.map((costItem) => costItem)
      : []
  );

  const costItems =
    proposal.costGroups.length > 0
      ? costItemsFromCostGroup.reduce((a, b) => {
          return [...a, ...b];
        })
      : [];

  if (costItems.length === 0) {
    return null;
  }

  const laborCostType = sum(
    costItems.filter(({ costType }) => costType === "1")
  );
  const materialsCostType = sum(
    costItems.filter(({ costType }) => costType === "2")
  );
  const laborAndMaterialsCostType = sum(
    costItems.filter(({ costType }) => costType === "3")
  );
  const allowanceCostType = sum(
    costItems.filter(({ costType }) => costType === "4")
  );
  const professionalServicesCostType = sum(
    costItems.filter(({ costType }) => costType === "5")
  );
  const uncategorizedCostType = sum(
    costItems.filter(({ costType }) => costType === "6")
  );
  const selections = sum(costItems.filter(({ selection }) => !!selection));
  const total = sum(costItems);

  return (
    <ProposalViewWrapper verticalAlignTop horizontalAlignLeft id="budget">
      <ProposalViewHeader>Budget</ProposalViewHeader>
      <PlanNote hideContent>
        <p>
          The budget helps you understand how the cost of your project is broken
          down.
        </p>
        <p>
          <b>Allowances</b>: Allowances are items that you will need to select,
          such as appliances, plumbing fixtures, tile, etc. that will vary in
          cost depending on your decision. It helps to have this to guide you as
          you begin the selection process. These items are included in the Total
          Cost number.
        </p>
        <p>
          <b>Labor/Materials</b>: Labor and Materials covers everything that is
          not an Allowance, including raw materials such as framing lumber,
          drywall, fasteners, copper pipes, etc., as well as the labor to
          perform the work. The items in this category are supplied by the
          contractor.
        </p>
        <p>
          <b>Total</b>: This is the total cost of your project. Allowance
          decisions may cause this to be higher or lower, depending on the
          Allowance items selected. Change orders may also affect the Budget.
        </p>
      </PlanNote>
      <BudgetTable>
        <BudgetItem>
          <BudgetLabel>Labor Subtotal</BudgetLabel>
          <FormattedNumber number={laborCostType} />
        </BudgetItem>

        <BudgetItem>
          <BudgetLabel>Materials Subtotal</BudgetLabel>
          <FormattedNumber number={materialsCostType} />
        </BudgetItem>

        <BudgetItem>
          <BudgetLabel>Labor and Materials Subtotal</BudgetLabel>
          <FormattedNumber number={laborAndMaterialsCostType} />
        </BudgetItem>

        <BudgetItem>
          <BudgetLabel>Allowance Subtotal</BudgetLabel>
          <FormattedNumber number={allowanceCostType} />
        </BudgetItem>

        <BudgetItem>
          <BudgetLabel>Professional Services Subtotal</BudgetLabel>
          <FormattedNumber number={professionalServicesCostType} />
        </BudgetItem>

        <BudgetItem>
          <BudgetLabel>Uncategorized Subtotal</BudgetLabel>
          <FormattedNumber number={uncategorizedCostType} />
        </BudgetItem>

        <BudgetItem>
          <BudgetLabel>Selections Subtotal</BudgetLabel>
          <FormattedNumber number={selections} />
        </BudgetItem>

        <BudgetItem>
          <BudgetLabel>Total</BudgetLabel>
          <FormattedNumber number={total} />
        </BudgetItem>
      </BudgetTable>
    </ProposalViewWrapper>
  );
};

export default BudgetView;

import styled from "styled-components";

import Link from "../../elements/Link/Link";
import ChoosyLogo from "../../assets/images/choosy_logo.png";

export const NavigationWrapper = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  top: 0;
  font-family: Inter, serif;
  background-color: var(--background-color);
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0 20px;
  width: 100%;
  height: var(--app-header-height);

  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  border-bottom: ${(props) =>
    props.noborder ? "none" : "1px solid var(--line-color-softer)"};

  ${(props) =>
    props.scrolled
      ? `
    box-shadow: 0 1px 20px #c5c5c5;
  `
      : ""}

  ${Link.Html}, ${Link.Router} {
    font-size: 17px;
    margin-left: 35px;
  }
`;

export const Logo = styled(Link.Router)`
  width: 144px;
  height: 31px;
  background-size: contain;
  background-repeat: no-repeat;

  margin-left: 0 !important;
  position: relative;
  background-image: url(${ChoosyLogo});
`;

export const Links = styled.div`
  display: flex;
  position: relative;

  ${(props) => {
    if (props.inverse) {
      return `
        & > * {
          color: #000;
        }
      `;
    }

    return props.scrolled
      ? `
      & > * {
        color: #000;
      }
    `
      : `
      & > * {
        color: #FAFAFA;
      }
    `;
  }}
`;

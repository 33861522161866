import React from 'react';

import analytics, { generateAnalyticsPrefix } from '../../helpers/analytics';
import { ModalViewProposal } from '../../constants/analyticEvents';

import {
  ModalWrapper,
  Modal,
  CheckIcon,
  ModalText,
  ModalButtons,
  ModalButton,
} from './CreateProposalModal.styled';

const CreateNewClientModal = ({ proposalId, edited, hideActiveModals }) => (
  <ModalWrapper>
    <Modal>
      <ModalText>
        Proposal {edited ? 'Edited' : 'Created'}
      </ModalText>
      <CheckIcon />
      <ModalButtons>
        <ModalButton
          to={`/proposal/${proposalId}`}
          onClick={() => {
            analytics.track({
              event: generateAnalyticsPrefix(ModalViewProposal),

            });
            hideActiveModals();
          }}
        >View Proposal
        </ModalButton>
      </ModalButtons>
    </Modal>
  </ModalWrapper>

);

export default CreateNewClientModal;

import React from 'react';

import Headline from '../../elements/Headline/Headline';
import Spacer from '../../elements/Spacer/Spacer';
import Button from '../../elements/Button/Button';
import Content from '../../elements/Content/Content';

import celebrateImage from '../../assets/images/celebrate.svg';

const SignedProposal = ({
  goToDashboard,
}) => (
  <Content>
    <Headline align="center">You’re all set.</Headline>
    <Spacer height="30" />
    <img src={celebrateImage} alt="Celebrate" />
    <Spacer height="40" />
    {/* <HelpText fontFamily="Georgia" fontSize="17px" lineHeight="25px" color="#333">{companyName} is counter-signing your agreement and you’ll receive a copy shortly.</HelpText> */}
    <Spacer height="50" />
    <Button onClick={goToDashboard} outline>Visit Dashboard</Button>
  </Content>
);

export default SignedProposal;

import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const SIZE = {
  large: 2,
  big: 1.5,
  default: 1,
  small: 0.7,
  xs: 0.5,
};

const calc = (value, props) => {
  if (props.size) {
    return `calc(${value} * ${SIZE[props.size]})`;
  }

  return value;
};

const buttonBaseStyles = css`
  background: ${(props) => (props.background ? props.background : "#486AFF")};

  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => calc("20px", props)};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  padding: 7px 5px;
  border-radius: 8px;

  color: ${(props) => (props.color ? props.color : "#F2F2F2")};

  min-height: ${(props) => calc("52px", props)};
  border: 0;
  cursor: pointer;
  outline: 0;

  ${(props) =>
    props.outline
      ? `
      background: #fff;
      color: #486AFF;
      border: ${calc("2px", props)} solid #486AFF;
      box-sizing: border-box;

      &:hover {
        color: #fff;
        background: #486AFF !important;
      }
  `
      : ""}

  ${(props) =>
    props.disabled
      ? `background: #B6B8BC;
      &:hover {
        background: #B6B8BC !important;
      }`
      : ""};
  ${(props) => {
    if (!props.inline) {
      return `
        width: ${props.width ? props.width : "100%"};
      `;
    }

    return "";
  }}

  transition: background .1s ease-in;

  &:hover {
    background: #1e3ed0;
    color: #fff;
  }

  ${(props) => (props.customStyle ? props.customStyle : "")}
`;

const StyledButton = styled.button`
  ${buttonBaseStyles}
`;

const LinkButton = styled(Link)`
  ${buttonBaseStyles}
  align-items: center;
  text-decoration: none;
`;

const NativeLinkButton = styled.a`
  ${buttonBaseStyles}
  align-items: center;
  text-decoration: none;
`;

const Button = ({ to, href, children, ...rest }) => {
  if (to) {
    return (
      <LinkButton to={to} {...rest}>
        {children}
      </LinkButton>
    );
  }

  if (href) {
    return (
      <NativeLinkButton href={href} target="_blank" {...rest}>
        {children}
      </NativeLinkButton>
    );
  }

  return <StyledButton {...rest}>{children}</StyledButton>;
};

export default Button;

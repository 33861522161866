import React from "react";
import ChoosyLaptop from "../../../assets/images/landing-page/choosy-laptop.png";

import {
  SectionWrapperHalf,
  SectionContent,
  SectionImg,
  Paragraph,
  Heading,
} from "../../../pages/LandingPage/LandingPage.styled";

import Spacer from "../../../elements/Spacer/Spacer";

const SectionWhatIsChoosy = () => {
  return (
    <SectionWrapperHalf sectionColor="#ECEFF1" equalCols>
      <SectionImg elOrder="2">
        <img src={ChoosyLaptop} alt="choosy laptop" />
        <Spacer height="70" />
      </SectionImg>

      <SectionContent rightSide elOrder="1">
        <Paragraph textColor="var(--choosy-blue)">What is choosy?</Paragraph>
        <Heading as="h3">The only limit is your imagination.</Heading>
        <Paragraph>
          Choosy is an app for homeowners, contractors, designers and architects
          (the Team) to choose fixtures, finishes and specifications for their
          construction project (Choosy, get it?).
        </Paragraph>
      </SectionContent>
    </SectionWrapperHalf>
  );
};

export default SectionWhatIsChoosy;

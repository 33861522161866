import React, { Component } from "react";
import { connect } from "react-redux";

import { ItemContainer, ItemImage, Header } from "./MoodBoard.styled";

class MoodBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items.filter((item) => item.product),
    };
  }

  /*
    PDF styles/layout for this mood board. See https://react-pdf.org/ for documentation.
  */

  /*
    Prevents PDF from rendering too many times when we add/remove items.
  */
  componentWillReceiveProps(nextProps) {
    const isFetching = nextProps.items.some((item) => item.fetching);
    const { items } = this.state;

    if (isFetching) {
      return;
    }

    const isDifferent =
      nextProps.items.length !== items.length ||
      nextProps.items.some((item, index) => {
        return item.product && items[index].product._id !== item.product._id;
      });

    if (isDifferent) {
      this.setState({
        items: nextProps.items,
      });
    }
  }

  render() {
    const { name } = this.props;
    const { items } = this.state;

    return (
      <div>
        <Header>
          <h2>{name} Board</h2>
        </Header>
        <ItemContainer>
          {items.map((item) => (
            <ItemImage key={item.product._id} src={item.product.image} />
          ))}
        </ItemContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  proposal: state.edit,
  user: state.authorization.user,
});

export default connect(mapStateToProps)(MoodBoard);

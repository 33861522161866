import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 1002;
  padding: 10px 20px 0 20px;
  top: 0;
  width: 100%;
  height: 70px;
  height: var(--app-header-height);
  background-color: var(--background-color);
  border-bottom: ${(props) =>
    props.noborder ? "none" : "1px solid var(--line-color-softer)"};
  box-shadow: ${(props) => (props.scrolled ? "0 1px 20px #c5c5c5" : "none")};
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);

  @media print {
    position: absolute;
    top: 0;
    justify-content: center;
    border-bottom: none;
    box-shadow: none;
  }
`;

export const HeaderButton = styled.button`
  width: 40px;
  height: 40px;
  padding: 0;
  text-align: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media print {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  background-image: url(${(props) => props.src});
  width: 105px;
  height: 31px;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Logo = styled.img`
  display: block;
  width: 105px;
  height: 31px;
  opacity: 0;
`;

export const Proposal = styled.div`
  z-index: 0;
  min-height: calc(100vh - var(--app-header-height));
  padding: var(--content-padding);
  overflow: hidden;
  background-color: var(--background-color);
`;

export const Overlap = styled.div`
  background: #fff;
  position: fixed;
  overflow: hidden;
  z-index: ${(props) => (props.show ? "1001" : "-1")};
  top: 70px;
  bottom: 0;
  height: ${(props) => (props.show ? "100%" : 0)};
  width: 100%;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;

  ${(props) =>
    props.show
      ? `
    transition: height .3s ease-in;
  `
      : `
    transition: height .2s linear, z-index .2s linear;
  `};
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding-left: 30px;
  padding-right: 40px;
`;

const baseLinkStyles = (props) => `
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #333333;

  text-decoration: ${props.underline ? "underline" : "none"};
  margin-bottom: ${props.noMarginBottom ? "0" : "18.1px;"}
`;

export const NavigationItem = styled.div`
  ${(props) => baseLinkStyles(props)}

  h2 {
    font-weight: 400;
  }
`;

export const NativeLink = styled.a`
  ${(props) => baseLinkStyles(props)}
`;

export const AnchorLink = styled(HashLink)`
  ${(props) => baseLinkStyles(props)}
`;

export const NavigationLink = styled(Link)`
  ${(props) => baseLinkStyles(props)}
`;

export const RegularLink = styled.a`
  ${(props) => baseLinkStyles(props)}
`;

export const BurgerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

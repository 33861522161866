import React from "react";
import styled from "styled-components";

import CreateProposalModal from "../composables/CreateProposalModal";
import AddProductModal from "../composables/AddProductModal";
import AddManualProductModal from "../composables/AddManualProductModal";

const ModalWrapper = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  top: 0;
  bottom: -10%;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const modalsMap = {
  createProposal: CreateProposalModal,
  addProduct: AddProductModal,
  addManualProduct: AddManualProductModal,
};

const ModalHost = ({ modals, hideActiveModals }) => {
  const modalsToShow = modals
    .filter(({ state }) => state === true)
    .map(({ name }) => ({
      component: modalsMap[name],
      name,
    }));

  const showWrapper = modalsToShow.length > 0;

  const hideModals = (e) => {
    if (e.target.parentElement.parentElement.id) {
      hideActiveModals();
    }
  };

  return (
    <ModalWrapper show={showWrapper} onClick={hideModals} id="modal-container">
      {modalsToShow.map((modal) => (
        <modal.component key={modal.name} />
      ))}
    </ModalWrapper>
  );
};
export default ModalHost;

import React from "react";

import Headline from "../../elements/Headline/Headline";
import HR from "../../elements/HR/HR";
import Spacer from "../../elements/Spacer/Spacer";
import Button from "../../elements/Button/Button";
import Content from "../../elements/Content/Content";

import { Icon, HelpText } from "./SentProposal.styled";

const SentProposal = ({
  to,
  goToDashboard,
  created,
  authorized,
  postSignupAuthorized,
}) => (
  <Content>
    <Icon />
    {created ? (
      <Headline align="left">Check your email</Headline>
    ) : (
      <Headline align="left">Proposal Sent</Headline>
    )}
    <HR />
    <Spacer height="20" />
    {!postSignupAuthorized &&
      (!created ? (
        <HelpText>
          Your proposal was sent to <u>{to}</u>. We’ll notify you as soon as
          it’s signed.{" "}
        </HelpText>
      ) : (
        <HelpText>
          We've sent <u>{to}</u> a link to see the proposal you created.
        </HelpText>
      ))}
    {postSignupAuthorized && (
      <HelpText>
        We’ve sent <u>{to}</u> a link to log into Choosy.
      </HelpText>
    )}
    <Spacer height="50" />
    {authorized && <Button onClick={goToDashboard}>Continue</Button>}
  </Content>
);

export default SentProposal;

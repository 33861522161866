import React, { Fragment, useState } from "react";
import { Menu, Dropdown, Button as AntdButton, Select } from "antd";
import {
  DownOutlined,
  CopyOutlined,
  EditOutlined,
  DeleteOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

import analytics from "../../helpers/analytics";
import {
  DashboardDuplicate,
  DashboardDelete,
  DashboardEdit,
  DashboardTransferOwnership,
} from "../../constants/analyticEvents";

import Link from "../../elements/Link/Link";
import Spacer from "../../elements/Spacer/Spacer";

import { Wrapper, Action } from "./ProposalControl.styled";

const ProposalControl = ({
  proposal,
  user,
  history,
  contractors,
  duplicateProposal,
  deleteProposal,
  reassignProposal,
  toggleProposalVisibility,
}) => {
  const [reassignEnabled, setReassignEnabled] = useState(false);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const onDuplicate = () => {
    duplicateProposal(proposal._id);

    analytics.track({
      event: DashboardDuplicate,
    });
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want to delete this propsal?")) {
      analytics.track({
        event: DashboardDelete,
      });
      deleteProposal(proposal._id);
    }
  };

  const onReassign = () => {
    analytics.track({
      event: DashboardTransferOwnership,
    });

    setReassignEnabled(true);
  };

  const goToEdit = () => {
    analytics.track({
      event: DashboardEdit,
    });

    history.push(`/proposal/edit/${proposal._id}`);
  };

  const handleGoToSelections = (e) => {
    e.stopPropagation();

    history.push(`/proposal/${proposal._id}/selections/`);
  };

  const handleReassign = (value) => {
    if (value === "%NEW%") {
      const email = prompt("Please enter the email of the new contractor");

      if (email === null) {
        setReassignEnabled(false);
        return;
      }

      if (
        window.confirm(
          `Are you sure you want to create new contractor account and reassign this proposal to ${email}?`
        )
      ) {
        reassignProposal(proposal._id, email, true);
        setReassignEnabled(false);

        return;
      }
      setReassignEnabled(false);
      return;
    }

    if (value === "%PUBLISH%") {
      if (
        window.confirm(
          `Are you sure you want to make this proposal ${
            proposal.isPublic ? "private" : "publicly available"
          }?`
        )
      ) {
        toggleProposalVisibility(proposal._id);

        setReassignEnabled(false);
        return;
      }

      setReassignEnabled(false);
      return;
    }

    if (
      window.confirm(
        `Are you sure you want to reassign this proposal to ${value}?`
      )
    ) {
      reassignProposal(proposal._id, value);
      setReassignEnabled(false);
    }
  };

  const getShoppingProgress = () => {
    const costGroupsWithAllowance = proposal.costGroups.filter(
      (costGroup) =>
        costGroup.values.filter(
          (costItems) => !costItems.pristine && costItems.selection
        ).length > 0
    );

    if (costGroupsWithAllowance.length === 0) {
      return null;
    }

    const costItems = costGroupsWithAllowance
      .map((costGroup) => costGroup.values)
      .reduce((a, b) => [...a, ...b])
      .filter((costItem) => costItem.selection);

    const costItemsWithOption =
      costItems.filter((costItem) =>
        costItem.options ? costItem.options.length > 0 : false
      ).length || 0;

    const progress = (costItemsWithOption / costItems.length) * 100;

    return Math.ceil(progress);
  };

  const shoppingProgress = getShoppingProgress();

  const ReassginComponent = () => {
    const { Option } = Select;

    if (user.reassign !== "granted") {
      return null;
    }

    if (!reassignEnabled) {
      return (
        <Fragment>
          <Action onClick={onReassign}>Sharing</Action>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Select
          showSearch
          onChange={handleReassign}
          defaultValue="null"
          style={{ width: "100%" }}
          placeholder="Sharing"
        >
          <Option disabled value="null">
            Sharing
          </Option>
          <Option value="%NEW%">>> Copy Proposal URL</Option>
          <Option value="%PUBLISH%">
            >> {proposal.isPublic ? "Make Private" : "Make Publicly Available"}
          </Option>
          {contractors.map((contractor) => (
            <Option value={contractor} key={contractor}>
              {contractor}
            </Option>
          ))}
        </Select>
      </Fragment>
    );
  };

  const showEdit = !proposal.events.signedByHomeowner;

  const menu = (
    <Menu>
      {shoppingProgress && (
        <Menu.Item key="4" icon={<ShoppingOutlined />}>
          <Link.Router
            onClick={handleGoToSelections}
            to={`/proposal/${proposal._id}/selections/`}
          >
            {shoppingProgress}% Shopping
          </Link.Router>
        </Menu.Item>
      )}
      <Menu.Item key="1" icon={<CopyOutlined />}>
        <Action onClick={onDuplicate}>Duplicate</Action>
      </Menu.Item>
      {showEdit && (
        <Menu.Item key="2" icon={<EditOutlined />}>
          <Action onClick={goToEdit}>Edit</Action>
        </Menu.Item>
      )}
      <Menu.Item key="3" icon={<DeleteOutlined />}>
        <Action onClick={onDelete}>Delete</Action>
      </Menu.Item>
    </Menu>
  );

  return (
    user.role !== "homeowner" && (
      <Wrapper onClick={stopPropagation}>
        <Dropdown overlay={menu}>
          <AntdButton>
            Options <DownOutlined />
          </AntdButton>
        </Dropdown>
        <Spacer width="10" inline />
        <ReassginComponent />
      </Wrapper>
    )
  );
};

export default ProposalControl;

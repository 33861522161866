import React, { useState } from 'react'
import {
    WrapperAvatar,
    ImageAvatar
} from './UserAvatar.styled';


const UserAvatar = ({
    borderRadius = '100%',
    src,
    name,
    color="#cccccc",
    textColor="#fff",
    size = 48} ) => {

    const [sizeToUse, setSizeToUse] = useState(size)

    if (!name) return null;

    let initials = '';


    if (name.includes("@")) {
        initials = name.substring(0,2).toUpperCase();
    }
    else {
        initials = name.split(' ').map(subName => {return subName.length > 0 ? subName[0].toUpperCase() : ''}).join('');
    }

    if (sizeToUse.indexOf('px') === -1) {
        setSizeToUse(`${size.toString(10)}px`);
    }

    if (src) {
        return ( <div aria-label = { name } >
            <WrapperAvatar isImg size={ sizeToUse } borderRadius={ borderRadius } >
                <ImageAvatar size={ sizeToUse } borderRadius={ borderRadius }  />
            </WrapperAvatar>
            </div>
        )
    }

    return ( <div aria-label = { name } >
        <WrapperAvatar isImg = { false } size={ sizeToUse } color = { color } textColor={ textColor } borderRadius={ borderRadius } >
            { initials }
        </WrapperAvatar>
        </div>
    )
}

export default UserAvatar;
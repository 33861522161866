const START_FETCH_PROPOSALS = 'START_FETCH_PROPOSALS';
const SUCCESS_FETCH_PROPOSALS = 'SUCCESS_FETCH_PROPOSALS';
const ERROR_FETCH_PROPOSALS = 'ERROR_FETCH_PROPOSALS';

const FETCH_CONTRACTORS = 'FETCH_CONTRACTORS';

export const fetchContractors = () => (dispatch) => {
  fetch('/api/proposal/contractors', {
    method: 'GET',
  }).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }

    throw new Error(res);
  })
    .then((contractors) => {
      dispatch({
        type: FETCH_CONTRACTORS,
        payload: {
          contractors,
        },
      });
    });
};

export const getProposals = () => (dispatch) => {
  dispatch({
    type: START_FETCH_PROPOSALS,
  });

  fetch('/api/proposal/', {
    method: 'GET',
  }).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }

    throw new Error(res);
  })
    .then((proposals) => {
      dispatch({
        type: SUCCESS_FETCH_PROPOSALS,
        payload: {
          proposals,
        },
      });
    })
    .catch(error => dispatch({
      type: ERROR_FETCH_PROPOSALS,
      payload: {
        error,
      },
    }));
};

export default function DashboardReducer (state = {
  fetching: false,
  proposals: [],
  contractors: [],
}, action) {
  switch (action.type) {
    case START_FETCH_PROPOSALS:
      return {
        ...state,
        fetching: true,
      };
    case SUCCESS_FETCH_PROPOSALS:
      return {
        ...state,
        fetching: false,
        proposals: action.payload.proposals,
      };
    case ERROR_FETCH_PROPOSALS:
      return {
        ...state,
        fetching: false,
        sent: false,
      };
    case FETCH_CONTRACTORS:
      return {
        ...state,
        contractors: action.payload.contractors,
      };
    default:
      return state;
  }
}

const START_SEND_PROPOSAL = "START_SEND_PROPOSAL";
const SUCCESS_SEND_PROPOSAL = "SUCCESS_SEND_PROPOSAL";
const ERROR_SEND_PROPOSAL = "ERROR_SEND_PROPOSAL";

export const SUCCESS_SEND_CREATED_PROPOSAL = "SUCCESS_SEND_CREATED_PROPOSAL";
const ERROR_SEND_CREATED_PROPOSAL = "ERROR_SEND_PROPOSAL";

const EMAIL_RESET = "EMAIL_RESET";

export const reset = () => ({
  type: EMAIL_RESET,
});

export const sendProposal =
  (email, message, homeownersName) => (dispatch, getState) => {
    dispatch({
      type: START_SEND_PROPOSAL,
      payload: {
        to: email.toLocaleLowerCase(),
      },
    });

    const { proposal } = getState().proposalView;

    fetch("/api/proposal/send", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        proposalId: proposal._id,
        to: email.toLocaleLowerCase(),
        message,
        homeownersName,
      }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.text();
        }

        throw new Error(res);
      })
      .then(() => {
        dispatch({
          type: SUCCESS_SEND_PROPOSAL,
        });
      })
      .catch((error) =>
        dispatch({
          type: ERROR_SEND_PROPOSAL,
          payload: {
            error,
          },
        })
      );
  };

export const sendCreatedProposal = (email) => (dispatch, getState) => {
  dispatch({
    type: START_SEND_PROPOSAL,
    payload: {
      to: email.toLocaleLowerCase(),
    },
  });

  const { proposalId } = getState().create;

  fetch("/api/proposal/sendCreated", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      proposalId,
      to: email.toLocaleLowerCase(),
    }),
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.text();
      }

      throw new Error(res);
    })
    .then(() => {
      dispatch({
        type: SUCCESS_SEND_CREATED_PROPOSAL,
      });
    })
    .catch((error) =>
      dispatch({
        type: ERROR_SEND_CREATED_PROPOSAL,
        payload: {
          error,
        },
      })
    );
};

export default function EmailsReducer(
  state = {
    fetching: false,
    sent: false,
    created: false,
    to: "",
  },
  action
) {
  switch (action.type) {
    case EMAIL_RESET:
      return {
        ...state,
        fetching: false,
        sent: false,
        created: false,
      };
    case START_SEND_PROPOSAL:
      return {
        ...state,
        fetching: true,
        sent: false,
        to: action.payload.to,
        created: false,
      };
    case SUCCESS_SEND_PROPOSAL:
      return {
        ...state,
        fetching: false,
        sent: true,
        created: false,
      };
    case SUCCESS_SEND_CREATED_PROPOSAL:
      return {
        ...state,
        fetching: false,
        sent: true,
        created: true,
      };
    case ERROR_SEND_PROPOSAL:
    case ERROR_SEND_CREATED_PROPOSAL:
      return {
        ...state,
        fetching: false,
        sent: false,
      };
    default:
      return state;
  }
}

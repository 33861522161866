import styled from 'styled-components';

export const DropZoneWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px dashed rgba(51, 51, 51, 0.22);
  min-height: 100px;
  display: flex;
  cursor: pointer;

  ${props => props.isDragActive && `
    border-style: solid;
    box-shadow: inset 0 0 7px 1px #cecece;
  `}
`;

export const DropZoneLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const DropZoneLabel = styled.span`
  font-family: Inter, serif;
  font-size: 0.9em;
  color: var(--text-color-soft);
  font-weight: 500;
`;
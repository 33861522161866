import React from 'react';

import CoverPreview from '../CoverPreview/CoverPreview';

import ProposalViewWrapper from '../../elements/ProposalViewWrapper/ProposalViewWrapper';

const CoverView = ({ proposal }) => (
  <ProposalViewWrapper>
    <CoverPreview
      mode="view"
      showLogo={proposal.showLogo}
      companyLogo={proposal.companyLogo}
      companyName={proposal.companyName}
      projectName={proposal.projectName}
      projectAddress={proposal.projectAddress}
    />
  </ProposalViewWrapper>
);

export default CoverView;

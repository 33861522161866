import React, { Fragment } from "react";

import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import { OpenedCostItem, ClosedCostItem } from "../../constants/analyticEvents";

import {
  CostHeading,
  Cost as PlanViewCost,
} from "../../components/PlanView/PlanView.styled";

import CostGroupAccordeonView from "../../components/CostGroupAccordeonView/CostGroupAccordeonView";
import FormattedNumber from "../../components/FormattedNumber/FormattedNumber";
import NavigationIconList from "../../components/NavigationIconList/NavigationIconList";

import {
  Cost,
  CostWrapper,
  DetailedViewHeader,
  AccordeonControl,
} from "./CostGroupListView.styled";

const CostGroupListView = ({ items, totalCost }) => (
  <Fragment>
    {totalCost > 0 && (
      <CostWrapper>
        <CostHeading>Total Cost</CostHeading>
        <PlanViewCost>
          <FormattedNumber number={totalCost} />
        </PlanViewCost>
      </CostWrapper>
    )}
    <NavigationIconList
      items={items}
      accordeon
      onExpand={() => {
        analytics.track({
          event: generateAnalyticsPrefix(OpenedCostItem),
        });
      }}
      onCollapse={() => {
        analytics.track({
          event: generateAnalyticsPrefix(ClosedCostItem),
        });
      }}
      component={(item, index) => (
        <CostGroupAccordeonView
          withNote
          costGroup={item}
          costGroupNumber={index}
        />
      )}
      padding="10"
      getName={(item) => item.name}
      getHelpText={(item) => {
        const costGroupCost =
          item.values.length > 0
            ? item.values
                .map(({ cost }) => Number(cost))
                .reduce((a, b) => a + b)
            : 0;
        return (
          <Cost>
            <FormattedNumber number={costGroupCost} />
          </Cost>
        );
      }}
      showIcon={false}
    >
      {(allCollapsed, toggleAll) => (
        <DetailedViewHeader>
          Detailed Work{" "}
          <AccordeonControl onClick={toggleAll}>
            {allCollapsed ? "Expand All" : "Collapse All"}
          </AccordeonControl>
        </DetailedViewHeader>
      )}
    </NavigationIconList>
  </Fragment>
);

export default CostGroupListView;

import styled from "styled-components";

import { Wrapper as ProposalControlWrapper } from "../../composables/ProposalControl/ProposalControl.styled";

export const ProposalItem = styled.div`
  flex: 1 ${ProposalControlWrapper};
`;

export const ProposalItemTimestamp = styled.div`
  margin-top: 0.25rem;
  font-family: var(--font-family-label);
  font-size: 0.9rem;
  color: var(--text-color-soft);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

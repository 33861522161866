import React, { Fragment } from "react";

import costGroupNotes from "../../constants/costGroupNotes";

import Link from "../../elements/Link/Link";

import FormattedNumber from "../FormattedNumber/FormattedNumber";
import CostGroupNote from "../CostGroupNote/CostGroupNote";
import ImageReel from "../ImageReel/ImageReel";

import { costTypes } from "../../constants/costGroupData";

import {
  Wrapper,
  CostItemsList,
  CostItem,
  CostItemContent,
  CostItemInfo,
  CostItemDetail,
  CostItemCost,
  CostItemNote,
  CostItemNumber,
  AllowanceLabel,
  Notes,
} from "./CostGroupAccordeonView.styled";

const CostGroupAccordeonView = ({ costGroup, costGroupNumber, withNote }) => {
  const note = costGroupNotes[costGroup.name.toLocaleLowerCase()] || null;

  return (
    <Wrapper>
      <CostItemsList>
        {costGroup.values
          .filter(({ pristine }) => !pristine)
          .map((value, index) => (
            <CostItem key={value.id}>
              <CostItemNumber>
                {costGroupNumber + 1}.{index + 1}
              </CostItemNumber>
              <CostItemContent>
                <CostItemInfo>
                  <CostItemDetail>{value.name}</CostItemDetail>
                  <CostItemCost>
                    {value.cost && <FormattedNumber number={value.cost} />}
                  </CostItemCost>
                </CostItemInfo>
                <CostItemNote>
                  <AllowanceLabel>
                    {costTypes[value.costType - 1]?.name}
                  </AllowanceLabel>
                  {value.selection && (
                    <AllowanceLabel>
                      <Link.Router
                        to={`./selections/#${window.btoa(value.id)}`}
                      >
                        Selected
                      </Link.Router>
                    </AllowanceLabel>
                  )}
                  {value.note}
                </CostItemNote>
              </CostItemContent>
            </CostItem>
          ))}
      </CostItemsList>
      {costGroup.note && (
        <Fragment>
          <Notes>{costGroup.note}</Notes>
        </Fragment>
      )}

      {note && withNote && <CostGroupNote hideContent>{note}</CostGroupNote>}

      {costGroup.images.length > 0 && (
        <Fragment>
          <ImageReel height="16" items={costGroup.images} />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default CostGroupAccordeonView;

import React from "react";

import ProposalViewWrapper from "../../elements/ProposalViewWrapper/ProposalViewWrapper";
import ProposalViewHeader from "../../elements/ProposalViewHeader/ProposalViewHeader";

import WelcomePeview from "../WelcomePreview/WelcomePreview";

import { formatDate } from "../../helpers/formatDate";

const WelcomeView = ({ proposal, to }) => (
  <ProposalViewWrapper verticalAlignTop horizontalAlignLeft id="welcome">
    <ProposalViewHeader>Welcome</ProposalViewHeader>
    <WelcomePeview
      mode="view"
      personalPhoto={proposal.personalPhoto}
      name={proposal.name}
      to={to}
      date={formatDate(proposal.date)}
      introductoryNote={proposal.introductoryNote}
      companyName={proposal.companyName}
      phoneNumber={proposal.phoneNumber}
    />
  </ProposalViewWrapper>
);
export default WelcomeView;

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import queryString from "query-string";

import {
  authorize as authorizeAction,
  reset as resetAction,
} from "../../ducks/authorization";

import HandleAuthoriation from "./HandleAuthoriation";

const mapStateToProps = (state) => ({
  authorization: state.authorization,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authorize: authorizeAction,
      reset: resetAction,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentWillMount() {
      const { reset } = this.props;

      reset();
    },
    componentDidMount() {
      const { authorize, match } = this.props;

      authorize(match.params.token);
    },
    componentWillReceiveProps(nextProps) {
      const { history, match, location } = this.props;

      if (
        !nextProps.authorization.fetching &&
        nextProps.authorization.authorized &&
        match.url.indexOf("dashboard") === -1
      ) {
        if (location.search) {
          const qs = queryString.parse(location.search);
          history.replace(qs.redirect);
        } else {
          history.replace("/dashboard/");
        }
      }
    },
  })
)(HandleAuthoriation);

import React, { Fragment } from "react";
import moment from "moment";

import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import {
  DashboardCreateProposal,
  DashboardOpenProposal,
} from "../../constants/analyticEvents";

import NavigationIconList from "../../components/NavigationIconList/NavigationIconList";

import Headline from "../../elements/Headline/Headline";
import HR from "../../elements/HR/HR";
import Button from "../../elements/Button/Button";
import Spacer from "../../elements/Spacer/Spacer";

import { ProposalItemTimestamp, ProposalItem } from "./Dashboard.styled";

const Dashboard = ({ dashboard, history, forContractor }) => {
  if (dashboard.fethcing) {
    return null;
  }

  const createNewProposalButtonClick = () => {
    analytics.track({
      event: generateAnalyticsPrefix(DashboardCreateProposal),
    });

    history.push("/create");
  };

  return (
    <Fragment>
      <Headline>Projects</Headline>
      <HR />
      <NavigationIconList
        items={dashboard.proposals}
        iconSize="70"
        padding="0"
        onItemCick={(item) => {
          analytics.track({
            event: generateAnalyticsPrefix(DashboardOpenProposal),
          });

          history.push(
            forContractor
              ? `/proposal/${item._id}/`
              : `/proposal/received/${item._id}/`
          );
        }}
        mapIcon={() => "paper-stack"}
        getName={(item) => item.name}
        getHelpText={(item) => {
          let date;
          let prefix;

          const { signedByHomeowner, signedByContractor, sent, created } =
            item.events;

          if (signedByHomeowner && signedByContractor) {
            prefix = "Signed";
            date = moment(
              Math.max(signedByHomeowner, signedByContractor)
            ).format("MMM D, YYYY");
          } else if (sent) {
            prefix = "Sent";
            date = moment(sent).format("MMM D, YYYY");
          } else {
            prefix = "Created";
            date = moment(created).format("MMM D, YYYY");
          }

          return (
            <ProposalItem>
              {item.projectName}
              <ProposalItemTimestamp>
                <span>
                  {prefix} {date}
                </span>
              </ProposalItemTimestamp>
            </ProposalItem>
          );
        }}
      />
      <Spacer height="20" />
      {forContractor && (
        <Button onClick={createNewProposalButtonClick}>
          Create New Proposal
        </Button>
      )}
    </Fragment>
  );
};

export default Dashboard;

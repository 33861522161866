import styled from "styled-components";

export const PreviewWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #333;
  word-wrap: break-word;
`;

export const DraggableCostGroupWrapper = styled.div`
  user-select: none;
  background: ${(props) => (props.isDragging ? "#fff" : "")};
  border: ${(props) => (props.isDragging ? "1px solid #82828240" : "")};
`;

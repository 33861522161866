/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Icon from "../../elements/Icon/Icon";
import { allowedFiles } from "../../constants/mime-types";
import analytics, { generateAnalyticsPrefix } from "../../helpers/analytics";
import {
  PlanRemoveCostGroup,
  PlanCostGroup,
  PlanCostItem,
  PlanAllowance,
  PlanRemoveCostItem,
  PlanAttachCostGroupPhoto,
  PlanRemoveCostGroupPhoto,
} from "../../constants/analyticEvents";

import {
  costGroupsItems,
  planningAndPermitsItems,
  utilitiesItems,
  demolitionItems,
  concreteItems,
  excavationItems,
  framingItems,
  exteriorItems,
  plumbingItems,
  mechanicalItems,
  electricalItems,
  insulationItems,
  wallCoveringsItems,
  wallFinishesItems,
  interiorTrimItems,
  bathroomModuleItems,
  kitchenModuleItems,
  powderRoomModuleItems,
  flooringItems,
  landscapingItems,
  generalItems,
  customItems,
  generalConditionsItems,
  costTypes,
} from "../../constants/costGroupData";

import FormattedNumber from "../../components/FormattedNumber/FormattedNumber";
import DropZone from "../../components/DropZone/DropZone";
import AutoCalcInput from "../../components/AutoCalcInput/AutoCalcInput";

import {
  DraggableIcon,
  CostGroupWrapper,
  DraggableCostItem,
} from "./CostGroup.styled";

class CostGroup extends Component {
  constructor(props) {
    super(props);

    const { costGroupId, create } = props;

    const costGroup = create.costGroups.find(({ id }) => id === costGroupId);

    this.state = {
      costItems: costGroup.name.replace(/ /g, "_").toLocaleLowerCase(),
    };
  }

  componentDidMount() {
    const { costGroupId, create, lastCostGroupId } = this.props;

    const costGroup = create.costGroups.find(({ id }) => id === costGroupId);

    if (costGroup.pristine && costGroup.id === lastCostGroupId) {
      this.$costGroup.focus();
    }
  }

  render() {
    const {
      costGroupId,
      create,
      costGroupDraggableProvided,
      costGroupDraggableSnapshot,
      createCostItem,
      updateCostItemValue,
      updateCostGroupValue,
      removeCostGroup,
      removeCostGroupItem,
      uploadCostGroupImage,
      removeCostGroupImage,
      mode,
      orderCostGroupItems,
      editOrderCostGroupItems,
    } = this.props;

    const { costItems } = this.state;

    const costGroup = create.costGroups.find(({ id }) => id === costGroupId);
    const costGroupValues = costGroup.values;

    const handleCostGroupChange = (e) => {
      const group = e.target.value.toLocaleLowerCase();

      updateCostGroupValue(costGroupId, "name", e.target.value);

      this.setState({
        costItems: group.replace(/ /g, "_"),
      });
    };

    const handleLastInputChange = (costItemId, field, value) => {
      updateCostItemValue(costGroupId, costItemId, field, value);

      createCostItem(costGroupId);
    };

    const askRemoveCostGroup = () => {
      const confirm = window.confirm(
        "Are you sure you want to remove this cost group?"
      );

      if (confirm) {
        removeCostGroup(costGroupId);
      }
    };

    const ifGroupIsCustom = (groupName) => {
      const allowedGroups = [
        "planning_and_permits",
        "utilities",
        "demolition",
        "concrete",
        "excavation",
        "framing",
        "exterior",
        "plumbing",
        "mechanical",
        "electrical",
        "insulation",
        "wall_finishes",
        "wall_coverings",
        "interior_trim",
        "bathrooms",
        "kitchens",
        "general",
        "general_conditions",
        "flooring",
        "landscaping",
        "bathroom_module",
        "kitchen_module",
        "powder_room_module",
      ];

      return allowedGroups.indexOf(groupName) === -1;
    };

    const totalCost =
      costGroupValues.length > 0
        ? costGroupValues
            .map(({ cost }) => Number(cost))
            .reduce((a, b) => a + b)
        : 0;

    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      if (mode === "edit") {
        editOrderCostGroupItems(
          costGroupId,
          result.source.index,
          result.destination.index
        );
      } else {
        orderCostGroupItems(
          costGroupId,
          result.source.index,
          result.destination.index
        );
      }
    };

    const getLastTrStyles = (isDraggingOver) => ({
      opacity: isDraggingOver ? 0 : 1,
    });

    const draggableCostItemRow = (value, index) => {
      return (
        <Draggable key={value.id} draggableId={value.id} index={index}>
          {(provided, snapshot) => (
            <DraggableCostItem
              key={value.id}
              ref={provided.innerRef}
              {...provided.draggableProps}
              isDragging={snapshot.isDragging}
            >
              <td className="remove-item-td">
                <a
                  className="remove-item"
                  onClick={() => {
                    analytics.track({
                      event: generateAnalyticsPrefix(PlanRemoveCostItem),
                    });

                    removeCostGroupItem(costGroupId, value.id);
                  }}
                >
                  x
                </a>
              </td>
              <td>
                <input
                  list={
                    !ifGroupIsCustom(costItems)
                      ? `cost_item-${costItems}`
                      : "custom"
                  }
                  defaultValue={value.name || ""}
                  onChange={(e) =>
                    updateCostItemValue(
                      costGroupId,
                      value.id,
                      "name",
                      e.target.value
                    )
                  }
                  onClick={() => {
                    analytics.track({
                      event: generateAnalyticsPrefix(PlanCostItem),
                    });
                  }}
                />
              </td>
              <td>
                <AutoCalcInput
                  defaultValueProp={value.cost || ""}
                  defaultRawValueProp={value.rawCost || ""}
                  calculationHandler={(targetValue, rawValue) => {
                    updateCostItemValue(
                      costGroupId,
                      value.id,
                      "cost",
                      targetValue
                    );
                    updateCostItemValue(
                      costGroupId,
                      value.id,
                      "rawCost",
                      rawValue
                    );
                  }}
                />
              </td>
              <td>
                <select
                  value={value.costType || ""}
                  onChange={(e) =>
                    updateCostItemValue(
                      costGroupId,
                      value.id,
                      "costType",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {costTypes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="checkbox"
                  defaultChecked={value.selection || false}
                  onChange={(e) => {
                    analytics.track({
                      event: generateAnalyticsPrefix(PlanAllowance),
                    });

                    updateCostItemValue(
                      costGroupId,
                      value.id,
                      "selection",
                      e.target.checked
                    );
                  }}
                />
              </td>
              <td>
                <textarea
                  defaultValue={value.note || ""}
                  onChange={(e) =>
                    updateCostItemValue(
                      costGroupId,
                      value.id,
                      "note",
                      e.target.value
                    )
                  }
                />
              </td>
              <DraggableIcon isDragging={snapshot.isDragging}>
                <div {...provided.dragHandleProps}>
                  <Icon name="draggable-icon" size="25" />
                </div>
              </DraggableIcon>
            </DraggableCostItem>
          )}
        </Draggable>
      );
    };

    return (
      <CostGroupWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-cost-item" type="cost-item">
            {(provided, snapshot) => (
              <table
                className="cost_group"
                id={costGroupId}
                ref={provided.innerRef}
              >
                <tbody>
                  <tr className="cost_group_table_header">
                    <td colSpan="1">
                      <a
                        className="remove-item"
                        onClick={() => {
                          analytics.track({
                            event: generateAnalyticsPrefix(PlanRemoveCostGroup),
                          });

                          askRemoveCostGroup();
                        }}
                      >
                        x
                      </a>
                    </td>
                    <td colSpan="5" className="cost-group-input">
                      <input
                        list="cost_group"
                        placeholder="Choose Cost Group"
                        onChange={handleCostGroupChange}
                        value={costGroup.name || ""}
                        ref={(input) => {
                          this.$costGroup = input;
                        }}
                        onClick={() => {
                          analytics.track({
                            event: generateAnalyticsPrefix(PlanCostGroup),
                          });
                        }}
                      />
                      <a className="info_tooltip">
                        i
                        <span className="tooltip_content">
                          <i>Cost Groups</i> are the buckets you put your{" "}
                          <i>Cost Items</i> in to organize them for your
                          customer.{" "}
                          {/* There are few different ways to do this, and how you use them is up to you. Check out some examples of different ways to use them here. */}
                        </span>
                      </a>
                    </td>
                    <DraggableIcon
                      isDragging={costGroupDraggableSnapshot.isDragging}
                    >
                      <div {...costGroupDraggableProvided.dragHandleProps}>
                        <Icon name="draggable-icon" size="30" />
                      </div>
                    </DraggableIcon>
                  </tr>
                  <tr className="inset_table_heading">
                    <td className="remove-item-td" />
                    <td>
                      Cost Items
                      <a className="info_tooltip left">
                        i
                        <span className="tooltip_content">
                          <i>Cost Items</i> are the individual costs that make
                          up each <i>Cost Group</i>. This can be as simple or
                          detailed as you like.
                        </span>
                      </a>
                    </td>
                    <td>Cost</td>
                    <td>Cost Type</td>
                    <td>
                      Selection
                      <a className="info_tooltip center">
                        i
                        <span className="tooltip_content">
                          Allowances, also called Selections are anything that
                          you or your designer or your contractor will be
                          selecting for the project, things like tile, faucets,
                          sinks, countertops, cabinets, etc.
                          <br /> <br />
                          Allowances are usually used as a <i>Cost Item</i>, but
                          could be a <i>Cost Group</i> on its' own depending on
                          the situation.
                        </span>
                      </a>
                    </td>
                    <td>
                      Note
                      <a className="info_tooltip">
                        i
                        <span className="tooltip_content">
                          Notes are used to describe a <i>Cost Group</i> or a{" "}
                          <i>Cost Item</i> . They are used to define scope, and
                          also cover your butt by being clear about exactly what
                          is and what is not included in your budget number.
                        </span>
                      </a>
                    </td>
                  </tr>
                  {costGroupValues.map((value, index) => {
                    if (index === costGroupValues.length - 1) {
                      return (
                        <tr
                          key={value.id}
                          style={getLastTrStyles(snapshot.isDraggingOver)}
                        >
                          <td className="remove-item-td" />
                          <td>
                            <input
                              list={
                                !ifGroupIsCustom(costItems)
                                  ? `cost_item-${costItems}`
                                  : "custom"
                              }
                              defaultValue={value.name || ""}
                              onClick={() => {
                                analytics.track({
                                  event: generateAnalyticsPrefix(PlanCostItem),
                                });
                              }}
                              onChange={(e) =>
                                handleLastInputChange(
                                  value.id,
                                  "name",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <AutoCalcInput
                              defaultValueProp={value.cost || ""}
                              defaultRawValueProp={value.rawCost || ""}
                              calculationHandler={(targetValue, rawValue) => {
                                handleLastInputChange(
                                  value.id,
                                  "cost",
                                  targetValue
                                );
                                handleLastInputChange(
                                  value.id,
                                  "rawCost",
                                  rawValue
                                );
                              }}
                            />
                          </td>
                          <td>
                            <select
                              value={value.costType || ""}
                              onChange={(e) =>
                                handleLastInputChange(
                                  value.id,
                                  "costType",
                                  e.target.value
                                )
                              }
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              {costTypes.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              defaultChecked={value.selection || false}
                              onChange={(e) => {
                                analytics.track({
                                  event: generateAnalyticsPrefix(PlanAllowance),
                                });

                                handleLastInputChange(
                                  value.id,
                                  "selection",
                                  e.target.checked
                                );
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              defaultValue={value.note || ""}
                              onChange={(e) =>
                                handleLastInputChange(
                                  value.id,
                                  "note",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      );
                    }

                    return draggableCostItemRow(value, index);
                  })}

                  <tr className="footer">
                    <td className="remove-item-td" />
                    <td style={{ color: "#333" }}>Cost Group Total</td>
                    <td align="center">
                      <FormattedNumber number={totalCost} />
                    </td>
                    <td />
                    <td />
                    <td>
                      <textarea
                        defaultValue={costGroup.note || ""}
                        onChange={(e) =>
                          updateCostGroupValue(
                            costGroupId,
                            "note",
                            e.target.value
                          )
                        }
                      />
                    </td>
                  </tr>
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </DragDropContext>

        <DropZone
          items={costGroup.images}
          accept={allowedFiles}
          multiple
          label={costGroup.name}
          maxSize={15}
          uploadHandler={(image, file) => {
            analytics.track({
              event: generateAnalyticsPrefix(PlanAttachCostGroupPhoto),
            });

            uploadCostGroupImage(mode, costGroupId, {
              upload: image.target.result,
              id: Date.now(),
              file,
            });
          }}
          deleteHandler={(item) => {
            analytics.track({
              event: generateAnalyticsPrefix(PlanRemoveCostGroupPhoto),
            });

            removeCostGroupImage(mode, costGroupId, item.file);
          }}
        />
        {/*  TODO: Add handler to open image */}

        <datalist id="cost_group">
          {costGroupsItems.map((option, index) => (
            <option value={option} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-planning_and_permits">
          {planningAndPermitsItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-utilities">
          {utilitiesItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-demolition">
          {demolitionItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-concrete">
          {concreteItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-excavation">
          {excavationItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-framing">
          {framingItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-exterior">
          {exteriorItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-plumbing">
          {plumbingItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-mechanical">
          {mechanicalItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-electrical">
          {electricalItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-insulation">
          {insulationItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-wall_coverings">
          {wallCoveringsItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-wall_finishes">
          {wallFinishesItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-interior_trim">
          {interiorTrimItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-bathrooms">
          {bathroomModuleItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-kitchens">
          {kitchenModuleItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-flooring">
          {flooringItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-landscaping">
          {landscapingItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-general">
          {generalItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="custom">
          {customItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-general_conditions">
          {generalConditionsItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-bathroom_module">
          {bathroomModuleItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-powder_room_module">
          {powderRoomModuleItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>

        <datalist id="cost_item-kitchen_module">
          {kitchenModuleItems.map((option, index) => (
            <option value={option.name} key={index} />
          ))}
        </datalist>
      </CostGroupWrapper>
    );
  }
}

export default CostGroup;
